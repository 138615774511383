import makeStyles from '@material-ui/core/styles/makeStyles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';

export const SprintProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 20,
      width: '95%',
      borderRadius: 10,
      marginBottom: '0.5rem',
    },
    colorPrimary: {
      backgroundColor:
        theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
      borderRadius: 10,
      backgroundColor: 'primary',
    },
  }),
)(LinearProgress);

export const useStyles = makeStyles(theme => ({
  squadName: {
    fontSize: '1.8rem',
    fontFamily: 'Quicksand, sans-serif',
    fontWeight: 500,
    marginBottom: '28px',
  },
  content: {
    width: '84vw',
    height: '90vh',
    '@media (min-width: 1500px)': {
      height: '70vh',
    },
    '@media (min-width: 961px) and (max-width: 1047px)': {
      width: '82vw',
    },
    '@media (max-width: 900px)': {
      flexDirection: 'column',
    },
  },
  boxOne: {
    width: '30%',
    '@media (max-width: 900px)': {
      width: '90vw',
      flexDirection: 'row',
    },
  },
  boxDescribe: {
    '@media (max-width: 900px)': {
      width: '40%',
      height: '20vh',
      marginRight: '30px',
    },
  },
  label: {
    fontSize: '14px',
    fontWeight: 700,
    fontFamily: 'Quicksand, sans-serif',
    '@media (max-width: 1500px)': {
      fontSize: '12px',
    },
  },
  squadDescription: {
    border: 0,
    resize: 'none',
    width: '93%',
    height: '85%',
    padding: '8px',
    '&::placeholder': {
      fontFamily: 'Quicksand, sans-serif',
      fontSize: '14px',
    },
  },
  boxMembers: {
    '@media (max-width: 900px)': {
      width: '40%',
      height: '20vh',
    },
  },
  profilePictureContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  profilePictureContent: {
    width: '98%',
    display: 'flex',
    flexWrap: 'wrap',
  },
  profilePicture: {
    width: '60px',
    height: '60px',
    fontSize: '16px',
    margin: '8px 8px 8px 0',
    '@media (max-width: 1500px)': {
      width: '35px',
      height: '35px',
    },
  },
  boxTwo: {
    width: '70%',
    paddingRight: '8px',
    '@media (max-width: 900px)': {
      width: '88vw',
      height: '85vh',
      padding: '8px',
      margin: '0',
    },
  },
  containerBoxTwo: {
    width: '100%',
    height: '95%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  containerLabel: {
    width: '100%',
    height: '10%',
    marginTop: '40px',
  },
  sprintsScroll: {
    height: '100%',
    width: '30%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '&::-webkit-scrollbar': {
      width: '10px',
      height: '10px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'rgb(241,241,241)',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#263579',
      borderRadius: '10px',
    },
  },
  sprintButton: {
    width: '90%',
    display: 'flex',
    padding: '0',
    marginBottom: '1rem',
  },
  sprintCard: {
    width: '100%',
    padding: '5px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  sprintNameCard: {
    fontSize: '16px',
    fontFamily: 'Quicksand, sans-serif',
    fontWeight: 500,
    '@media (max-width:1500px)': {
      fontSize: '12px',
    },
  },
  sprintDuration: {
    fontSize: '14px',
    fontFamily: 'Quicksand, sans-serif',
    fontWeight: 500,
    color: '#898989',
    marginRight: '3px',
    '@media (max-width:1500px)': {
      fontSize: '12px',
    },
  },
  sprintProgress: {
    fontSize: '12px',
    color: '#429335',
    fontFamily: 'Quicksand, sans-serif',
    fontWeight: 500,
    '@media (max-width:1500px)': {
      fontSize: '10px',
    },
  },
  boxObjective: {
    width: '65%',
    height: '90%',
    '@media (max-width: 900px)': {
      marginLeft: '8px',
      paddingRight: '20px',
    },
  },
  sprintName: {
    fontSize: '36px',
    fontFamily: 'Quicksand, sans-serif',
    fontWeight: 700,
    color: '#373737',
    '@media (max-width:1500px)': {
      fontSize: '18px',
    },
  },
  sprintGoal: {
    fontSize: '14px',
    fontFamily: 'Quicksand, sans-serif',
    color: '#373737',
    fontWeight: 400,
  },
  chart: {
    width: '220px',
    '@media (max-width:1500px)': {
      width: '12vw',
    },
    '@media (max-width: 900px)': {
      width: '16vw',
    },
  },
  percentage: {
    position: 'absolute',
    fontFamily: 'Quicksand, sans-serif',
    fontSize: '24px',
    fontWeight: 700,
    '@media (max-width:1500px)': {
      fontSize: '18px',
    },
  },
  boxData: {
    width: '95%',
    justifyContent: 'space-between',
  },
  containerSprintValue: {
    width: '95%',
  },
  sprintDataIcon: {
    color: '#263579',
    fontSize: '24px',
    '@media (max-width: 1500px)': {
      fontSize: '16px',
    },
  },
  sprintDataLabel: {
    color: '#373737',
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: 'Quicksand, sans-serif',
    '@media (max-width: 1500px)': {
      fontSize: '12px',
    },
  },
  sprintDataValue: {
    color: '#373737',
    fontSize: '18px',
    fontWeight: 700,
    fontFamily: 'Quicksand, sans-serif',
    '@media (max-width: 1500px)': {
      fontSize: '12px',
    },
  },
  multiline: {
    height: '100%',
    width: '100%',
    padding: '0',
  },
  loading: {
    margin: '0 auto',
    position: 'relative',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
    width: '70%',
    height: '100%',
  },
  circularProgress: {
    position: 'absolute',
    top: '40%',
    right: '45%',
  },
}));
