import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  openSelectType: {
    position: 'absolute',
    background: 'white',

    zIndex: 10,

    margin: '.3rem 0 0 .5rem',
  },
  closeSelectType: {
    display: 'none',
  },
  cardContent: {
    width: '6rem',
    height: '120px',
  },
  title: {
    fontSize: '62.5%',
    margin: '0 0 .8rem .3rem',
    fontWeight: 600,
    color: '#A7A7A7',
  },
  button: {
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    fontSize: '87.5%',
    margin: '.5rem',
  },
}));
