/* eslint-disable camelcase */
import { createModel } from '@rematch/core';
import { RootModel } from '.';

import * as api from '../api/projects';

export interface ProjectData {
  id: number;
  name: string;
  status: string;
  client_id: number;
  description?: string;
  user_responsible_id?: number;

  start_date: string;
  finish_date: string;

  created_at: string;
  updated_at: string;
  requirements: object[];
  tasks: object[];
}

export const Projects = createModel<RootModel>()({
  state: [] as ProjectData[],
  reducers: {
    loadProjects: (state, payload: ProjectData[]) => payload,
  },
  effects: dispatch => ({
    readAll: async () => {
      const projects = await api.readAll();
      dispatch.Projects.loadProjects(projects);
    },
    create: async (options: api.CreateOptions) => {
      await api.create(options);
    },
    read: async (id: number) => {
      const project = await api.read({ id });
      return project;
    },
    edit: async (options: api.EditOptions) => {
      await api.update(options);
    },
  }),
});
