import makeStyles from '@material-ui/core/styles/makeStyles';

import { green, red } from '@material-ui/core/colors';

export const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    width: 'calc(100% - 57px)',
    borderBottom: '1px solid #B9B9B7',
    paddingBottom: '.7rem',
    paddingLeft: '3.5rem',
  },
  breadCrumbs: {
    fontFamily: 'Quicksand, sans-serif',
    fontSize: '20px',
    fontWeight: 'bold',
  },
  squadName: {
    color: '#000',
    fontFamily: 'Quicksand, sans-serif',
    fontSize: '20px',
    fontWeight: 'normal',
  },
  contentContainer: {
    width: 'calc(97% - 57px)',
    marginInline: '3rem',
    marginTop: '.5rem',
    paddingBottom: '1rem',
    '@media (min-width: 961px) and (max-width: 1047px)': {
      width: '82vw',
    },
  },
  options: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    borderBottom: '1px solid #B9B9B7',
    marginBottom: theme.spacing(2),
  },
  tabs: {
    display: 'flex',
  },
  tabName: {
    fontFamily: 'Quicksand, sans-serif',
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
  filters: {
    display: 'flex',
  },
  searchBar: {
    width: '140%',
    color: '#000',
  },
  filterSelector: {
    height: '10px',
    width: '100%',
    marginLeft: '1rem',

    '& > div': {
      width: '15rem',
    },
  },
  button: {
    fontFamily: 'Quicksand, sans-serif',
    width: '100%',
    height: '2.4rem',
    paddingInline: '1.5rem',
    marginLeft: '1rem',
  },
  progress: {
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: 'green',
    },
    height: 15,
    borderRadius: 7,
    '& .MuiLinearProgress-bar': {
      borderRadius: 7,
    },
  },
  formsNewTask: {
    width: 500,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  redButton: {
    backgroundColor: red[700],
    color: '#ffffff',
  },
  greenButton: {
    backgroundColor: green[700],
    color: '#ffffff',
  },
  iconClose: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: '#ffffff',
  },
  iconWarning: {
    color: red[700],
    verticalAlign: 'bottom',
  },
  title: {
    backgroundColor: green[700],
    color: '#ffffff',
  },
  body: {
    marginTop: 20,
    marginBottom: 10,
  },
}));
