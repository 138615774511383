import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  taskCard: {
    height: '85px',
    display: 'flex',
    cursor: 'pointer',
    borderRadius: '4px',
    padding: '2rem 1rem',
    alignItems: 'center',
    boxSizing: 'border-box',
    border: '1px solid #F3F3F3',
    justifyContent: 'space-between',
    boxShadow: '0px 4px 3px -5px rgba(0,0,0,0.5)',
  },
  taskType: {
    display: 'flex',
    margin: '0.5rem 0 0 0',
  },
  taskName: {
    color: '#373737',
    fontSize: '1rem',
    fontWeight: 'bold',
    margin: '0 0 1rem 0',
  },
  tag: {
    marginTop: '0',
    color: '#373737',
    marginBottom: '0',
    fontWeight: 'bold',
    fontSize: '0.7rem',
    borderRadius: '4px',
    marginRight: '20px',
    textTransform: 'uppercase',
    backgroundColor: '#f8f8f8',
    padding: '5px 10px 5px 10px',
  },
  time: {
    display: 'flex',
    color: '#898989',
    alignItems: 'center',
  },
  timeIcon: {
    height: '15px',
  },
  timeQuantity: {
    margin: '0',
    fontSize: '0.9rem',
    fontWeight: 'bold',
  },
  avatar: {
    height: '38px',
    fontSize: '16px',
    marginLeft: '10px',
    borderRadius: '50%',
    backgroundColor: '#263579',
  },
  more: {
    color: '#898989',
  },
  cardBody: {
    display: 'flex',
    alignItems: 'center',
  },
  typeImage: {
    height: '15px',
  },

  taskID: {
    margin: '0',
    display: 'flex',
    color: '#898989',
    fontSize: '0.8rem',
    fontWeight: 'bold',
    alignItems: 'center',
  },

  modalTitle: {
    padding: '0',
  },

  modalContent: {
    padding: '8px 18px 8px 18px',
  },

  actionMenu: {
    padding: 0,
  },
}));
