import { createModel } from '@rematch/core';
import { RootModel } from '.';

export interface BoardFilterProps {
  userId?: number;
  projectId?: number;
}

export const BoardFilter = createModel<RootModel>()({
  state: {} as BoardFilterProps,
  reducers: {
    loadFilter: (state, payload: BoardFilterProps) => ({
      userId: payload.userId,
      projectId: payload.projectId,
    }),
  },
});
