import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  breadCrumbContainer: {
    borderBottom: '1px solid #e0e0e0',
    padding: '0rem 3rem 0.5rem 3rem',
  },

  searchContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: '1rem 0',
  },

  paperInput: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '20px',
    padding: '0px 3px 0px 15px',
    background: '#F8F8F8',
    border: '1px solid #E0E0E0',
    boxShadow: 'none',
    '&:hover': {
      cursor: 'pointer',
      border: '1px solid #263579',
    },
  },

  buttonSearch: {
    width: '35px',
    height: '35px',
    padding: '0',
    margin: '0',
  },

  searchIcon: {
    margin: '0',
  },

  buttonCreate: {
    margin: theme.spacing(3, 0),
  },

  formsNewUser: {
    width: 500,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  modalTitle: {
    padding: '0',
  },

  deleteMessage: {
    fontFamily: 'Quicksand',
    fontSize: '18px',
    padding: '0.5rem 0 3rem 0',
  },

  deleteIcon: {
    color: 'red',
    fontSize: '2rem',
    borderRadius: '40%',
  },

  deleteTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#373737',
  },

  boxDelete: {
    display: 'grid',
    gridTemplateColumns: '6% 40%',
    gridGap: '0.5rem',
    padding: '1.5rem 0',
  },

  formButtonsContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },

  modalSubtitle: {
    fontFamily: 'Quicksand',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '15px',
    lineHeight: '22px',
    color: '#373737',
    padding: '0.7rem 0',
  },

  error: {
    borderColor: 'red',
    color: 'red',
  },

  border: {
    '&::hover': {
      borderColor: 'red',
    },
  },

  field: {
    marginRight: theme.spacing(2),
  },

  removeUppercase: {
    textTransform: 'none',
    fontSize: '15px',
  },
}));
