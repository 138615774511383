/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useState, useRef, KeyboardEvent } from 'react';

import { useSelector } from 'react-redux';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

import * as Yup from 'yup';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import InputBase from '@material-ui/core/InputBase';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import WarningIcon from '@material-ui/icons/Warning';
import RefreshIcon from '@material-ui/icons/Refresh';

import { TextField } from 'unform-material-ui';

import Table, {
  ColumnData,
  RowData,
  ToolbarAction,
  RowAction,
} from '../../components/Table';

import Dialog from '../../components/Dialog';
import SnackAlert from '../../components/SnackAlert';

import { useSnackAlert } from '../../hooks/useSnackAlert';

import { dispatch, RootState } from '../../config/store';

import { useStyles } from './styles';
import BreadCrumbBar from '../../components/BreadCrumbBar';

const columns: ColumnData[] = [
  {
    id: 'name',
    label: 'Nome',
    sortable: true,
  },
  {
    id: 'email',
    label: 'Email',
    sortable: true,
  },
  {
    id: 'daily_average_hours',
    label: 'Horas/dia',
    sortable: true,
  },
];

const Users: React.FC = () => {
  const [rowId, setRowId] = useState<number>(0);

  const [hours, setHours] = useState('');
  const [userId, setUserId] = useState(0);
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');

  const [passwordError, setPasswordError] = useState(false);

  const [modalType, setModalType] = useState('');

  const [redefinePassword, setRedefinePassword] = useState(false);

  const [modalActive, setModalActive] = useState(false);
  const [snackActive, setSnackActive] = useState(false);

  const [rows, setRows] = useState<RowData[]>([]);

  const users = useSelector((state: RootState) => state.Users.users);
  const user = useSelector((state: RootState) => state.Users.user);

  const formRef = useRef<FormHandles>(null);

  const classes = useStyles();

  const { message, severity } = useSnackAlert({
    message: 'Preencha os campos corretamente!',
    severity: 'error',
  });

  const handleValidationEmail = async (email: any) => {
    const user = users?.find(u => u.email === email);
    if (user && user.name !== userName) {
      return false;
    }
    return true;
  };

  const schema = Yup.object().shape({
    name: Yup.string().required('O nome do usuário é obrigatório.'),
    email: Yup.string()
      .email('Insira um e-mail válido.')
      .required('E-mail é obrigatório')
      .test('unique-email', 'Email já cadastrado', async value => {
        const values = await handleValidationEmail(value);
        return values;
      }),
    password:
      redefinePassword || modalType === 'create'
        ? Yup.string()
            .min(6, 'A senha deve ter no mínimo 6 caracteres.')
            .required('Senha é obrigatória.')
        : Yup.string(),
    password_confirmation:
      redefinePassword || modalType === 'create'
        ? Yup.string()
            .required('A confirmação de senha é obrigatória.')
            .oneOf([Yup.ref('password'), null], 'As senhas não conferem.')
        : Yup.string(),
    daily_average_hours: Yup.number()
      .integer('Digite um valor inteiro')
      .typeError('A média de horas é obrigatória')
      .positive('A média de horas deve ser positiva')
      .max(24, 'A média de horas diária não pode ultrapassar 24 horas'),
    current_password:
      modalType === 'edit' && redefinePassword
        ? Yup.string().required('A senha atual é obrigatória')
        : Yup.string(),
  });

  useEffect(() => {
    dispatch.Users.readAll();
  }, []);

  useEffect(() => {
    if (users && users.length > 0) {
      setRows(users.filter(u => u.active === true));
    }
  }, [users]);

  useEffect(() => {
    if (user && Object.keys(user).length > 0) {
      setRows(user);
    }
  }, [user]);

  const toolbarActions: ToolbarAction[] = [
    {
      icon: <RefreshIcon />,
      callback: () => {
        dispatch.Users.readAll();
      },
    },
  ];

  const handleSetModal = () => {
    setModalActive(!modalActive);
  };

  const handleDeleteModal = (id: number) => {
    setRowId(id);
    setModalType('delete');
    handleSetModal();
  };

  const handleCreateModal = () => {
    setModalType('create');
    handleSetModal();
  };

  const handleEditDialog = async (id: number) => {
    if (id) {
      const userEdit = await dispatch.Users.read(id);
      if (userEdit) {
        setUserId(userEdit.id);
        setUserName(userEdit?.name);
        setUserEmail(userEdit?.email);
        setHours((userEdit?.daily_average_hours).toString());
      }
    }

    setModalType('edit');
    setPasswordError(false);
    handleSetModal();
  };

  const toggleSnack = () => {
    setSnackActive(!snackActive);
  };

  const handleCreateUser = async (data: any) => {
    try {
      formRef?.current?.setErrors({});

      data.daily_average_hours = parseInt(data.daily_average_hours, 10);

      await schema.validate(data, {
        abortEarly: false,
      });

      if (modalType === 'create') {
        await dispatch.Users.create(data);
      } else {
        const dataName = {
          id: userId,
          name: data.name,
          daily_average_hours: data.daily_average_hours,
        };
        await dispatch.Users.update(dataName);

        if (redefinePassword) {
          const dataPassword = {
            old_password: data.current_password,
            password: data.password,
            password_confirmation: data.password_confirmation,
          };
          await dispatch.Users.changepassword(dataPassword);
        }
      }

      setModalActive(!modalActive);

      await dispatch.Users.readAll();
    } catch (err) {
      const validationErrors = {};
      let errorMessage;
      if (err instanceof Error) {
        errorMessage = err.message;
      }
      if (errorMessage === 'Request failed with status code 400') {
        setPasswordError(true);
      } else {
        setPasswordError(false);
      }

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          // @ts-ignore
          validationErrors[error?.path] = error.message;
        });
        formRef?.current?.setErrors(validationErrors);
      }
    }
  };

  const handleDeleteUser = async () => {
    await dispatch.Users.remove(rowId);
    await dispatch.Users.readAll();
  };

  const actionIcons: RowAction[] = [
    {
      icon: <DeleteIcon />,
      callback: handleDeleteModal,
    },
    {
      icon: <EditIcon />,
      callback: handleEditDialog,
    },
  ];

  const handleFieldChange = async (e: any) => {
    setUserName(e.target.value);
  };

  const handleSearchKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter' && userName !== '') {
      setRows([]);
      setUserName('');
      dispatch.Users.search(userName);
    }
  };

  const handleUserSearch = () => {
    setRows([]);
    setUserName('');
    dispatch.Users.search(userName);
  };

  const deleteContent = (
    <>
      <Box className={classes.boxDelete}>
        <WarningIcon className={classes.deleteIcon} />
        <Typography className={classes.deleteTitle}>Excluir usuário</Typography>
      </Box>
      <Typography className={classes.deleteMessage}>
        Tem certeza que deseja excluir esse usuário?
      </Typography>
      <Box className={classes.formButtonsContainer}>
        <Button
          variant="contained"
          color="inherit"
          className={classes.buttonCreate}
          style={{ marginRight: 8, backgroundColor: '#EAEAEA' }}
          classes={{
            root: classes.removeUppercase,
          }}
          onClick={() => {
            setModalActive(!modalActive);
          }}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          style={{ backgroundColor: 'red' }}
          onClick={() => {
            handleDeleteUser();
            setModalActive(!modalActive);
          }}
          className={classes.buttonCreate}
          classes={{
            root: classes.removeUppercase,
          }}
        >
          Excluir
        </Button>
      </Box>
    </>
  );

  const handleToggleRedefinePassword = () => {
    setRedefinePassword(!redefinePassword);
  };

  const content = (
    <Form
      ref={formRef}
      onSubmit={handleCreateUser}
      className={classes.formsNewUser}
    >
      <Box>
        <Typography className={classes.modalSubtitle}>
          Informações de usuário
        </Typography>
        <TextField
          id="name"
          name="name"
          label="Nome"
          defaultValue={modalType === 'edit' ? userName : null}
          variant="outlined"
          size="small"
          type="text"
          margin="dense"
          fullWidth
          onChange={() => {
            formRef?.current?.setFieldError('name', '');
          }}
        />
        <TextField
          id="email"
          name="email"
          label="Email"
          defaultValue={modalType === 'edit' ? userEmail : null}
          variant="outlined"
          size="small"
          margin="dense"
          fullWidth
          onChange={() => {
            formRef?.current?.setFieldError('email', '');
          }}
          disabled={modalType === 'edit'}
        />
        <TextField
          id="daily_average_hours"
          name="daily_average_hours"
          label="Horas médias diárias"
          defaultValue={modalType === 'edit' && hours}
          variant="outlined"
          size="small"
          type="number"
          margin="dense"
          fullWidth
          onChange={() => {
            formRef?.current?.setFieldError('daily_average_hours', '');
          }}
        />
      </Box>
      {modalType === 'edit' && (
        <Box
          className={classes.formButtonsContainer}
          style={{ alignItems: 'center' }}
        >
          <span style={{ fontSize: '.8rem' }}>Redefinir senha</span>
          <Switch
            color="primary"
            size="small"
            checked={redefinePassword}
            onChange={handleToggleRedefinePassword}
          />
        </Box>
      )}

      {redefinePassword || modalType !== 'edit' ? (
        <Box style={{ width: '100%' }}>
          <Typography className={classes.modalSubtitle}>
            {modalType === 'edit' ? 'Redefinir Senha' : 'Adicionar Senha'}
          </Typography>
          {modalType === 'edit' && (
            <TextField
              id="currentPassword"
              name="current_password"
              label="Senha Atual"
              variant="outlined"
              size="small"
              error={passwordError}
              type="password"
              margin="dense"
              className={classes.border}
              fullWidth
              onChange={() => {
                formRef?.current?.setFieldError('current_password', '');
              }}
              helperText={passwordError && 'As senhas não coincidem.'}
              FormHelperTextProps={{ classes: { root: classes.error } }}
              InputLabelProps={
                passwordError ? { classes: { root: classes.error } } : undefined
              }
              InputProps={
                passwordError
                  ? {
                      classes: {
                        notchedOutline: classes.error,
                      },
                    }
                  : undefined
              }
            />
          )}
          <TextField
            id="password"
            name="password"
            label="Nova Senha"
            variant="outlined"
            size="small"
            type="password"
            margin="dense"
            fullWidth
            onChange={() => {
              formRef?.current?.setFieldError('password', '');
            }}
          />
          <TextField
            id="passwordConfirmation"
            name="password_confirmation"
            label="Confirmação de senha"
            variant="outlined"
            size="small"
            type="password"
            margin="dense"
            fullWidth
            onChange={() => {
              formRef?.current?.setFieldError('password_confirmation', '');
            }}
          />
        </Box>
      ) : null}

      <Box className={classes.formButtonsContainer}>
        <Button
          variant="contained"
          color="inherit"
          className={classes.buttonCreate}
          style={{ marginRight: 8 }}
          onClick={() => {
            setModalActive(!modalActive);
            setRedefinePassword(false);
          }}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          className={classes.buttonCreate}
        >
          Salvar
        </Button>
      </Box>
    </Form>
  );

  return (
    <>
      <div className={classes.breadCrumbContainer}>
        <BreadCrumbBar data={[{ label: 'Usuários', path: '' }]} />
      </div>
      <Container>
        <Box className={classes.searchContainer}>
          <Paper className={classes.paperInput}>
            <InputBase
              onChange={handleFieldChange}
              id="outlined-basic"
              placeholder="Search Users"
              onKeyDown={handleSearchKeyDown}
            />
            <IconButton
              onClick={handleUserSearch}
              onKeyDown={handleSearchKeyDown}
              aria-label="search"
              className={classes.buttonSearch}
            >
              <SearchIcon className={classes.searchIcon} />
            </IconButton>
          </Paper>

          <Button
            onClick={handleCreateModal}
            className={classes.buttonCreate}
            variant="contained"
            color="primary"
          >
            Novo usuário
          </Button>
        </Box>

        <SnackAlert
          open={snackActive}
          handleClose={toggleSnack}
          message={message}
          severity={severity}
        />

        <Dialog
          title={
            modalType === 'create'
              ? 'Cadastro de usuários'
              : modalType === 'edit'
              ? 'Edição de usuários'
              : ''
          }
          content={modalType === 'delete' ? deleteContent : content}
          open={modalActive}
          maxWidth={modalType === 'delete' && 'sm'}
          fullWidth={modalType === 'delete'}
          classDialogTitle={modalType === 'delete' ? classes.modalTitle : ''}
          handleClose={handleSetModal}
          confirm={handleCreateUser}
          showActions={false}
        />

        <Table
          actions={actionIcons}
          columns={columns}
          rows={rows}
          showToolbar
          toolbarActions={toolbarActions}
        />
      </Container>
    </>
  );
};

export default Users;
