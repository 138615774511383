import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  breadCrumbBar: {
    borderBottom: '1px solid #CACACA',
    marginBottom: '1.5rem',
    paddingBottom: '1rem',
    fontSize: 24,
  },
  linkText: {
    fontFamily: 'Quicksand, sans-serif',
    fontWeight: 500,
    fontSize: 24,
    textDecoration: 'none',
    color: '#B9B9B7',
    transition: '.3s',

    '&:hover': {
      color: '#808080',
    },
  },
}));
