import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  column: {
    width: '90%',
    minHeight: '580px',
    maxHeight: '580px',
    paddingLeft: '15px',
    paddingRight: '8px',
    borderRadius: '5px',
    marginRight: '1rem',
    background: '#F8F8F8',
    paddingBottom: theme.spacing(1.2),
    border: '1px solid rgba(0, 0, 0, 0.03)',
  },
  droppable: {
    marginTop: theme.spacing(2),
    width: '295px',
    minHeight: '450px',
    maxHeight: '450px',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
      scrollbarWidth: 'none',
    },
  },
  title: {
    textAlign: 'left',
    fontFamily: 'Quicksand, sans-serif',
    fontWeight: 'bold',
    fontSize: '14px',
    textTransform: 'uppercase',
  },
  issues: {
    marginLeft: '1rem',
    opacity: '0.3',
  },
  createTaskContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
  createTask: {
    fontFamily: 'Quicksand, sans-serif',
    color: '#373737',
    width: '100%',
    background: '#F8F8F8',
    justifyContent: 'flex-start',
    textTransform: 'capitalize',
    boxShadow: 'none',
    '&:hover': {
      background: '#F8F8F8',
      boxShadow: 'none',
      opacity: '0.5',
    },
  },
  columnMenuTitle: {
    fontSize: '14px',
    fontWeight: 'bold',
    fontFamily: 'Quicksand, sans-serif',
    color: '#A7A7A7',
    marginLeft: '15px',
    marginBottom: theme.spacing(2),
  },
  columnMenuItem: {
    fontSize: '16px',
    fontFamily: 'Quicksand, sans-serif',
    fontWeight: 400,
    color: '#DA4545',
  },
  columnTitle: {
    fontSize: '14px',
  },
}));
