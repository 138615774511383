import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  header: {
    display: 'grid',
    gridTemplateColumns: '80% 20%',
  },

  inputTitle: {
    fontSize: '24px',
    color: '#373737',
    margin: '0.1rem 0',
    lineHeigth: '35px',
    fontWeight: 'bold',
    fontFamily: 'Quicksand',
  },

  inputDescription: {
    fontSize: '16px',
    color: '#373737',
    margin: '0.1rem 0',
    lineHeigth: '35px',
    paddingTop: '1rem',
    fontFamily: 'Quicksand',
  },

  BoxButtonClose: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },

  iconButton: {
    height: '60%',
  },

  title: {
    padding: '1rem 0',
  },

  titleDescription: {
    fontSize: '14px',
    lineHeigth: '17px',
    fontWeight: 'bold',
    fontFamily: 'Quicksand',
    margin: '0 0 0.2rem 0',
  },

  description: {
    width: '100%',
    marginBottom: '1rem',
  },

  removeUppercase: {
    textTransform: 'none',
  },

  BoxButton: {
    width: '97%',
    display: 'flex',
    alignItems: 'middle',
    justifyContent: 'flex-end',
  },

  cancelButton: {
    border: 'none',
    fontSize: '16px',
    color: '#373737',
    boxShadow: 'none',
    lineHeigth: '24px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontFamily: 'Quicksand',
    letterSpacing: '-0.08px',
    backgroundColor: 'white',
  },

  saveButton: {
    color: '#FFFFFF',
    fontSize: '16px',
    lineHeigth: '24px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontFamily: 'Quicksand',
    letterSpacing: '-0.08px',
    backgroundColor: '#263579',
  },
}));
