import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import AddIcon from '@material-ui/icons/Add';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import { Autocomplete } from '@material-ui/lab';

import { useSelector } from 'react-redux';

import Dialog from '../../components/Dialog';
import { RowData } from '../../components/Table';
import SnackAlert from '../../components/SnackAlert';
import BreadCrumbBar from '../../components/BreadCrumbBar';

import { useSnackAlert } from '../../hooks/useSnackAlert';

import { dispatch, RootState } from '../../config/store';
import { UserData } from '../../config/interfaces/Users';

import { TextArea, useStyles, SprintProgress } from './styles';

const Squads: React.FC = () => {
  const classes = useStyles();

  const [squadName, setSquadName] = useState('');
  const [squadDescription, setSquadDescription] = useState('');
  const [squadUsers, setSquadUsers] = useState<UserData[]>([]);

  const [errorName, setErrorName] = useState(false);

  const [modalActive, setModalActive] = useState(false);
  const [snackActive, setSnackActive] = useState(false);

  const [rows, setRows] = useState<RowData[]>([]);

  const squads = useSelector(
    (state: RootState) => state.Squads.squadsWithPagination,
  );
  const users = useSelector((state: RootState) => state.Users.users);

  useEffect(() => {
    dispatch.Squads.readAllWithPagination({ page: 1, limit: 20 });
    dispatch.Users.active();
  }, []);

  useEffect(() => {
    if (squads && Object.keys(squads).length > 0) {
      setRows(squads.data);
    }
  }, [squads]);

  const handleDialog = () => {
    setModalActive(!modalActive);
    setErrorName(false);
  };

  const toggleSnack = () => {
    setSnackActive(!snackActive);
  };

  const handleCreateSquad = async (event: React.FormEvent) => {
    event.preventDefault();

    if (squadName && squadUsers.length > 0) {
      setModalActive(!modalActive);
      const usersId = squadUsers.map((user: UserData) => user.id);

      await dispatch.Squads.create({
        name: squadName,
        users: usersId || [],
        description: squadDescription,
      });

      setSquadName('');
      setSquadUsers([]);
      setErrorName(false);
    } else {
      if (!squadName) {
        setErrorName(true);
      }
      toggleSnack();
    }

    await dispatch.Squads.readAllWithPagination({ page: 1, limit: 20 });
  };

  const getRemainingTime = (startDate: any, endDate: any) => {
    const today: any = new Date();

    // Get the distance between two dates in miliseconds, then convert it to days
    const remainingDays = Math.ceil((endDate - today) / (24 * 60 * 60 * 1000));
    const passedDays = Math.ceil((today - startDate) / (24 * 60 * 60 * 1000));

    let message;
    if (remainingDays <= 0) {
      message = 'Sprint finalizada';
    } else if (remainingDays > 1) {
      message = `Acaba em ${remainingDays} dias`;
    } else {
      message = `Acaba em ${remainingDays} dia`;
    }

    return {
      remainingDays,
      passedDays,
      percentage:
        remainingDays <= 0
          ? 100
          : (passedDays * 100) / (remainingDays + passedDays),
      message,
    };
  };

  const contentCreate = (
    <form onSubmit={handleCreateSquad} className={classes.formsNewSquad}>
      <Box className={classes.maxWidthModalItems}>
        <div>
          <strong>Nome da Squad</strong>
          <TextField
            id="name"
            label="Nome"
            variant="outlined"
            size="small"
            type="text"
            margin="dense"
            fullWidth
            required
            error={errorName}
            helperText={
              errorName === true
                ? 'É obrigatório preencher o nome da squad.'
                : ''
            }
            onChange={name => setSquadName(name.target.value)}
          />
        </div>

        <Box className={classes.modalContainer}>
          <Box className={classes.modalFieldNamePlusIcon}>
            <strong>Membros</strong>
          </Box>
          <Autocomplete
            multiple
            id="combo-box-users"
            options={users}
            getOptionLabel={option => option.name}
            style={{ flexGrow: 1 }}
            size="small"
            renderInput={params => (
              <TextField {...params} label="Membros" variant="outlined" />
            )}
            onChange={(_, value) => setSquadUsers(value)}
          />
        </Box>

        <Box className={classes.field}>
          <strong>Descrição</strong>
          <TextArea
            name="descricao"
            id="description"
            value={squadDescription}
            onChange={event => setSquadDescription(event.target.value)}
          />
        </Box>
      </Box>

      <Box className={classes.formButtonsContainer}>
        <Button
          variant="contained"
          color="inherit"
          className={classes.buttonCreate}
          style={{ marginRight: 8 }}
          onClick={handleDialog}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          onClick={handleCreateSquad}
          className={classes.buttonCreate}
        >
          Salvar
        </Button>
      </Box>
    </form>
  );

  const { message, severity } = useSnackAlert({
    message: 'Os campos devem ser preenchidos corretamente',
    severity: 'error',
  });

  const cardTitle = (name: String) => (
    <Typography className={classes.squadName}>{name}</Typography>
  );

  return (
    <Box>
      <div className={classes.breadCrumbContainer}>
        <BreadCrumbBar data={[{ label: 'Squads', path: '' }]} />
      </div>

      <SnackAlert
        open={snackActive}
        handleClose={toggleSnack}
        message={message}
        severity={severity}
      />

      <Dialog
        title="Cadastro Squad"
        content={contentCreate}
        open={modalActive}
        handleClose={handleDialog}
        confirm={handleCreateSquad}
        showActions={false}
      />
      <Grid
        container
        direction="row"
        spacing={4}
        justifyContent="flex-start"
        style={{ margin: '1rem 0 0 2rem', width: '99%' }}
      >
        <Grid
          item
          xs
          sm={3}
          className={classes.squadGridItem}
          onClick={handleDialog}
        >
          <Card
            className={`${classes.squadCard} ${classes.addSquadCard}`}
            elevation={0}
          >
            <CardContent>
              <AddIcon className={classes.addIcon} />
            </CardContent>
          </Card>
        </Grid>

        {rows.map(row => {
          const realDates = {
            start: new Date(row.sprint.created_at),
            end: new Date(row.sprint.expected_end_date),
          };
          const timeData = getRemainingTime(realDates.start, realDates.end);

          return (
            <Grid
              item
              xs={3}
              sm={3}
              className={classes.squadGridItem}
              key={row.name}
            >
              <Link to={`squad/${row.id}`} style={{ textDecoration: 'none' }}>
                <Card className={classes.squadCard} elevation={0}>
                  <CardHeader title={cardTitle(row.name)} />
                  <CardContent>
                    <Typography
                      className={`${classes.sprintName} ${classes.Typography}`}
                      gutterBottom
                    >
                      <DirectionsRunIcon color="primary" />
                      {row.sprint.name} {row.amount_sprint}
                    </Typography>
                    <SprintProgress
                      variant="determinate"
                      value={timeData.percentage}
                    />
                    <Typography
                      className={`${classes.cardTextSmall} ${classes.Typography} ${classes.gutterBottom}`}
                    >
                      <AccessTimeIcon
                        className={`${classes.icons} ${classes.clockIcon}`}
                      />
                      {timeData.message}
                    </Typography>
                    <div
                      className={`${classes.cardTextSmall} ${classes.iconTypography}`}
                    >
                      <Typography
                        className={`${classes.cardTextSmall} ${classes.Typography}`}
                        gutterBottom
                      >
                        <CalendarTodayIcon
                          className={classes.icons}
                          color="primary"
                        />
                        <span className={classes.cardTextMedium}>Início: </span>
                        {realDates.start.toLocaleDateString('pt-BR')}
                      </Typography>
                      <Typography
                        className={`${classes.cardTextSmall} ${classes.Typography}`}
                        gutterBottom
                      >
                        <CalendarTodayIcon
                          className={classes.icons}
                          color="primary"
                        />
                        <span className={classes.cardTextMedium}>Fim: </span>
                        {realDates.end.toLocaleDateString('pt-BR')}
                      </Typography>
                    </div>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default Squads;
