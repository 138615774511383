import React, { useState, useEffect } from 'react';

import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';

import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';

import { useSelector } from 'react-redux';

import Dialog from '../../../../components/Dialog';
import Loading from '../../../../components/Dialog/Loading';

import SnackAlert from '../../../../components/SnackAlert';
import TaskContent from '../../../../components/TaskContent';

import { useSnackAlert } from '../../../../hooks/useSnackAlert';

import { dispatch, RootState } from '../../../../config/store';

import BoardColumn from './BoardColumn';
import CreateColumnModal from './CreateColumnModal';

import { SprintReadRes } from '../../../../config/interfaces/Sprints';

import { useStyles } from './styles';

interface BoardProps {
  columnsProp: SprintReadRes['sprint']['columns'];
  squadId: number;
  fetchColumns: () => Promise<void>;
}

const Board: React.FC<BoardProps> = ({
  columnsProp,
  squadId,
  fetchColumns,
}: BoardProps) => {
  const [columnName, setColumnName] = useState('');
  const [columnId, setColumnId] = useState<number>();

  const [modalActive, setModalActive] = useState(false);
  const [snackActive, setSnackActive] = useState(false);

  const [columns, setColumns] =
    useState<SprintReadRes['sprint']['columns']>(columnsProp);
  const [modalCreateTaskActive, setModalCreateTaskActive] = useState(false);
  const [createNewColumnModalOpen, setCreateNewColumnModalOpen] =
    useState(false);
  const currentSprint = useSelector(
    (state: RootState) => state.Squads.squad?.sprint.id,
  );

  const classes = useStyles();

  const handleDialog = () => {
    setModalActive(!modalActive);
  };

  const handleDialogTask = () => {
    setModalCreateTaskActive(!modalCreateTaskActive);
  };

  const handleUpdateTask = async () => {
    fetchColumns();
  };

  const handleColumnId = (id: number) => {
    setColumnId(id);
  };

  const toggleSnack = () => {
    setSnackActive(!snackActive);
  };

  const handleCreateColumn = async () => {
    if (columnName) {
      setModalActive(!modalActive);

      await dispatch.Columns.create({
        name: columnName,
        position:
          columns.sort((a, b) => a.position - b.position)[columns.length - 1]
            .position + 1,
        squad_id: squadId,
      });

      await dispatch.Columns.read({ id: squadId });
    } else {
      toggleSnack();
    }
  };

  const handleOpenNewColumnModal = () => {
    setCreateNewColumnModalOpen(!createNewColumnModalOpen);
  };

  const contentColumn = (
    <form onSubmit={handleCreateColumn}>
      <TextField
        id="outlined-basic"
        label="Nome da coluna"
        variant="outlined"
        style={{ width: 400 }}
        required
        value={columnName}
        onChange={e => setColumnName(e.target.value)}
      />
    </form>
  );

  const { message, severity } = useSnackAlert({
    message: 'Os campos devem ser preenchidos corretamente!',
    severity: 'error',
  });

  const handleDragEnd = async ({ source, destination, type }: DropResult) => {
    if (!destination) return;

    let newColumns = Array.from(columns ?? []);

    const fromCol = newColumns.findIndex(
      column => column.id === +source.droppableId,
    );
    const toCol = newColumns.findIndex(
      column => column.id === +destination.droppableId,
    );

    if (type === 'task') {
      const fromIndex = source.index;
      const toIndex = destination.index;

      const [task] = newColumns[fromCol].tasks.splice(fromIndex, 1);
      newColumns[toCol].tasks.splice(toIndex, 0, task);

      newColumns[toCol].tasks = newColumns[toCol].tasks.map((t, index) => ({
        ...t,
        column_id: newColumns[toCol].id,
        position: index,
      }));

      setColumns(newColumns);

      dispatch.Tasks.updatePosition({
        id: task.id,
        change_to_sprint: currentSprint,
        change_to_column: newColumns[toCol].id,
        tasks_in_destination_column: newColumns[toCol].tasks.map(t => t.id),
      });
    } else if (type === 'column') {
      const fromIndex = source.index;
      const toIndex = destination.index;

      const [movedColumn] = newColumns.splice(fromIndex, 1);
      newColumns.splice(toIndex, 0, movedColumn);

      newColumns = newColumns.map((column, index) => ({
        ...column,
        position: index,
      }));

      setColumns(newColumns);

      dispatch.Columns.sortPositions({
        squad_id: +squadId,
        columns: newColumns.map(column => column.id) as [
          number,
          number,
          ...number[]
        ],
      });
    }
  };

  return (
    <>
      <Dialog
        title="Criar coluna"
        open={createNewColumnModalOpen}
        handleClose={() => {
          setCreateNewColumnModalOpen(false);
        }}
        content={
          <CreateColumnModal
            toggleModal={handleOpenNewColumnModal}
            squadId={squadId}
            updateColumns={fetchColumns}
          />
        }
        showActions={false}
      />

      <div>
        <SnackAlert
          open={snackActive}
          handleClose={toggleSnack}
          message={message}
          severity={severity}
        />
        <Dialog
          title="Nova coluna"
          content={contentColumn}
          open={modalActive}
          handleClose={handleDialog}
          confirm={handleCreateColumn}
        />
        <Dialog
          title=""
          content={
            <TaskContent
              closeModal={() => {
                handleDialogTask();
                handleUpdateTask();
              }}
              squadId={squadId}
              columnId={columnId}
              sprintId
            />
          }
          open={modalCreateTaskActive}
          handleClose={() => {
            handleDialogTask();
            handleUpdateTask();
          }}
          classDialogTitle={classes.modalTitle}
          showActions={false}
          fullWidth
          maxWidth="md"
        />
        <Box
          display="flex"
          justifyContent={columns?.length ? 'start' : 'center'}
        >
          {columns?.length ? (
            <DragDropContext
              onDragEnd={handleDragEnd}
              {...{ overflow: 'auto' }}
            >
              <div className={classes.boardContainer}>
                <Droppable
                  droppableId="columns"
                  direction="horizontal"
                  type="column"
                >
                  {dropProvided => (
                    <div
                      {...dropProvided.droppableProps}
                      ref={dropProvided.innerRef}
                      style={{
                        display: 'flex',
                      }}
                    >
                      <div
                        style={{
                          minWidth: `${columns?.length * 22}rem`,
                          display: 'flex',
                          overflow: 'hidden',
                        }}
                      >
                        {columns?.length &&
                          columns
                            .sort((a, b) => a.position - b.position)
                            .map(column => (
                              <Draggable
                                draggableId={`column-${column.id}`}
                                key={column.id}
                                index={column.position}
                              >
                                {dragProvided => (
                                  <div
                                    {...dragProvided.draggableProps}
                                    ref={dragProvided.innerRef}
                                    style={{
                                      ...dragProvided.draggableProps.style,
                                    }}
                                    className={classes.droggable}
                                  >
                                    <BoardColumn
                                      key={column.id}
                                      id={column.id}
                                      handleColumnId={handleColumnId}
                                      providedColumnDrag={dragProvided}
                                      handleCreateIssue={handleDialogTask}
                                      column={{
                                        ...column,
                                        items: column.tasks,
                                      }}
                                      fetchColumns={fetchColumns}
                                      issues={column.tasks.length}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            ))}
                      </div>
                      <Box
                        bgcolor="grey.500"
                        p={1}
                        width="295px"
                        height="fit-content"
                        borderRadius={5}
                      >
                        <ButtonBase onClick={handleOpenNewColumnModal}>
                          <Typography
                            variant="h5"
                            className={classes.newColumn}
                          >
                            + CRIAR COLUNA
                          </Typography>
                        </ButtonBase>
                      </Box>
                      {dropProvided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            </DragDropContext>
          ) : (
            <Box display="flex" alignItems="center" className={classes.loading}>
              <Loading />
            </Box>
          )}
        </Box>
      </div>
    </>
  );
};

export default Board;
