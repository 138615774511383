/* eslint-disable arrow-body-style */
import React from 'react';

import { Switch, Route, BrowserRouter } from 'react-router-dom';

import { AppRoute } from './appRoute';
import RestrictedRoute from './RestrictedRoute';

import AppDrawer from '../components/Drawer';

const Router: React.FC<{ routes: AppRoute[] }> = ({ routes }) => {
  return (
    <BrowserRouter>
      <Switch>
        {routes.map(({ path, main, exact, restricted }) => {
          return restricted ? (
            <Route
              key={path}
              path={path}
              exact={exact}
              component={() => (
                <RestrictedRoute>
                  <AppDrawer main={main} />
                </RestrictedRoute>
              )}
            />
          ) : (
            <Route key={path} path={path} exact={exact} component={main} />
          );
        })}
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
