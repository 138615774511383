import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  dragDropContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  droppable: {
    minHeight: '400px',
    height: '400px',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
      scrollbarWidth: 'none',
    },
  },
  column: {
    width: '100%',
    borderRadius: '3px',
    height: '500px',
    background: '#F8F8F8',
    position: 'relative',
    paddingInline: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    marginInline: '6px',
    marginBottom: theme.spacing(2.5),
  },
  title: {
    textAlign: 'left',
    fontFamily: 'Quicksand, sans-serif',
    fontWeight: 'bold',
    fontSize: '14px',
    padding: '.5rem .5rem',
    textTransform: 'uppercase',
  },
  issues: {
    marginLeft: '1rem',
    opacity: '0.3',
  },
  task: {
    height: '45px',
    display: 'flex',
    padding: '1.4rem',
    userSelect: 'none',
    background: '#fff',
    borderRadius: '4px',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1.5),
    fontFamily: 'Quicksand, sans-serif',
    boxShadow: '0px 3px 2px 0px rgba(0,0,0,0.06)',
  },
  taskRight: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
  },
  taskLeft: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  issue: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '.5rem',
  },
  taskID: {
    opacity: '0.45',
    fontSize: '13px',
    padding: theme.spacing(0.3, 1),
  },
  taskDifficulty: {
    fontSize: '14px',
    margin: theme.spacing(0, 2),
  },
  estimate: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    color: '#898989',
  },
  time: {
    marginLeft: '.2rem',
  },
  taskStatus: {
    backgroundColor: '#f8f8f8',
    display: 'flex',
    fontSize: '12px',
    textTransform: 'uppercase',
    padding: theme.spacing(0.5, 1),
    borderRadius: '5px',
  },
  taskDescription: {
    fontSize: '16px',
  },
  createTaskContainer: {
    bottom: '0',
    display: 'flex',
    padding: '1rem 0',
    position: 'absolute',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
  createTask: {
    fontFamily: 'Quicksand, sans-serif',
    color: '#373737',
    width: '100%',
    background: '#F8F8F8',
    justifyContent: 'flex-start',
    textTransform: 'capitalize',
    boxShadow: 'none',
    '&:hover': {
      background: '#F8F8F8',
      boxShadow: 'none',
      opacity: '0.5',
    },
  },
  taskMenuTitle: {
    fontSize: '14px',
    fontWeight: 'bold',
    fontFamily: 'Quicksand, sans-serif',
    color: '#A7A7A7',
    marginLeft: '15px',
    marginBottom: theme.spacing(2),
  },
  taskMenuItem: {
    marginTop: theme.spacing(1),
    fontSize: '16px',
    fontFamily: 'Quicksand, sans-serif',
    fontWeight: 400,
    color: '#373737',
  },
  taskMenuItemApagar: {
    fontSize: '16px',
    fontFamily: 'Quicksand, sans-serif',
    fontWeight: 400,
    color: '#DA4545',
  },
  squadMembersContainer: {
    fontSize: '16px',
    width: '30%',
    border: '1px solid black',
    filter: 'drop-shadow(0 0 0.75rem crimson)',
  },
  membersColumn: {
    borderRadius: '3px',
    background: '#F8F8F8',
    paddingInline: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    marginInline: '6px',
    height: '500px',
    marginBottom: theme.spacing(2.5),
    filter: 'drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.15))',
  },
  memberBox: {
    height: '410px',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
      scrollbarWidth: 'none',
    },
  },
  modalContent: {
    padding: '0 4rem 0 1rem',
  },
  modalTitle: {
    padding: '0',
  },
  loading: {
    margin: 0,
    position: 'absolute',
    top: ' 50%',
    transform: 'translateY(-50%)',
  },
}));
