import React from 'react';

import Snackbar, { SnackbarOrigin } from '@material-ui/core/Snackbar';

import Alert, { Color } from '@material-ui/lab/Alert';

export interface SnackAlertProps {
  open: boolean;
  handleClose: () => void;
  severity?: Color;
  message: string;
  duration?: number;
  position?: SnackbarOrigin;
}

const SnackAlert: React.FC<SnackAlertProps> = ({
  open,
  handleClose,
  severity = 'info',
  message,
  duration = 6000,
  position = { vertical: 'top', horizontal: 'center' },
}) => (
  <Snackbar
    open={open}
    onClose={handleClose}
    autoHideDuration={duration}
    anchorOrigin={position}
  >
    <Alert
      onClose={handleClose}
      severity={severity}
      elevation={6}
      variant="filled"
    >
      {message}
    </Alert>
  </Snackbar>
);

export default SnackAlert;
