import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  breadCrumbContainer: {
    borderBottom: '1px solid #e0e0e0',
    padding: '0rem 3rem 0.5rem 3rem',
  },

  divider: {
    marginBottom: theme.spacing(2),
  },

  buttonCreate: {
    margin: theme.spacing(3, 0),
  },

  buttonCancel: {
    margin: theme.spacing(3, 0),
    background: 'none',
    boxShadow: 'none',
    transition: '0.2s',
    '&:hover': {
      background: 'none',
      boxShadow: 'none',
      opacity: 0.4,
    },
  },
  formsNewProject: {
    width: 500,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  formButtonsContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  formsDateContainer: {
    width: '100%',
    display: 'flex',
    marginTop: '2.5rem',
  },
  footerContainer: {
    width: '100%',
    display: 'flex',
    marginTop: '2.5rem',
  },
}));
