import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  buttonAddMember: {
    width: '60px',
    height: '60px',
    margin: '8px 8px 8px 0',
    background: '#F8F8F8',
    '@media (max-width: 1500px)': {
      width: '35px',
      height: '35px',
    },
  },
  addMember: {
    width: '30px',
    height: '30px',
    '@media (max-width: 1500px)': {
      width: '25px',
      height: '25px',
    },
  },
  openCard: {
    position: 'absolute',
    background: 'white',
    zIndex: 10,
    marginTop: '-5px',
  },
  closeCard: {
    display: 'none',
  },
  cardContent: {
    width: '15vw',
    height: '9rem',
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    overflow: 'auto',
    '@media (max-width: 900px)': {
      width: '25vw',
    },
  },
  field: {
    width: '100%',
    marginBottom: '5px',
    background: '#F8F8F8',
  },
  searchIcon: {
    width: '20px',
    heigth: '20px',
  },
  contentMember: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonMember: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '5px 12px',
  },
  typography: {
    fontSize: '14px',
    textTransform: 'capitalize',
  },
  userIcon: {
    width: '30px',
    height: '30px',
  },
}));
