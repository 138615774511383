import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  dialogTitle: {
    fontSize: '28px',
    fontFamily: 'Quicksand, sans-serif',
    fontWeight: 500,
    marginLeft: theme.spacing(1),
  },
  dialogTitleWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  dialogMessage: {
    fontSize: '14px',
    fontFamily: 'Quicksand, sans-serif',
    fontWeight: 500,
  },
  task: {
    userSelect: 'none',
    padding: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
    background: '#fff',
    display: 'flex',
    height: '45px',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontFamily: 'Quicksand, sans-serif',
    boxShadow: '0px 3px 2px 0px rgba(0,0,0,0.06)',
    borderRadius: '4px',
    marginTop: theme.spacing(5),
  },
  taskLeft: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  issue: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '.5rem',
  },
  taskID: {
    opacity: '0.45',
    fontSize: '13px',
    padding: theme.spacing(0.3, 1),
  },
  taskDifficulty: {
    fontSize: '14px',
    margin: theme.spacing(0, 2),
  },
  estimate: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    color: '#898989',
  },
  taskDescription: {
    fontSize: '16px',
  },
  taskRight: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
  },
  taskStatus: {
    backgroundColor: '#f8f8f8',
    fontSize: '12px',
    textTransform: 'uppercase',
    padding: theme.spacing(0.5, 1),
    borderRadius: '5px',
  },
  buttonCreate: {
    margin: theme.spacing(3, 0),
    fontFamily: 'Quicksand, sans-serif',
    textTransform: 'none',
  },
  formButtonsContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: theme.spacing(2),
  },
  time: {
    marginLeft: '.2rem',
  },
}));
