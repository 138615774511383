import * as requests from '../requests';

import {
  TasksCreateRequest,
  TasksCreateRes,
  TasksSortPositionsRequest,
  TasksSortPositionsRes,
  TasksDeleteRequest,
  TasksDeleteRes,
  TasksReadRequest,
  TasksReadRes,
  TasksUpdateRequest,
  TasksUpdateRes,
  TasksUpdatePositionRequest,
  TasksUpdatePositionRes,
} from '../../interfaces/Tasks';

export const create = requests.create<TasksCreateRequest, TasksCreateRes>(
  'tasks',
);

export const read = requests.read<TasksReadRequest, TasksReadRes>('tasks');

export const update = requests.update<TasksUpdateRequest, TasksUpdateRes>(
  'tasks',
);

export const sortPositions = requests.generic<
  TasksSortPositionsRes,
  TasksSortPositionsRequest
>(requests.Method.POST, 'tasks', 'sortPositions');

export const updatePosition = requests.generic<
  TasksUpdatePositionRes,
  TasksUpdatePositionRequest
>(requests.Method.PATCH, 'tasks', 'updatePosition');

export const remove = requests.remove<TasksDeleteRequest, TasksDeleteRes>(
  'tasks',
);
