import { createModel } from '@rematch/core';
import { RootModel } from '.';
import * as api from '../api/clients';
import {
  ClientsCreateRequest,
  ClientsCreateRes,
  ClientsReadRequest,
  ClientsReadRes,
  ClientsReadAllRes,
  ClientsReadAllWithPaginationRequest,
  ClientsReadAllWithPaginationRes,
  ClientsUpdateRequest,
  ClientsUpdateRes,
} from '../interfaces/Clients';

type ClientState = {
  createdClient: ClientsCreateRes | null;
  client: ClientsReadRes | null;
  clients: ClientsReadAllRes[] | null;
  clientsWithPagination: ClientsReadAllWithPaginationRes | null;
  updatedClient: ClientsUpdateRes | null;
};

export const Clients = createModel<RootModel>()({
  state: {} as ClientState,

  reducers: {
    loadCreatedClient: (state, payload: ClientsCreateRes) => ({
      ...state,
      createdClient: payload,
    }),

    loadClient: (state, payload: ClientsReadRes) => ({
      ...state,
      client: payload,
    }),

    loadClients: (state, payload: ClientsReadAllRes[]) => ({
      ...state,
      clients: payload,
    }),

    loadClientsWithPagination: (
      state,
      payload: ClientsReadAllWithPaginationRes,
    ) => ({
      ...state,
      clientsWithPagination: payload,
    }),

    loadUpdatedClient: (state, payload: ClientsUpdateRes) => ({
      ...state,
      updatedClient: payload,
    }),
  },

  effects: dispatch => ({
    create: async (payload: ClientsCreateRequest) => {
      const res = await api.create(payload);
      dispatch.Clients.loadCreatedClient(res);
    },

    read: async (payload: ClientsReadRequest) => {
      const res = await api.read(payload);
      dispatch.Clients.loadClient(res);
    },

    readAll: async () => {
      const res = await api.readAll();
      dispatch.Clients.loadClients(res);
    },

    readAllWithPagination: async (
      payload: ClientsReadAllWithPaginationRequest,
    ) => {
      const res = await api.readAllWithPagination(payload);
      dispatch.Clients.loadClientsWithPagination(res);
    },

    update: async (payload: ClientsUpdateRequest) => {
      const res = await api.update(payload);
      dispatch.Clients.loadUpdatedClient(res);
    },
  }),
});
