import { createModel } from '@rematch/core';
import { RootModel } from '.';

interface DrawerProps {
  path: string;
}

export const Drawer = createModel<RootModel>()({
  state: {} as any,
  reducers: {
    loadRoutes: (state, payload: DrawerProps) => ({
      path: payload.path,
    }),
  },
});
