import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  fabComponent: {
    color: '#FFFFFF',
    backgroundColor: '#263579',
    '&:hover': { backgroundColor: '#1565c0' },
  },

  root: {
    height: 380,
    flexGrow: 1,
    transform: 'translateZ(0px)',
  },

  speedDial: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },

  options: {
    width: '100%',
    margin: '0.2rem',
    padding: '0.5rem',
    textAlign: 'center',
    borderRadius: '4px',
    boxSizing: 'border-box',
    backgroundColor: '#fdfdfd',
    border: '1px solid #E2DFDF',
    boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.1)',
  },

  list: {
    padding: '0',
    boxShadow: 'none',
  },
  menuList: {
    padding: '0',
    boxShadow: 'none',
    marginBottom: '3rem',
  },

  modalTitle: {
    padding: '0',
  },

  modalContent: {
    padding: '8px 18px 8px 18px',
  },
}));
