/* eslint-disable dot-notation */
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { UNAUTHORIZED } from 'http-status';

import { store } from '../store';

const getAuthHeaders = (config: AxiosRequestConfig) => {
  const { token } = store.getState().Auth;

  if (token) config.headers.Authorization = `Bearer ${token}`;

  return config;
};

const handleUnauthorized = (error: AxiosError): Promise<AxiosError> => {
  if (error.response?.status === UNAUTHORIZED) {
    // dispatch.Auth.logout();
  }
  return Promise.reject(error);
};

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL!,
});

api.interceptors.request.use(getAuthHeaders);
api.interceptors.response.use(undefined, handleUnauthorized);

export { api };
