/* eslint-disable camelcase */
import { createModel } from '@rematch/core';
import { RootModel } from '.';

import { dispatch } from '../store';
import * as api from '../api/requirements';

import {
  RequirementsCreateRequest,
  RequirementsCreateRes,
  RequirementsReadRequest,
  RequirementsReadRes,
  RequirementsReadAllRequest,
  RequirementsReadAllWithPaginationRequest,
  RequirementsReadAllRes,
  RequirementsReadAllWithPaginationRes,
  RequirementsUpdateRequest,
  RequirementsUpdateRes,
} from '../interfaces/Requirements';

interface RequirementsState {
  createdRequirement: RequirementsCreateRes | undefined;
  requirement: RequirementsReadRes | undefined;
  requirements: RequirementsReadAllRes[];
  requirementsWithPagination: RequirementsReadAllWithPaginationRes | undefined;
  updatedRequirement: RequirementsUpdateRes | undefined;
}

export const Requirements = createModel<RootModel>()({
  state: {} as RequirementsState,

  reducers: {
    loadCreatedRequirement: (
      state: RequirementsState,
      payload: RequirementsCreateRes,
    ) => ({
      ...state,
      createdRequirement: payload,
    }),

    loadRequirement: (
      state: RequirementsState,
      payload: RequirementsReadRes,
    ) => ({
      ...state,
      requirement: payload,
    }),

    loadRequirements: (
      state: RequirementsState,
      payload: RequirementsReadAllRes[],
    ) => ({
      ...state,
      requirements: payload,
    }),

    loadRequirementsWithPagination: (
      state: RequirementsState,
      payload: RequirementsReadAllWithPaginationRes,
    ) => ({
      ...state,
      requirementsWithPagination: payload,
    }),

    loadUpdatedRequirement: (
      state: RequirementsState,
      payload: RequirementsUpdateRes,
    ) => ({
      ...state,
      updatedRequirement: payload,
    }),
  },

  effects: () => ({
    create: async (payload: RequirementsCreateRequest) => {
      const res = await api.create(payload);
      dispatch.Requirements.loadCreatedRequirement(res);
    },

    read: async (payload: RequirementsReadRequest) => {
      const res = await api.read(payload);
      dispatch.Requirements.loadRequirement(res);
    },

    readAll: async (payload: RequirementsReadAllRequest) => {
      const res = await api.readAll(payload);
      dispatch.Requirements.loadRequirements(res);
    },

    readAllWithPagination: async (
      payload: RequirementsReadAllWithPaginationRequest,
    ) => {
      const res = await api.readAllWithPagination(payload);
      dispatch.Requirements.loadRequirementsWithPagination(res);
    },

    update: async (payload: RequirementsUpdateRequest) => {
      const res = await api.update(payload);
      dispatch.Requirements.loadUpdatedRequirement(res);
    },
  }),
});
