import { createModel } from '@rematch/core';
import { RootModel } from '.';

import * as api from '../api/columns';

import {
  ColumnsCreateRequest,
  ColumnsCreateRes,
  ColumnsDeleteRequest,
  ColumnsDeleteRes,
  ColumnsReadRequest,
  ColumnsReadRes,
  ColumnsSortPositionsRequest,
  ColumnsSortPositionsRes,
  ColumnsUpdateRequest,
  ColumnsUpdateRes,
} from '../interfaces/Columns';

export interface ColumnsState {
  createdColumn: ColumnsCreateRes;
  deletedColumn: ColumnsDeleteRes;
  column: ColumnsReadRes;
  sortedColumns: ColumnsSortPositionsRes;
  updatedColumn: ColumnsUpdateRes;
}

export const Columns = createModel<RootModel>()({
  state: {} as ColumnsState,
  reducers: {
    loadCreatedColumn: (state, payload: ColumnsCreateRes) => ({
      ...state,
      createdColumn: payload,
    }),

    loadDeletedColumn: (state, payload: ColumnsDeleteRes) => ({
      ...state,
      deletedColumn: payload,
    }),

    loadColumn: (state, payload: ColumnsReadRes) => ({
      ...state,
      column: payload,
    }),

    loadSortedColumns: (state, payload: ColumnsSortPositionsRes) => ({
      ...state,
      sortedColumns: payload,
    }),

    loadUpdatedColumn: (state, payload: ColumnsUpdateRes) => ({
      ...state,
      updatedColumn: payload,
    }),
  },
  effects: dispatch => ({
    read: async (payload: ColumnsReadRequest) => {
      const response = await api.read(payload);
      dispatch.Columns.loadColumn(response);
    },

    create: async (payload: ColumnsCreateRequest) => {
      const response = await api.create(payload);
      dispatch.Columns.loadCreatedColumn(response);
    },

    update: async (payload: ColumnsUpdateRequest) => {
      const response = await api.update(payload);
      dispatch.Columns.loadUpdatedColumn(response);
    },

    remove: async (payload: ColumnsDeleteRequest) => {
      const response = await api.remove(payload);
      dispatch.Columns.loadDeletedColumn(response);
    },

    sortPositions: async (payload: ColumnsSortPositionsRequest) => {
      const response = await api.sortPositions(payload);
      dispatch.Columns.loadSortedColumns(response);
    },
  }),
});
