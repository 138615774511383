import { makeStyles } from '@material-ui/core';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: 'calc(100% - 57px)',
    flexWrap: 'wrap',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    textAlign: 'left',
    fontFamily: 'Quicksand, sans-serif',
  },
  userButton: {
    marginRight: theme.spacing(1),
    paddingLeft: 0,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  logo: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    filter: 'brightness(0) invert(1)',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
  },
  drawerButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    fontSize: '20px',
    color: '#757575',
    cursor: 'pointer',
  },
  openDrawerButton: {
    justifyContent: 'center',
  },
  closeDrawerButton: {
    paddingRight: theme.spacing(2),
    justifyContent: 'end',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    width: '100%',
    marginTop: '.8rem',
    marginLeft: '3rem',
  },
}));

export { useStyles };
