import React from 'react';

import Typography from '@material-ui/core/Typography';

import ReportProblem from '@material-ui/icons/ReportProblem';
import Box from '@material-ui/core/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import Button from '@material-ui/core/Button';

interface ConfirmDeleteModalProps {
  handleClose: () => void;
  handleDelete: () => void;
}

const ConfirmDeleteModal: React.FC<ConfirmDeleteModalProps> = ({
  handleClose,
  handleDelete,
}: ConfirmDeleteModalProps) => (
  <Box px={5}>
    <Box display="flex" style={{ gap: '1rem' }} mb={3}>
      <ReportProblem color="error" fontSize="large" />
      <Typography variant="h2">Excluir coluna?</Typography>
    </Box>
    <Box mb={3}>
      <Typography variant="h5">
        Você está prestes a excluir permanentemente esta coluna. Todas as issues
        desta coluna voltarão para coluna A Fazer. Tem certeza que deseja
        continuar?
      </Typography>
    </Box>
    <Box textAlign="end" mb={3}>
      <ButtonBase onClick={handleClose} style={{ marginRight: '1rem' }}>
        <Typography variant="button">Cancelar</Typography>
      </ButtonBase>
      <Button
        variant="contained"
        onClick={handleDelete}
        style={{ backgroundColor: '#f44336', color: '#fff' }}
      >
        Excluir
      </Button>
    </Box>
  </Box>
);

export default ConfirmDeleteModal;
