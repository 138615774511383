import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { RootState } from '../../config/store';

export interface PropsHomeIcon {}

const HomeIcon = () => {
  const route = useSelector((state: RootState) => state.Drawer.path);

  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (route && route === '/home') {
      setIsActive(true);
    }
  }, [route]);

  return (
    <svg
      width="23"
      height="21"
      viewBox="0 0 23 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.48898 19.5829V13.5829H14.289V19.5829C14.289 20.2429 14.829 20.7829 15.489 20.7829H19.089C19.749 20.7829 20.289 20.2429 20.289 19.5829V11.1829H22.329C22.881 11.1829 23.145 10.4989 22.725 10.1389L12.693 1.10287C12.237 0.694875 11.541 0.694875 11.085 1.10287L1.05298 10.1389C0.644985 10.4989 0.896985 11.1829 1.44898 11.1829H3.48898V19.5829C3.48898 20.2429 4.02898 20.7829 4.68898 20.7829H8.28898C8.94898 20.7829 9.48898 20.2429 9.48898 19.5829Z"
        fill={isActive === false ? '#6c6c6c' : '#263579'}
      />
    </svg>
  );
};
export default HomeIcon;
