import { ThemeProvider } from '@material-ui/styles';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';

import Theme from './theme';

import AppRouter from './routes';

function App() {
  return (
    <ThemeProvider theme={Theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <AppRouter />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}

export default App;
