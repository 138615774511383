import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  task: {
    userSelect: 'none',
    padding: theme.spacing(1.5),
    paddingTop: theme.spacing(0.5),
    marginBottom: '15px',
    color: 'black',
    fontFamily: 'Quicksand, sans-serif',
    background: '#fff',
    boxShadow: '0px 3px 2px 0px rgba(0,0,0,0.06)',
    borderRadius: '4px',
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  taskName: {
    fontWeight: 600,
    letterSpacing: '1.5px',
    fontSize: '15px',
  },
  info: {
    marginTop: '15px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  project: {
    marginTop: theme.spacing(1),
  },
  projectName: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#B6B6B6',
    border: '1px solid #DEE1EA',
    padding: '3px 8px',
    borderRadius: '5px',
  },
  issue: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    color: '#898989',
    paddingBottom: '.5rem',
  },
  id: {
    marginLeft: '.2rem',
  },
  estimate: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    color: '#898989',
    marginRight: '.5rem',
  },
  time: {
    marginLeft: '.3rem',
  },
  taskMenuTitle: {
    fontSize: '14px',
    fontWeight: 'bold',
    fontFamily: 'Quicksand, sans-serif',
    color: '#A7A7A7',
    marginLeft: '15px',
    marginBottom: theme.spacing(2),
  },
  taskMenuItem: {
    marginTop: theme.spacing(1),
    fontSize: '16px',
    fontFamily: 'Quicksand, sans-serif',
    fontWeight: 400,
    color: '#373737',
  },
  taskMenuItemApagar: {
    fontSize: '16px',
    fontFamily: 'Quicksand, sans-serif',
    fontWeight: 400,
    color: '#DA4545',
  },
  modalTitle: {
    padding: '0',
  },
  modalContent: {
    padding: '0 4rem 0 1rem',
  },
  root: {
    width: '40rem',
  },
  avatar: {
    width: '35px',
    height: '35px',
    fontSize: '15px',
  },
}));
