import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

interface PDSDialogProps {
  title: string;
  open: boolean;
  content?: JSX.Element;
  buttonText?: string;
  handleClose: () => void;
  confirm?: (data?: any) => void;
  showActions?: boolean;
  fullWidth?: boolean;
  titleIcon?: JSX.Element;
  classDialogTitle?: string;
  classDialogContent?: string;
  maxWidth?: false | 'lg' | 'md' | 'sm' | 'xl' | 'xs' | undefined;
}

const PDSDialog: React.FC<PDSDialogProps> = ({
  title,
  open = false,
  content,
  buttonText = 'Confirmar',
  handleClose,
  confirm = () => {},
  showActions = true,
  fullWidth = false,
  titleIcon,
  maxWidth,
  classDialogTitle,
  classDialogContent,
}) => {
  const handleClick = () => {
    confirm();
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className={classDialogTitle}>
        {titleIcon}
        {title}
      </DialogTitle>
      <DialogContent className={classDialogContent}>{content}</DialogContent>
      {showActions && (
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleClick} color="primary">
            {buttonText}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default PDSDialog;
