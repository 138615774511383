/* eslint-disable camelcase */
import * as requests from '../requests';
import { ProjectData } from '../../models/projects';

export interface CreateOptions {
  name: string;
  status: string;
  description?: string;
  client_id: number;
  start_date: string;
  finish_date: string;
  user_responsible_id?: number;
}
export interface EditOptions {
  id: number;
  name: string;
  status: string;
  start_date?: string;
  finish_date?: string;
  user_responsible_id?: number;
}

interface ReadOptions {
  id: number;
}

export const create = requests.create<CreateOptions, ProjectData>('projects');
export const read = requests.read<ReadOptions, ProjectData>('projects');
export const readAll = requests.readAll<ProjectData>('projects');
export const update = requests.update<EditOptions, ProjectData>('projects');
