import React, { useState } from 'react';

import { DraggableProvided, Droppable } from 'react-beautiful-dnd';

import Box from '@material-ui/core/Box';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import MoreVertIcon from '@material-ui/icons/MoreVert';

import BoardTasks from '../BoardTasks';

import Dialog from '../../../../../components/Dialog';
import ConfirmDeleteModal from './Components/ConfirmDeleteModal';

import { useStyles } from './styles';

import { dispatch } from '../../../../../config/store';
import { SprintReadRes } from '../../../../../config/interfaces/Sprints';

interface ColumnProps {
  id: number;
  column: {
    id: number;
    name: string;
    items: SprintReadRes['sprint']['columns'][0]['tasks'];
  };

  handleCreateIssue: () => void;
  handleColumnId: (id: number) => void;
  providedColumnDrag: DraggableProvided;
  fetchColumns: () => Promise<void>;
  issues: number;
}

const BoardColumn: React.FC<ColumnProps> = ({
  id,
  column,
  handleColumnId,
  handleCreateIssue,
  providedColumnDrag,
  fetchColumns,
  issues,
}: ColumnProps) => {
  const classes = useStyles();

  const [deleteColumnModalOpen, setDeleteColumnModalOpen] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [currentColumn, setCurrentColumn] = useState<number>();

  const updateAnchor = (event?: React.MouseEvent<HTMLButtonElement>) => {
    if (event) {
      setMenuAnchor(event.currentTarget);
    } else {
      setMenuAnchor(null);
    }
  };

  const closeModal = () => {
    setDeleteColumnModalOpen(false);
  };

  const handleDeleteColumn = () => {
    if (currentColumn) {
      dispatch.Columns.remove({ id: currentColumn }).then(() => {
        setDeleteColumnModalOpen(false);
        fetchColumns();
      });
    }
  };

  return (
    <div className={classes.column}>
      <Dialog
        title=""
        content={
          <ConfirmDeleteModal
            handleClose={closeModal}
            handleDelete={handleDeleteColumn}
          />
        }
        fullWidth
        maxWidth="md"
        open={deleteColumnModalOpen}
        handleClose={closeModal}
        showActions={false}
      />

      <Box
        my={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        {...providedColumnDrag.dragHandleProps}
      >
        <Typography variant="h5" className={classes.columnTitle}>
          {column.name.toUpperCase()}
          <span className={classes.issues}>{`${issues} issues`}</span>
        </Typography>
        <IconButton
          aria-controls="task-actions"
          aria-haspopup="true"
          onClick={updateAnchor}
          aria-label="about"
        >
          <MoreVertIcon fontSize="small" />
        </IconButton>
        <Menu
          id="task-actions"
          anchorEl={menuAnchor}
          keepMounted
          open={Boolean(menuAnchor)}
          onClose={() => setMenuAnchor(null)}
        >
          <span className={classes.columnMenuTitle}>Ações</span>
          <MenuItem
            onClick={() => {
              updateAnchor();
              setDeleteColumnModalOpen(true);
              setCurrentColumn(column.id);
            }}
            className={classes.columnMenuItem}
          >
            Apagar
          </MenuItem>
        </Menu>
      </Box>
      <Droppable droppableId={`${id}`} key={id} type="task">
        {provided => (
          <div
            className={classes.droppable}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {column.items &&
              column.items.map((item, index) => (
                <BoardTasks
                  key={item.id}
                  item={item}
                  index={index}
                  columnId={id}
                  fetchColumns={fetchColumns}
                />
              ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      <div className={classes.createTaskContainer}>
        <Button
          className={classes.createTask}
          onClick={() => {
            handleColumnId(id);
            handleCreateIssue();
          }}
          variant="contained"
          key={id}
        >
          + Criar issue
        </Button>
      </div>
    </div>
  );
};

export default BoardColumn;
