import * as requests from '../requests';

import {
  RequirementsCreateRequest,
  RequirementsCreateRes,
  RequirementsReadRequest,
  RequirementsReadRes,
  RequirementsReadAllRequest,
  RequirementsReadAllWithPaginationRequest,
  RequirementsReadAllRes,
  RequirementsReadAllWithPaginationRes,
  RequirementsUpdateRequest,
  RequirementsUpdateRes,
} from '../../interfaces/Requirements';

export const read = requests.read<RequirementsReadRequest, RequirementsReadRes>(
  'requirements',
);
export const update = requests.update<
  RequirementsUpdateRequest,
  RequirementsUpdateRes
>('requirements');
export const create = requests.create<
  RequirementsCreateRequest,
  RequirementsCreateRes
>('requirements');

export const readAll = requests.readAll<
  RequirementsReadAllRes,
  RequirementsReadAllRequest
>('requirements');

export const readAllWithPagination = requests.readAllWithPagination<
  RequirementsReadAllWithPaginationRes,
  RequirementsReadAllWithPaginationRequest
>('requirements');
