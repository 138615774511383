import React, { useCallback, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import Chart from 'react-apexcharts';

import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { CircularProgress } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import { useSelector } from 'react-redux';

import AccessTimeIcon from '@material-ui/icons/AccessTime';

import AddMember from './components/AddMember';

import { dispatch, RootState, store } from '../../../../config/store';

import { SprintDashboardSprintRes } from '../../../../config/interfaces/Sprints';
import {
  SquadReadHistoricRes,
  SquadReadRes,
} from '../../../../config/interfaces/Squads';

import { SprintProgress, useStyles } from './styles';

const SquadSettings: React.FC = () => {
  const { squad } = useParams<{ squad: string }>();
  const squadId = +squad;

  const squadData = useSelector((state: RootState) => state.Squads.squad);

  const [sprint, setSprint] = useState<SprintDashboardSprintRes>();
  const [sprints, setSprints] = useState<SquadReadHistoricRes>();

  const [hoursPercentage, setHoursPercentage] = useState<number[]>([]);
  const [issuesPercentage, setIssuesPercentage] = useState<number[]>([]);
  const [totalHoursPorcentage, setTotalHoursPercentage] = useState<number[]>(
    [],
  );

  const classes = useStyles();

  const status = [{ name: 'EM PROGRESSO' }, { name: 'FINALIZADO' }];

  const chartOptions = {
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    colors: ['#263579', '#B9B9B7'],
    plotOptions: {
      pie: {
        donut: {
          size: '80%',
        },
      },
    },
  };

  useEffect(() => {
    if (sprints && !sprint) {
      handleGetSprint(sprints[0].id);
    }
  }, [sprint, sprints]);

  useEffect(() => {
    async function getHistoricSprints() {
      if (!sprints && squadData) {
        await dispatch.Squads.readHistoric({
          id: squadData.id,
        }).then(() => {
          setSprints(store.getState().Squads.squadHistoric);
        });
      }
    }
    getHistoricSprints();
  }, [sprints, squadData]);

  useEffect(() => {
    if (sprint?.dashboard) {
      setIssuesPercentage(
        getPercentage(
          Number(sprint.dashboard.tasks_finished),
          Number(sprint.dashboard.total_task),
        ),
      );

      setHoursPercentage(
        getPercentage(
          Number(sprint.dashboard.total_time_spent),
          Number(sprint.dashboard.total_time_estimated),
        ),
      );
      setTotalHoursPercentage(
        getPercentage(
          Number(sprint.dashboard.total_time_spent_finished),
          Number(sprint.dashboard.total_time_estimated_finished),
        ),
      );
    }
  }, [sprint]);

  const calculateProgress = (e: string, s: string) => {
    const today = new Date();
    const start = handleFomatDateMask(s);
    const end = handleFomatDateMask(e);

    const remainingDays = Math.ceil(
      (today.getTime() - end.getTime()) / (24 * 60 * 60 * 1000),
    );
    const passedDays = Math.abs(
      Math.ceil((today.getTime() - start.getTime()) / (24 * 60 * 60 * 1000)),
    );

    return remainingDays >= 0
      ? 100
      : (passedDays * 100) / (remainingDays * -1 + passedDays);
  };

  const getPercentage = (value: number, total: number) => {
    const percentage = total > 0 ? Math.ceil(value / (total / 100)) : 100;
    return [percentage, 100 - percentage];
  };

  const handleUserInitialMask = (user: string) => {
    const initials = user.substring(0, 2).toUpperCase();
    return `${initials}`;
  };

  const handleFomatDateMask = (time: string) => {
    const [convertTime] = time.replaceAll('\u005F', '0').split('T');
    const date = new Date(convertTime);
    return date;
  };

  const handleGetSprint = (id: number) => {
    dispatch.Sprints.dashboardSprintAsync({ params: { id } }).then(() => {
      setSprint(store.getState().Sprints.dashboardSprint);
    });
  };

  const handleColorMask = () => {
    const r = Math.round(Math.random() * 200).toString();
    const g = Math.round(Math.random() * 200).toString();
    const b = Math.round(Math.random() * 200).toString();

    return `rgba(${r}, ${g}, ${b}, 1)`;
  };

  const handleGetSquad = () => {
    dispatch.Squads.read({ id: squadId });
  };

  return (
    <Box>
      <Typography className={classes.squadName} variant="h5">
        {squadData?.name}
      </Typography>

      <Box display="flex" className={classes.content}>
        <Box display="flex" flexDirection="column" className={classes.boxOne}>
          <Box
            px={1}
            py={2}
            mb={3}
            border={1}
            borderColor="secondary.main"
            borderRadius={8}
            height="33%"
            className={classes.boxDescribe}
          >
            <Typography className={classes.label}>Descrição</Typography>
            <TextField
              value={squadData?.description}
              InputProps={{
                classes: {
                  multiline: classes.multiline,
                  input: classes.multiline,
                },
                disableUnderline: true,
              }}
              className={classes.squadDescription}
              id="descricao-squad"
              variant="standard"
              multiline
              disabled
              rows={4}
            />
          </Box>

          <Box
            px={1}
            py={2}
            border={1}
            borderColor="secondary.main"
            borderRadius={8}
            flexGrow={1}
            className={classes.boxMembers}
          >
            <Typography className={classes.label} gutterBottom>
              Membros
            </Typography>
            <Box className={classes.profilePictureContainer}>
              <Box className={classes.profilePictureContent}>
                {squadData?.users.map(user => (
                  <Avatar
                    className={classes.profilePicture}
                    style={{ backgroundColor: `${handleColorMask()}` }}
                  >
                    {handleUserInitialMask(user.name)}
                  </Avatar>
                ))}

                <AddMember squad={squadData!} handleGetSquad={handleGetSquad} />
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          className={classes.boxTwo}
          pl={1}
          py={2}
          ml={3}
          border={1}
          borderColor="secondary.main"
          borderRadius={8}
        >
          <Typography className={classes.label}>Sprints</Typography>
          <Box mt={2} className={classes.containerBoxTwo}>
            <Box
              overflow="hidden scroll"
              height={653}
              className={classes.sprintsScroll}
            >
              {sprints ? (
                sprints.map(s => (
                  <Button
                    className={classes.sprintButton}
                    onClick={() => {
                      handleGetSprint(s.id);
                    }}
                  >
                    <Box
                      border={1}
                      borderColor="secondary.main"
                      borderRadius={8}
                      className={classes.sprintCard}
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                    >
                      <Typography className={classes.sprintNameCard}>
                        {`${s.name} ${s.sprint_number}`}
                      </Typography>
                      <Box display="flex" alignItems="center">
                        <Box mr={2} display="flex" alignItems="center">
                          <AccessTimeIcon className={classes.sprintDuration} />
                          <Typography className={classes.sprintDuration}>
                            {s.total_estimated_time}
                          </Typography>
                        </Box>
                        <Typography className={classes.sprintProgress}>
                          {s.active ? status[0].name : status[1].name}
                        </Typography>
                      </Box>
                    </Box>
                  </Button>
                ))
              ) : (
                <div className={classes.loading}>
                  <CircularProgress className={classes.circularProgress} />
                </div>
              )}
            </Box>
            {sprint?.dashboard ? (
              <Box className={classes.boxObjective}>
                <Typography className={classes.sprintName} gutterBottom>
                  {sprint.sprint.name}
                </Typography>

                <Typography className={classes.sprintGoal} gutterBottom>
                  {sprint.sprint.goal ?? 'Sem Descrição'}
                </Typography>

                <Box my={2}>
                  <Typography className={classes.label} gutterBottom>
                    Objetivo
                  </Typography>
                </Box>

                <Box>
                  <Typography className={classes.label} gutterBottom>
                    Progresso
                  </Typography>
                  <SprintProgress
                    variant="determinate"
                    value={calculateProgress(
                      sprint.sprint.expected_end_date,
                      sprint.sprint.created_at,
                    )}
                  />
                </Box>

                <Box display="flex" className={classes.boxData}>
                  <Typography className={classes.label} gutterBottom>
                    {handleFomatDateMask(
                      sprint.sprint.created_at,
                    ).toLocaleString('pt-BR', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                    })}
                  </Typography>
                  <Typography className={classes.label} gutterBottom>
                    {handleFomatDateMask(
                      sprint.sprint.expected_end_date,
                    ).toLocaleString('pt-BR', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                    })}
                  </Typography>
                </Box>

                <Box display="flex" justifyContent="center" mt={4}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Chart
                        options={chartOptions}
                        series={issuesPercentage}
                        type="donut"
                        className={classes.chart}
                      />
                      <Typography className={classes.percentage} gutterBottom>
                        {issuesPercentage[0]}%
                      </Typography>
                    </Box>
                    <Typography className={classes.label} gutterBottom>
                      Issues Concluídas
                    </Typography>
                  </Box>

                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Chart
                        options={chartOptions}
                        series={hoursPercentage}
                        type="donut"
                        className={classes.chart}
                      />
                      <Typography className={classes.percentage} gutterBottom>
                        {hoursPercentage[0]}%
                      </Typography>
                    </Box>
                    <Typography className={classes.label} gutterBottom>
                      Horas Concluídas
                    </Typography>
                  </Box>

                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Chart
                        options={chartOptions}
                        series={hoursPercentage}
                        type="donut"
                        className={classes.chart}
                      />
                      <Typography className={classes.percentage} gutterBottom>
                        {totalHoursPorcentage[0]}%
                      </Typography>
                    </Box>
                    <Typography className={classes.label} gutterBottom>
                      Horas Totais
                    </Typography>
                  </Box>
                </Box>

                <Box
                  className={classes.containerSprintValue}
                  display="flex"
                  justifyContent="space-between"
                  mt={6}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <i className={`fas fa-check  ${classes.sprintDataIcon}`} />
                    <Typography
                      className={classes.sprintDataLabel}
                      gutterBottom
                    >
                      Issues Totais
                    </Typography>
                    <Typography
                      className={classes.sprintDataValue}
                      gutterBottom
                    >
                      {sprint.dashboard.total_task}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <i
                      className={`fas fa-check-double  ${classes.sprintDataIcon}`}
                    />
                    <Typography
                      className={classes.sprintDataLabel}
                      gutterBottom
                    >
                      Issues Concluidas
                    </Typography>
                    <Typography
                      className={classes.sprintDataValue}
                      gutterBottom
                    >
                      {sprint.dashboard.tasks_finished}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <i className={`fas fa-clock  ${classes.sprintDataIcon}`} />
                    <Typography
                      className={classes.sprintDataLabel}
                      gutterBottom
                    >
                      Horas Estimadas
                    </Typography>
                    <Typography
                      className={classes.sprintDataValue}
                      gutterBottom
                    >
                      {sprint.dashboard.total_time_estimated} horas
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <i className={`far fa-clock  ${classes.sprintDataIcon}`} />
                    <Typography
                      className={classes.sprintDataLabel}
                      gutterBottom
                    >
                      Horas Gastas
                    </Typography>
                    <Typography
                      className={classes.sprintDataValue}
                      gutterBottom
                    >
                      {sprint.dashboard.total_time_spent_finished} horas
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ) : (
              <div className={classes.loading}>
                <CircularProgress
                  className={classes.circularProgress}
                  size={80}
                />
              </div>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SquadSettings;
