/* eslint-disable no-restricted-globals */

import React, { useState, useEffect, useRef } from 'react';

import { useHistory } from 'react-router-dom';

import { useSelector } from 'react-redux';

import * as Yup from 'yup';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { store, RootState } from '../../config/store';

import Logo from '../../assets/pds-logo-v2.svg';

import { useStyles } from './styles';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="http://www.pdsolucoes.com.br/">
        PD Soluções
      </Link>
      {new Date().getFullYear()}
    </Typography>
  );
}

interface Error {
  email?: string;
  password?: string;
}

const Index: React.FC = () => {
  const [error, setError] = useState<Error>({} as Error);
  const [invalidCredential, setInvalidCredential] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const { dispatch } = store;
  const { token } = useSelector((state: RootState) => state.Auth);

  const history = useHistory();

  const classes = useStyles();

  const formRef = useRef<FormHandles>(null);

  const schema = Yup.object().shape({
    email: Yup.string()
      .email('Insira um e-mail válido.')
      .required('E-mail é obrigatório'),
    password: Yup.string()
      .min(6, 'A senha deve ter no minimo 6 caracteres.')
      .required('Senha é obrigatória.'),
  });

  useEffect(() => {
    if (token) {
      //  const { afterLogin } =
      //  (history.location.state as Record<string, any>) ?? {};
      //  history.push(afterLogin ?? '/home');
      history.push('/home');
    }
  }, [token, history]);

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setError({ ...error, email: '' });
    setInvalidCredential(false);
  };

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    setError({ ...error, password: '' });
    setInvalidCredential(false);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const handleSubmit = async () => {
    try {
      formRef?.current?.setErrors({});

      const values = { email, password };

      await schema.validate(values, {
        abortEarly: false,
      });

      if (values) {
        dispatch.Auth.loginAsync(values).catch(err => {
          setInvalidCredential(err);
        });
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const validationErrors = {};
        err.inner.forEach(errors => {
          // @ts-ignore
          validationErrors[errors?.path] = errors.message;
        });
        formRef.current?.setErrors(validationErrors);
        setError(validationErrors);
      }
    }
  };

  return (
    <>
      <Container component="main" maxWidth="xs" className={classes.container}>
        <CssBaseline />
        <Paper elevation={12} className={classes.paper}>
          <Box className={classes.logo}>
            <img src={Logo} alt="PD Soluções" className={classes.img} />
          </Box>
          <Form ref={formRef} className={classes.form} onSubmit={handleSubmit}>
            <TextField
              className={classes.inputEmail}
              variant="outlined"
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              size="small"
              onChange={handleChangeEmail}
              error={!!error.email}
            />
            {error?.email && <div className={classes.error}>{error.email}</div>}
            <FormControl
              className={classes.inputPassword}
              variant="outlined"
              fullWidth
              size="small"
              error={!!error.password}
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={handleChangePassword}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      size="small"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={75}
              />
              {error?.password && (
                <div className={classes.error}>{error.password}</div>
              )}
            </FormControl>

            <div className={classes.errorCredential}>
              {invalidCredential && (
                <Typography className={classes.errorCredentialText}>
                  Credenciais inválidas
                </Typography>
              )}
            </div>

            <FormControlLabel
              className={classes.remember}
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />

            <Button
              type="submit"
              fullWidth
              color="primary"
              variant="contained"
              className={classes.submit}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link
                  href="/forgot-password"
                  variant="body2"
                  className={classes.link}
                >
                  <Typography className={classes.forgot}>
                    Forgot password?
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          </Form>
        </Paper>
        <Box mt={18}>
          <Copyright />
        </Box>
      </Container>
    </>
  );
};

export default Index;
