import { useEffect, useState } from 'react';

import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import TextInputMUI from '@material-ui/core/TextField';
import CardContent from '@material-ui/core/CardContent';
import InputAdornment from '@material-ui/core/InputAdornment';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import SearchIcon from '@material-ui/icons/Search';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import { useSelector } from 'react-redux';

import { dispatch, RootState, store } from '../../../../../../config/store';

import { SquadReadRes } from '../../../../../../config/interfaces/Squads';
import { UserData } from '../../../../../../config/interfaces/Users';

import { useStyles } from './styles';

interface AddMemberProps {
  squad: SquadReadRes;
  handleGetSquad: () => void;
}

const AddMember: React.FC<AddMemberProps> = ({ squad, handleGetSquad }) => {
  const [rows, setRows] = useState<UserData[]>([]);
  const [open, setOpen] = useState(false);

  const classes = useStyles();

  const users = useSelector((state: RootState) => state.Users.users);

  const fetchData = async () => {
    await dispatch.Users.active().then(() => {
      setRows(store.getState().Users.users);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (users && Object.keys(users).length > 0) {
      setRows(users);
    }
  }, [users]);

  const handleOpenAndClose = () => {
    setOpen(!open);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const handleFieldChange = async (e: any) => {
    dispatch.Users.search(e.target.value);
  };

  const handleSelectMember = async (value: number) => {
    const usersArray: number[] = [];
    usersArray.push(value);

    if (squad) {
      squad.users.forEach(u => {
        usersArray.push(u.id);
      });

      await dispatch.Squads.update({ id: squad.id, users: usersArray });

      handleGetSquad();
    }
    handleOpenAndClose();
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        <IconButton
          className={classes.buttonAddMember}
          onClick={handleOpenAndClose}
        >
          <PersonAddIcon color="disabled" className={classes.addMember} />
        </IconButton>
        <Card className={open ? classes.openCard : classes.closeCard}>
          <CardContent className={classes.cardContent}>
            <TextInputMUI
              onChange={handleFieldChange}
              size="small"
              placeholder="Relator"
              variant="outlined"
              className={classes.field}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon className={classes.searchIcon} />
                  </InputAdornment>
                ),
              }}
            />
            {rows.map((member: any) => (
              <div key={member.id} className={classes.contentMember}>
                <Button
                  disableRipple
                  size="small"
                  className={classes.buttonMember}
                  onClick={() => handleSelectMember(member.id)}
                >
                  <Typography className={classes.typography}>
                    {member.name}
                  </Typography>
                  <AccountCircleIcon className={classes.userIcon} />
                </Button>
              </div>
            ))}
          </CardContent>
        </Card>
      </div>
    </ClickAwayListener>
  );
};

export default AddMember;
