import React, { useEffect, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import EditIcon from '@material-ui/icons/Edit';
import RefreshIcon from '@material-ui/icons/Refresh';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { useSelector } from 'react-redux';

import { KeyboardDatePicker } from '@material-ui/pickers';

import Table, {
  RowData,
  RowAction,
  ColumnData,
  ToolbarAction,
} from '../../components/Table';
import Dialog from '../../components/Dialog';
import SnackAlert from '../../components/SnackAlert';
import BreadCrumbBar from '../../components/BreadCrumbBar';

import { useSnackAlert } from '../../hooks/useSnackAlert';

import { dispatch, RootState } from '../../config/store';

import { useStyles } from './styles';

const optionsProjectStatus = [
  { id: 1, name: 'cancelled', status: 'Cancelado' },
  { id: 2, name: 'in_progress', status: 'Em progresso' },
  { id: 3, name: 'finished', status: 'Finalizado' },
  { id: 4, name: 'paused', status: 'Pausado' },
];

const columns: ColumnData[] = [
  {
    id: 'name',
    label: 'Projetos',
    sortable: true,
  },
  {
    id: 'status',
    label: 'Status',
    sortable: true,
  },
  {
    id: 'userResponsable',
    label: 'P.O',
    sortable: true,
  },
  {
    id: 'start_date',
    label: 'Data Início',
    sortable: true,
  },
  {
    id: 'finish_date',
    label: 'Data Fim',
    sortable: true,
  },
];

const breadCrumbData = [
  { label: 'Clientes', path: '/clients/' },
  { label: 'Projetos', path: '' },
];

const ClientProjects: React.FC = () => {
  const history = useHistory();
  const classes = useStyles();

  const { clientId } = useParams<{ clientId: string }>();

  const [projectId, setProjectId] = useState<number>();
  const [projectName, setProjectName] = useState('');
  const [projectStatus, setProjectStatus] = useState('');
  const [projectEndDate, setProjectEndDate] = useState(new Date());
  const [projectStartDate, setProjectStartDate] = useState(new Date());

  const [projectUserResponsableId, setProjectUserResponsableId] =
    useState<number>();
  const [projectUserResponsableName, setProjectUserResponsableName] =
    useState('');

  const [status, setStatus] = useState('');
  const [statusId, setStatusId] = useState<number>();

  const [errorName, setErrorName] = useState(false);

  const [modalEditActive, setModalEditActive] = useState(false);
  const [modalCreateActive, setModalCreateActive] = useState(false);

  const [snackActive, setSnackActive] = useState(false);

  const [rows, setRows] = useState<RowData[]>([]);

  const [saved, setSaved] = useState(false);

  const users = useSelector((state: RootState) => state.Users.users);
  const projects = useSelector((state: RootState) => state.Projects);

  const handleUsersUpdate = () => dispatch.Users.active();
  const handleProjectsUpdate = () => dispatch.Projects.readAll();

  useEffect(() => {
    handleProjectsUpdate();
    handleUsersUpdate();
  }, []);

  const formatDate = (date: string) => {
    const d = new Date(date);
    return d.toLocaleDateString();
  };

  useEffect(() => {
    handleData();
  }, []);

  useEffect(() => {
    if (projects !== null) {
      setRows(
        projects
          .filter(project => project.client_id === Number(clientId))
          .map(project => ({
            ...project,
            id: Number(project.id),
            start_date: formatDate(project.start_date),
            finish_date: formatDate(project.finish_date),
            userResponsable: users?.find(
              u => u.id === project.user_responsible_id,
            )?.name,
            status: optionsProjectStatus.find(s => s.name === project.status)
              ?.status,
          })),
      );
    }
  }, [projects, clientId, users]);

  const handleProjectDetails = (id: number) => {
    history.push(`/clients/${clientId}/project/${id}/details`);
  };

  const handleData = async () => {
    await dispatch.Users.readAll();
  };

  const handleResetStates = () => {
    setStatus('');
    setStatusId(undefined);

    setProjectName('');
    setProjectStatus('');
    setProjectId(undefined);
    setProjectEndDate(new Date());
    setProjectStartDate(new Date());
    setProjectUserResponsableName('');
    setProjectUserResponsableId(undefined);

    setErrorName(false);
  };

  const handleCreateDialog = () => {
    setModalCreateActive(!modalCreateActive);
    handleResetStates();
  };

  const handleEditDialog = () => {
    setModalEditActive(!modalEditActive);
    handleResetStates();
  };

  const toggleSnack = () => {
    setSnackActive(!snackActive);
  };

  const handleEditProject = async (id: number) => {
    setModalEditActive(!modalEditActive);

    const project = await dispatch.Projects.read(id);

    setProjectId(project.id);
    setProjectName(project.name);
    setProjectStatus(project.status);
    setProjectEndDate(() => new Date(project.finish_date));
    setProjectStartDate(() => new Date(project.start_date));
    setProjectUserResponsableId(project.user_responsible_id);

    const currentResponsable = users?.find(
      u => u.id === project.user_responsible_id,
    );

    if (currentResponsable) {
      setProjectUserResponsableName(currentResponsable.name);
    }

    const currentStatus = optionsProjectStatus.find(
      s => s.name === project.status,
    );

    if (currentStatus) {
      setStatus(currentStatus.status);
      setStatusId(currentStatus.id);
    }
  };

  const handleUpdateProject = async (event: React.FormEvent) => {
    event.preventDefault();

    if (
      projectName &&
      projectStartDate &&
      setProjectEndDate &&
      projectStatus &&
      projectId
    ) {
      setModalEditActive(!modalEditActive);

      await dispatch.Projects.edit({
        id: projectId,
        name: projectName,
        status: projectStatus,
        start_date: projectStartDate.toISOString(),
        finish_date: projectEndDate.toISOString(),
      });

      await dispatch.Projects.readAll();

      handleResetStates();
    } else {
      if (!projectName) {
        setErrorName(true);
      }
      toggleSnack();
    }
  };

  const handleCreateProject = async (event: React.FormEvent) => {
    setSaved(true);

    event.preventDefault();

    if (projectName && projectStartDate && setProjectEndDate && projectStatus) {
      setModalCreateActive(!modalCreateActive);

      await dispatch.Projects.create({
        name: projectName,
        status: projectStatus,
        client_id: Number(clientId),
        user_responsible_id: projectUserResponsableId,
        start_date: projectStartDate.toISOString(),
        finish_date: projectEndDate.toISOString(),
      });

      await dispatch.Projects.readAll();

      handleResetStates();
    } else {
      if (!projectName) {
        setErrorName(true);
      }
      toggleSnack();
    }
  };

  const toolbarActions: ToolbarAction[] = [
    {
      callback: handleProjectsUpdate,
      icon: <RefreshIcon />,
    },
  ];

  const actionIcons: RowAction[] = [
    {
      icon: <VisibilityIcon />,
      callback: handleProjectDetails,
    },
    {
      icon: <EditIcon />,
      callback: handleEditProject,
    },
  ];

  const contentEdition = (
    <form className={classes.formsNewProject}>
      <Box>
        <TextField
          id="project-name"
          label="Nome"
          variant="outlined"
          fullWidth
          size="small"
          required
          value={projectName}
          onChange={name => setProjectName(name.target.value)}
          error={errorName}
          helperText={
            errorName ? 'É obrigatório preencher o nome do projeto.' : ''
          }
        />
        <Box className={classes.formsDateContainer}>
          <KeyboardDatePicker
            autoOk
            id="start-date"
            label="Data Início"
            variant="inline"
            inputVariant="outlined"
            format="dd/MM/yyyy"
            size="small"
            required
            style={{ marginRight: '1rem' }}
            InputAdornmentProps={{ position: 'end' }}
            value={projectStartDate}
            onChange={(date: React.SetStateAction<Date> | null) => {
              if (date != null) {
                setProjectStartDate(date);
              }
            }}
          />
          <KeyboardDatePicker
            autoOk
            id="end-date"
            label="Data Final"
            variant="inline"
            inputVariant="outlined"
            format="dd/MM/yyyy"
            size="small"
            required
            InputAdornmentProps={{ position: 'end' }}
            value={projectEndDate}
            onChange={(date: React.SetStateAction<Date> | null) => {
              if (date != null) {
                setProjectEndDate(date);
              }
            }}
          />
        </Box>
        <Box className={classes.footerContainer}>
          <Autocomplete
            id="status-box"
            options={optionsProjectStatus}
            getOptionLabel={s => s.status}
            size="small"
            value={{
              id: statusId,
              name: projectStatus,
              status,
            }}
            fullWidth
            style={{ marginRight: '1rem' }}
            renderInput={params => (
              <TextField {...params} label="Status" variant="outlined" />
            )}
            onChange={(event, newValue) => {
              if (newValue != null) {
                setProjectStatus(newValue.name);
                setStatus(newValue.status);
              }
            }}
          />
          <Autocomplete
            id="user-responsable"
            value={projectUserResponsableName}
            noOptionsText="No Responsable User"
            onChange={(_, value) => {
              if (value) {
                const res = users?.find(r => r.name === value);
                setProjectUserResponsableId(res?.id ?? 0);
                setProjectUserResponsableName(res?.name ?? '');
              }
            }}
            options={(users ?? []).map(option => option.name)}
            size="small"
            fullWidth
            renderInput={options => (
              <TextField
                {...options}
                label="Responsável"
                variant="outlined"
                key={options.id}
                value={options.id}
              />
            )}
          />
        </Box>
      </Box>
      <Box className={classes.formButtonsContainer}>
        <Button
          variant="contained"
          color="inherit"
          className={classes.buttonCancel}
          style={{ marginRight: 8 }}
          onClick={handleEditDialog}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          onClick={handleUpdateProject}
          className={classes.buttonCreate}
        >
          Salvar
        </Button>
      </Box>
    </form>
  );

  const contentCreation = (
    <form className={classes.formsNewProject}>
      <Box>
        <TextField
          id="project-name"
          label="Nome"
          variant="outlined"
          fullWidth
          size="small"
          required
          onChange={name => setProjectName(name.target.value)}
          error={errorName}
          helperText={
            errorName ? 'É obrigatório preencher o nome do projeto.' : ''
          }
        />
        <Box className={classes.formsDateContainer}>
          <KeyboardDatePicker
            autoOk
            id="start-date"
            label="Data Início"
            variant="inline"
            inputVariant="outlined"
            format="dd/MM/yyyy"
            size="small"
            required
            style={{ marginRight: '1rem' }}
            InputAdornmentProps={{ position: 'end' }}
            value={projectStartDate}
            onChange={(date: React.SetStateAction<Date> | null) => {
              if (date != null) {
                setProjectStartDate(date);
              }
            }}
          />
          <KeyboardDatePicker
            autoOk
            id="end-date"
            label="Data Final"
            variant="inline"
            inputVariant="outlined"
            format="dd/MM/yyyy"
            size="small"
            required
            InputAdornmentProps={{ position: 'end' }}
            value={projectEndDate}
            onChange={(date: React.SetStateAction<Date> | null) => {
              if (date != null) {
                setProjectEndDate(date);
              }
            }}
          />
        </Box>
        <Box className={classes.footerContainer}>
          <Autocomplete
            id="status-box"
            options={optionsProjectStatus}
            getOptionLabel={s => s.status}
            size="small"
            fullWidth
            style={{ marginRight: '1rem' }}
            renderInput={params => (
              <TextField
                {...params}
                label="Status"
                variant="outlined"
                error={projectStatus === '' && saved}
                helperText={
                  projectStatus === '' && saved
                    ? 'É obrigatório preencher o status.'
                    : ''
                }
              />
            )}
            onChange={(event, newValue) => {
              if (newValue != null) {
                setProjectStatus(newValue.name);
              }
            }}
          />
          <Autocomplete
            id="user-responsable"
            options={users!}
            getOptionLabel={user => user.name!}
            size="small"
            fullWidth
            renderInput={params => (
              <TextField
                {...params}
                label="Responsável"
                variant="outlined"
                error={!projectUserResponsableId && saved}
                helperText={
                  !projectUserResponsableId && saved
                    ? 'É obrigatório escolher um responsável.'
                    : ''
                }
              />
            )}
            onChange={(event, newValue) => {
              if (newValue != null) {
                setProjectUserResponsableId(newValue.id);
              }
            }}
          />
        </Box>
      </Box>
      <Box className={classes.formButtonsContainer}>
        <Button
          variant="contained"
          color="inherit"
          className={classes.buttonCancel}
          style={{ marginRight: 8 }}
          onClick={handleCreateDialog}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          onClick={handleCreateProject}
          className={classes.buttonCreate}
        >
          Salvar
        </Button>
      </Box>
    </form>
  );

  const { message, severity } = useSnackAlert({
    message: 'Todos os campos devem ser preenchidos corretamente!',
    severity: 'error',
  });

  return (
    <>
      <div className={classes.breadCrumbContainer}>
        <BreadCrumbBar data={breadCrumbData} />
      </div>

      <Container>
        <Button
          onClick={handleCreateDialog}
          className={classes.buttonCreate}
          variant="contained"
          color="primary"
        >
          Novo projeto
        </Button>

        <SnackAlert
          open={snackActive}
          handleClose={toggleSnack}
          message={message}
          severity={severity}
        />

        <Dialog
          title="Criar projeto"
          content={contentCreation}
          open={modalCreateActive}
          handleClose={handleCreateDialog}
          confirm={handleCreateProject}
          showActions={false}
        />

        <Dialog
          title="Editar projeto"
          content={contentEdition}
          open={modalEditActive}
          handleClose={handleEditDialog}
          confirm={handleUpdateProject}
          showActions={false}
        />

        <Table
          actions={actionIcons}
          columns={columns}
          rows={rows}
          showToolbar
          toolbarActions={toolbarActions}
        />
      </Container>
    </>
  );
};

export default ClientProjects;
