import { useState } from 'react';
import { SnackAlertProps } from '../components/SnackAlert';

type SnackAlertOptions = Omit<SnackAlertProps, 'open' | 'handleClose'>;

export const useSnackAlert = (options: SnackAlertOptions) => {
  const [open, setOpen] = useState(false);
  const toggleSnack = () => setOpen(!open);
  const [message, setMessage] = useState(options.message);
  const [duration, setDuration] = useState(options.duration);
  const [severity, setSeverity] = useState(options.severity);
  const [position, setPosition] = useState(options.position);

  return {
    open,
    toggleSnack,
    message,
    setMessage,
    duration,
    setDuration,
    severity,
    setSeverity,
    position,
    setPosition,
  };
};
