import { Link } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import { useStyles } from './styles';

interface DataBreadCrumb {
  label: string;
  path?: string;
}
interface BreadCrumbProps {
  data: DataBreadCrumb[];
}

const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

const BreadCrumbBar = ({ data }: BreadCrumbProps) => {
  const classes = useStyles();

  return (
    <div>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="large" />}
      >
        {data?.map(item => {
          const current = data.indexOf(item) === data.length - 1;

          return current ? (
            <Typography color="primary" variant="h6" key={item.label}>
              {capitalize(item.label)}
            </Typography>
          ) : (
            <Link
              className={classes.linkText}
              to={item.path ? item.path : ''}
              key={item.label}
            >
              <Typography color="inherit" variant="h6">
                {capitalize(item.label)}
              </Typography>
            </Link>
          );
        })}
      </Breadcrumbs>
    </div>
  );
};

export default BreadCrumbBar;
