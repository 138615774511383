import {
  SprintReadRes,
  SprintReadRequest,
  SprintDashboardSprintRes,
  SprintDashboardSprintRequest,
  SprintFinishSprintRequest,
  SprintFinishSprintRes,
} from '../../interfaces/Sprints';
import * as requests from '../requests';

export const read = requests.read<SprintReadRequest, SprintReadRes>('sprints');

export const finishSprint = requests.generic<
  SprintFinishSprintRes,
  SprintFinishSprintRequest
>(requests.Method.PATCH, 'sprints', 'finishSprint');

// export const dashboard = requests.read<
//   SprintDashboardSprintRequest,
//   SprintDashboardSprintRes
// >('sprints');

export const dashboard = requests.generic<
  SprintDashboardSprintRes,
  SprintDashboardSprintRequest
>(requests.Method.GET, 'sprints', 'dashboardSprint');
