import React, { FormEvent, KeyboardEvent, useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';

import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import RefreshIcon from '@material-ui/icons/Refresh';
import VisibilityIcon from '@material-ui/icons/Visibility';

import Table, {
  ColumnData,
  RowData,
  ToolbarAction,
  RowAction,
} from '../../components/Table';
import Dialog from '../../components/Dialog';
import SnackAlert from '../../components/SnackAlert';
import BreadCrumbBar from '../../components/BreadCrumbBar';

import { dispatch, RootState } from '../../config/store';

import { useSnackAlert } from '../../hooks/useSnackAlert';

import { useStyles } from './styles';

const columns: ColumnData[] = [
  {
    id: 'name',
    label: 'Nome',
    sortable: false,
  },
];

const Clients: React.FC = () => {
  const [userName, setUserName] = useState('');

  const [modalActive, setModalActive] = useState(false);
  const [snackActive, setSnackActive] = useState(false);

  const [modalType, setModalType] = useState('');
  const [clientName, setClientName] = useState('');
  const [clientId, setClientId] = useState<number>(0);
  const [oldName, setOldName] = useState<string | undefined>('');
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(5);

  const [rows, setRows] = useState<RowData[]>([]);
  const [count, setCount] = useState<number>(1);

  const [error, setError] = useState(false);

  const clients = useSelector(
    (state: RootState) => state.Clients.clientsWithPagination,
  );
  const client = useSelector((state: RootState) => state.Clients.client);

  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    setRows([]);
    dispatch.Clients.readAllWithPagination({ page, limit });
  }, [page, limit]);

  useEffect(() => {
    if (clients && clients.data.length > 0) {
      setRows(clients.data);
      setCount(clients.meta.total);
    }
  }, [clients]);

  useEffect(() => {
    if (client && Object.keys(client).length > 0) {
      setRows([client]);
    }
  }, [client]);

  const handleClientProjects = (id: number) => {
    history.push(`/clients/${id}/projects`);
  };

  const handleDialog = () => {
    setError(false);
    setModalActive(!modalActive);
  };

  const handleModalCreate = () => {
    setModalType('create');
    handleDialog();
  };

  const handleModalEdit = (id: number) => {
    const clientSelect = clients?.data.find(c => c.id === id);
    setOldName(clientSelect?.name);

    setModalType('edit');
    setClientId(id);
    handleDialog();
  };

  const toggleSnack = () => {
    setSnackActive(!snackActive);
  };

  const handleEditClient = async (e: FormEvent) => {
    e.preventDefault();
    try {
      if (clientName && clientId) {
        await dispatch.Clients.update({ id: clientId, name: clientName });
        await dispatch.Clients.readAllWithPagination({ page, limit });
        setClientName('');
        setModalType('');
      }

      setModalActive(!modalActive);
    } catch (err) {
      if (err) {
        setError(true);
      }
      setModalActive(modalActive);
    }
  };

  const handleCreateClient = async (e: FormEvent) => {
    e.preventDefault();
    try {
      if (clientName) {
        await dispatch.Clients.create({ name: clientName });
        await dispatch.Clients.readAllWithPagination({ page, limit });
        setClientName('');
        setModalType('');
      }

      setModalActive(!modalActive);
    } catch (err) {
      if (err) {
        setError(true);
      }
      setModalActive(modalActive);
    }
  };

  const handleFieldChange = async (e: any) => {
    setUserName(e.target.value);
  };

  const handleSearchKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter' && userName !== '') {
      setRows([]);
      setUserName('');
      dispatch.Clients.readAllWithPagination({
        page,
        limit,
        client_name: userName,
      });
    }
  };

  const handleClientSearch = () => {
    setRows([]);
    setUserName('');
    dispatch.Clients.readAllWithPagination({
      page,
      limit,
      client_name: userName,
    });
  };

  const onPageChange = (_: unknown, value: number) => {
    if (value + 1) setPage(value + 1);
  };

  const onPaginationSizeChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(1);
  };

  const toolbarActions: ToolbarAction[] = [
    {
      icon: <RefreshIcon />,
      callback: () => dispatch.Clients.readAllWithPagination({ page, limit }),
    },
  ];

  const actionIcons: RowAction[] = [
    {
      icon: <VisibilityIcon />,
      callback: handleClientProjects,
    },
    {
      icon: <EditIcon />,
      callback: handleModalEdit,
    },
  ];

  const content = (
    <form
      onSubmit={
        modalType === 'create'
          ? handleCreateClient
          : modalType === 'edit'
          ? handleEditClient
          : undefined
      }
    >
      <Box className={classes.containerInput}>
        <TextField
          id="outlined-basic"
          defaultValue={modalType === 'edit' ? oldName : ''}
          label="Nome"
          variant="outlined"
          style={{ width: 400 }}
          size="small"
          required
          error={error}
          onChange={e => {
            setClientName(e.target.value);
            setError(false);
          }}
        />
        {error && <span className={classes.span}>Cliente já existe</span>}
      </Box>
      <Box
        className={classes.BoxButton}
        style={{ padding: '3.5rem 0rem 1rem 1rem' }}
      >
        <Button
          variant="contained"
          className={classes.cancelButton}
          style={{ marginRight: '1rem' }}
          color="inherit"
          onClick={handleDialog}
          classes={{
            root: classes.removeUppercase,
          }}
        >
          Cancelar
        </Button>
        <Button
          className={classes.saveButton}
          variant="contained"
          type="submit"
          color="primary"
          classes={{
            root: classes.removeUppercase,
          }}
        >
          Salvar
        </Button>
      </Box>
    </form>
  );

  const { message, severity } = useSnackAlert({
    message: 'O nome deve ser preenchido',
    severity: 'error',
  });

  return (
    <>
      <div className={classes.breadCrumbContainer}>
        <BreadCrumbBar data={[{ label: 'Clientes', path: '' }]} />
      </div>
      <Container>
        <Box className={classes.searchContainer}>
          <Paper className={classes.paperInput}>
            <InputBase
              onChange={handleFieldChange}
              id="outlined-basic"
              placeholder="Search Clients"
              onKeyDown={handleSearchKeyDown}
            />
            <IconButton
              onClick={handleClientSearch}
              onKeyDown={handleSearchKeyDown}
              aria-label="search"
              className={classes.buttonSearch}
            >
              <SearchIcon className={classes.searchIcon} />
            </IconButton>
          </Paper>

          <Button
            onClick={handleModalCreate}
            className={classes.buttonCreate}
            variant="contained"
            color="primary"
          >
            Novo cliente
          </Button>
        </Box>

        <SnackAlert
          open={snackActive}
          handleClose={toggleSnack}
          message={message}
          severity={severity}
        />

        <Dialog
          title={
            modalType === 'create' ? 'Cadastro de cliente' : 'Editar Cliente'
          }
          content={content}
          open={modalActive}
          handleClose={handleDialog}
          showActions={false}
        />

        <Table
          actions={actionIcons}
          columns={columns}
          rows={rows}
          showToolbar
          toolbarActions={toolbarActions}
          onPageChange={onPageChange}
          onPaginationSizeChange={onPaginationSizeChange}
          pageNumber={page}
          pageSize={limit}
          count={count}
        />
      </Container>
    </>
  );
};

export default Clients;
