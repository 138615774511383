import React, { useState } from 'react';

import { Draggable } from 'react-beautiful-dnd';

import Box from '@material-ui/core/Box';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ScheduleIcon from '@material-ui/icons/Schedule';

import Avatar from '@material-ui/core/Avatar';

import { useSelector } from 'react-redux';

import Dialog from '../../../../../components/Dialog';
import TaskContent from '../../../../../components/TaskContent';

import { useStyles } from './styles';

import { dispatch, RootState } from '../../../../../config/store';
import { SprintReadRes } from '../../../../../config/interfaces/Sprints';

interface TasksProps {
  item: SprintReadRes['sprint']['columns'][0]['tasks'][0];
  index: number;
  columnId: number;
  fetchColumns: () => Promise<void>;
}

const BoardTasks: React.FC<TasksProps> = ({
  item,
  index,
  columnId,
  fetchColumns,
}: TasksProps) => {
  const classes = useStyles();

  const [taskMenuAnchor, setTaskMenuAnchor] = useState<null | HTMLElement>(
    null,
  );

  const openTaskMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setTaskMenuAnchor(event.currentTarget);
  };

  const users = useSelector((state: RootState) => state.Users.users);

  const [modalEditTaskActive, setModalEditTaskActive] = useState(false);

  const handleOpenModalEditTask = async () => {
    await dispatch.Requirements.readAllWithPagination({
      project_id: item.project_id!,
      page: 1,
      limit: 100,
    });
    if (modalEditTaskActive) {
      fetchColumns();
    }

    setModalEditTaskActive(!modalEditTaskActive);
  };

  const closeTaskMenu = () => {
    setTaskMenuAnchor(null);
  };

  const [modalActive, setModalActive] = useState(false);

  const handleDialog = () => {
    setModalActive(!modalActive);
  };

  const handleTimeMask = (time: number) => {
    const hour = time.toString().slice(0, 2);
    return `${hour}:00`;
  };

  const handleUserInitialMask = (userID: number) => {
    const userName = users.find(user => user.id === userID)?.name ?? '';

    const initials = userName
      .split(' ')
      .reduce((acc, cur) => acc + cur[0], '')
      .toUpperCase();
    return `${initials}`;
  };

  const handleTaskDelete = (id: number) => {
    dispatch.Tasks.remove({ id }).then(() => {
      fetchColumns();
    });
  };

  return (
    <>
      <Dialog
        title=""
        content={
          <TaskContent
            issueId={+item.id}
            closeModal={handleOpenModalEditTask}
            squadId={item.squad_id}
            columnId={columnId}
            sprintId
          />
        }
        open={modalEditTaskActive}
        handleClose={handleOpenModalEditTask}
        classDialogTitle={classes.modalTitle}
        showActions={false}
        fullWidth
        maxWidth="md"
      />
      <Draggable
        draggableId={(item.id ?? 1).toString()}
        key={item.id}
        index={index}
      >
        {provided => (
          <div
            className={classes.task}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            style={{ ...provided.draggableProps.style }}
          >
            <div className={classes.cardHeader}>
              <span className={classes.taskName}>{item.name}</span>
              <IconButton
                aria-controls="task-actions"
                aria-haspopup="true"
                onClick={e => {
                  openTaskMenu(e);
                }}
                aria-label="about"
              >
                <MoreVertIcon fontSize="small" />
              </IconButton>
              <Menu
                id="task-actions"
                anchorEl={taskMenuAnchor}
                keepMounted
                open={Boolean(taskMenuAnchor)}
                onClose={closeTaskMenu}
              >
                <span className={classes.taskMenuTitle}>Ações</span>
                <MenuItem
                  className={classes.taskMenuItem}
                  onClick={closeTaskMenu}
                >
                  Copiar link
                </MenuItem>
                <MenuItem
                  className={classes.taskMenuItemApagar}
                  onClick={() => {
                    closeTaskMenu();
                    handleDialog();
                    handleTaskDelete(item.id);
                  }}
                >
                  Apagar
                </MenuItem>
              </Menu>
            </div>
            <Box onClick={handleOpenModalEditTask}>
              <div className={classes.project}>
                <span className={classes.projectName}>{item.project.name}</span>
              </div>
              <div className={classes.cardFooter}>
                <div className={classes.issue}>
                  <CheckBoxIcon color="primary" fontSize="small" />
                  <span className={classes.id}>
                    IS - {item.sprint_id ? item.sprint_id : 'N/A'}
                  </span>
                </div>
                <div className={classes.info}>
                  <div className={classes.estimate}>
                    <ScheduleIcon fontSize="small" />
                    <span className={classes.time}>
                      {handleTimeMask(item.time_estimated!)}
                    </span>
                  </div>
                  <Avatar className={classes.avatar}>
                    {handleUserInitialMask(item.user_assignee_id)}
                  </Avatar>
                </div>
              </div>
            </Box>
          </div>
        )}
      </Draggable>
    </>
  );
};

export default BoardTasks;
