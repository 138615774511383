import React, { useState } from 'react';

import Fab from '@material-ui/core/Fab';
import Menu from '@material-ui/core/Menu';
import Fade from '@material-ui/core/Fade';
import AddIcon from '@material-ui/icons/Add';
import MenuItem from '@material-ui/core/MenuItem';

import Dialog from '../../../../components/Dialog';
import RequirementContent from '../RequirementContent';
import TaskContent from '../../../../components/TaskContent';

import { useStyles } from './styles';

interface TaskMenuProps {
  projectId: number;
  requirementId: number | undefined;
  handleData: (requirement: number) => void;
}

const NewTaskMenu: React.FC<TaskMenuProps> = ({
  projectId,
  handleData,
  requirementId,
}) => {
  const classes = useStyles();

  const [modal, setModal] = useState(false);
  const [content, setContent] = useState('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const modalType: string = 'create';

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleSetContent = (typeContent: string) => {
    setContent(typeContent);
  };

  const handleSetModal = async () => {
    setModal(!modal);
    handleData(requirementId!);
  };

  const options = [
    { name: 'Novo Requisito', content: 'requirement' },
    { name: 'Nova Tarefa', content: 'task' },
  ];

  return (
    <div>
      <Dialog
        title=""
        content={
          content === 'task' ? (
            <TaskContent
              closeModal={handleSetModal}
              projectId={projectId}
              {...(requirementId && { requirementId })}
            />
          ) : content === 'requirement' ? (
            <RequirementContent
              toggleModal={handleSetModal}
              projectId={projectId}
            />
          ) : undefined
        }
        fullWidth
        maxWidth="md"
        open={modal}
        handleClose={handleSetModal}
        classDialogContent={classes.modalContent}
        classDialogTitle={classes.modalTitle}
        showActions={false}
      />
      <Fab
        size="large"
        aria-label="add"
        className={classes.fabComponent}
        onClick={handleClick}
      >
        <AddIcon fontSize="large" />
      </Fab>
      <Menu
        keepMounted
        open={open}
        id="long-menu"
        anchorEl={anchorEl}
        classes={{
          list: classes.menuList,
        }}
        onClose={handleClose}
        onClick={handleClose}
        TransitionComponent={Fade}
        PaperProps={{
          classes: {
            elevation8: classes.list,
          },
          style: {
            width: '25ch',
            backgroundColor: 'transparent',
            paddingBottom: '1rem',
          },
        }}
      >
        {options.map(option => (
          <MenuItem
            key={option.name}
            onClick={handleClose}
            classes={{
              gutters: classes.list,
              root: classes.list,
            }}
          >
            <div
              role="button"
              aria-hidden="true"
              className={classes.options}
              onClick={() => {
                handleSetContent(option.content);
                handleSetModal();
              }}
            >
              {option.name}
            </div>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
export default NewTaskMenu;
