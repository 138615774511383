import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { RootState } from '../../config/store';

export interface PropsClientsIcon {}

const ClientsIcon = () => {
  const route = useSelector((state: RootState) => state.Drawer.path);

  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (route && route === '/clients') {
      setIsActive(true);
    }
  }, [route]);

  return (
    <svg
      width="19"
      height="24"
      viewBox="0 0 19 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.39017 5.0625C5.39017 3.86903 5.86428 2.72443 6.70819 1.88052C7.55211 1.03661 8.6967 0.5625 9.89017 0.5625C11.0836 0.5625 12.2282 1.03661 13.0722 1.88052C13.9161 2.72443 14.3902 3.86903 14.3902 5.0625C14.3902 6.25597 13.9161 7.40057 13.0722 8.24448C12.2282 9.08839 11.0836 9.5625 9.89017 9.5625C8.6967 9.5625 7.55211 9.08839 6.70819 8.24448C5.86428 7.40057 5.39017 6.25597 5.39017 5.0625ZM15.8917 11.0625H15.0622L10.3957 20.5365L12.1402 11.8125L9.89017 9.5625L7.64017 11.8125L9.38467 20.5365L4.71817 11.0625H3.88717C0.888672 11.0625 0.888672 13.0785 0.888672 15.5625V23.0625H18.8887V15.5625C18.8887 13.0785 18.8887 11.0625 15.8902 11.0625H15.8917Z"
        fill={isActive === false ? '#6c6c6c' : '#263579'}
      />
    </svg>
  );
};
export default ClientsIcon;
