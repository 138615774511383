import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  breadCrumbContainer: {
    borderBottom: '1px solid #e0e0e0',
    padding: '0rem 3rem 0.5rem 3rem',
  },

  searchContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: '1rem 0',
  },

  divider: {
    marginBottom: theme.spacing(2),
  },

  paperInput: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '20px',
    padding: '0px 3px 0px 15px',
    background: '#F8F8F8',
    border: '1px solid #E0E0E0',
    boxShadow: 'none',
    '&:hover': {
      cursor: 'pointer',
      border: '1px solid #263579',
    },
  },

  buttonSearch: {
    width: '35px',
    height: '35px',
    padding: '0',
    margin: '0',
  },

  searchIcon: {
    margin: '0',
  },

  buttonCreate: {
    margin: theme.spacing(3, 0),
  },

  removeUppercase: {
    textTransform: 'none',
  },

  containerInput: {
    height: '30px',
    display: 'flex',
    flexDirection: 'column',
  },

  span: {
    margin: '10px 0 0 8px',
    color: 'red',
    fontWeight: 300,
  },

  BoxButton: {
    width: '97%',
    display: 'flex',
    alignItems: 'middle',
    justifyContent: 'flex-end',
  },

  cancelButton: {
    border: 'none',
    fontSize: '16px',
    color: '#373737',
    boxShadow: 'none',
    lineHeigth: '24px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontFamily: 'Quicksand',
    letterSpacing: '-0.08px',
    backgroundColor: 'white',
  },

  saveButton: {
    color: '#FFFFFF',
    fontSize: '16px',
    lineHeigth: '24px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontFamily: 'Quicksand',
    letterSpacing: '-0.08px',
    backgroundColor: '#263579',
  },
}));
