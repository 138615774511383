import { Method } from '../requests';
import * as requests from '../requests';

import {
  SquadCreateRes,
  SquadCreateRequest,
  SquadReadHistoricRes,
  SquadReadHistoricRequest,
  SquadReadRes,
  SquadReadRequest,
  SquadReadAllRes,
  SquadReadAllWithPaginationRes,
  SquadReadAllWithPaginationRequest,
  SquadUpdateRes,
  SquadUpdateRequest,
  SquadGetUsersHoursRes,
  SquadGetUsersHoursRequest,
} from '../../interfaces/Squads';

export const create = requests.create<SquadCreateRequest, SquadCreateRes>(
  'squads',
);

export const readHistoric = requests.generic<
  SquadReadHistoricRes,
  SquadReadHistoricRequest
>(Method.POST, 'squads', 'readHistoric');

export const read = requests.read<SquadReadRequest, SquadReadRes>('squads');

export const readAll = requests.readAll<SquadReadAllRes>('squads');

export const readAllWithPagination = requests.readAllWithPagination<
  SquadReadAllWithPaginationRes,
  SquadReadAllWithPaginationRequest
>('squads');

export const update = requests.update<SquadUpdateRequest, SquadUpdateRes>(
  'squads',
);

export const getUsersHours = requests.genericWithParams<
  SquadGetUsersHoursRes,
  SquadGetUsersHoursRequest
>(Method.GET, 'squads', 'getUsersHours');
