import * as requests from '../requests';
import {
  ClientsCreateRes,
  ClientsCreateRequest,
  ClientsReadRes,
  ClientsReadRequest,
  ClientsReadAllRes,
  ClientsReadAllWithPaginationRes,
  ClientsReadAllWithPaginationRequest,
  ClientsUpdateRes,
  ClientsUpdateRequest,
} from '../../interfaces/Clients';

export interface CreateOptions {
  name: string;
}

export interface UpdateOptions extends Partial<CreateOptions> {
  id: number;
  name: string;
}

export interface SearchOptions {
  client_name: string;
}

export const create = requests.create<ClientsCreateRequest, ClientsCreateRes>(
  'clients',
);
export const read = requests.read<ClientsReadRequest, ClientsReadRes>(
  'clients',
);

export const readAll = requests.readAll<ClientsReadAllRes>('clients');

export const readAllWithPagination = requests.readAllWithPagination<
  ClientsReadAllWithPaginationRes,
  ClientsReadAllWithPaginationRequest
>('clients');

export const update = requests.update<ClientsUpdateRequest, ClientsUpdateRes>(
  'clients',
);
