import React from 'react';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';

import MoreVertIcon from '@material-ui/icons/MoreVert';

import { useStyles } from './styles';
import { dispatch } from '../../../../../config/store';

const options = ['Apagar'];

const ITEM_HEIGHT = 48;

interface ActionMenuProps {
  id: number;
  requirementId: number;
}

const ActionMenu: React.FC<ActionMenuProps> = ({
  id,
  requirementId,
}: ActionMenuProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e: React.MouseEvent<HTMLElement>) => {
    dispatch.Tasks.remove({ id });

    dispatch.Requirements.read({ id: requirementId });

    e.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        className={classes.menu}
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
            border: '1px solid #E2DFDF',
            boxSizing: 'border-box',
            boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.1)',
            borderRadius: '4px',
          },
        }}
      >
        <p className={classes.actionTitle}>Ações</p>
        {options.map(option => (
          <MenuItem
            key={option}
            onClick={handleClose}
            className={classes.atentionOption}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default ActionMenu;
