import React, { useEffect, useState } from 'react';

import ListItemText from '@material-ui/core/ListItemText';

import { CustomBox, useStyles } from './styles';
import { RequirementsReadAllRes } from '../../../../config/interfaces/Requirements';

interface RequirementSummaryProps {
  index: number;
  active: number;
  requirement: RequirementsReadAllRes;
  handleRequirementSelect: (id: number) => void;
}

const RequirementItem: React.FC<RequirementSummaryProps> = ({
  index,
  active,
  requirement,
  handleRequirementSelect,
}: RequirementSummaryProps) => {
  const [isActive, setIsActive] = useState(false);
  const [isSelect, setIsSelect] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    if (requirement && !isSelect && index === 0 && active === undefined) {
      handleRequirementSelect(requirement.id);
      setIsActive(true);
      setIsSelect(true);
    } else if (!isSelect || active !== undefined) {
      if (active === requirement.id) {
        setIsActive(true);
      } else {
        setIsActive(false);
      }
    }
  }, [active, handleRequirementSelect, requirement, index, isSelect]);

  return (
    <CustomBox
      active={isActive}
      onClick={() => {
        handleRequirementSelect(requirement.id);
      }}
    >
      <ListItemText
        className={classes.requirementNumber}
        disableTypography
        classes={{
          root: classes.textRoot,
        }}
        aria-hidden="true"
      >
        {requirement.id}
      </ListItemText>
      <ListItemText
        className={classes.requirementTitle}
        disableTypography
        classes={{
          root: classes.contentRoot,
        }}
      >
        {requirement.name}
      </ListItemText>
    </CustomBox>
  );
};

export default RequirementItem;
