import { createModel } from '@rematch/core';
import { RootModel } from '.';

import {
  TasksCreateRequest,
  TasksCreateRes,
  TasksSortPositionsRequest,
  TasksSortPositionsRes,
  TasksDeleteRequest,
  TasksDeleteRes,
  TasksReadRequest,
  TasksReadRes,
  TasksUpdateRequest,
  TasksUpdateRes,
  TasksUpdatePositionRequest,
  TasksUpdatePositionRes,
} from '../interfaces/Tasks';

import * as api from '../api/tasks';

interface TasksState {
  createdTask: TasksCreateRes | undefined;
  sortedTasks: TasksSortPositionsRes | undefined;
  task: TasksReadRes | undefined;
  updatedTask: TasksUpdateRes | undefined;
  updatedPositionTask: TasksUpdatePositionRes | undefined;
  removedTask: TasksDeleteRes | undefined;
}

export const Tasks = createModel<RootModel>()({
  state: {} as TasksState,

  reducers: {
    loadCreatedTask: (state: TasksState, payload: TasksCreateRes) => ({
      ...state,
      createdTask: payload,
    }),

    loadSortedTasks: (state: TasksState, payload: TasksSortPositionsRes) => ({
      ...state,
      sortedTasks: payload,
    }),

    loadTask: (state: TasksState, payload: TasksReadRes) => ({
      ...state,
      task: payload,
    }),

    loadUpdatedTask: (state: TasksState, payload: TasksUpdateRes) => ({
      ...state,
      updatedTask: payload,
    }),

    loadUpdatedPositionTask: (
      state: TasksState,
      payload: TasksUpdatePositionRes,
    ) => ({
      ...state,
      updatedPositionTask: payload,
    }),

    loadRemovedTask: (state: TasksState, payload: TasksDeleteRes) => ({
      ...state,
      removedTask: payload,
    }),
  },

  effects: dispatch => ({
    create: async (payload: TasksCreateRequest) => {
      const res = await api.create(payload);
      dispatch.Tasks.loadCreatedTask(res);
    },

    sortPositions: async (payload: TasksSortPositionsRequest) => {
      const res = await api.sortPositions(payload);
      dispatch.Tasks.loadSortedTasks(res);
    },

    read: async (payload: TasksReadRequest) => {
      const res = await api.read(payload);
      dispatch.Tasks.loadTask(res);
    },

    update: async (payload: TasksUpdateRequest) => {
      const res = await api.update(payload);
      dispatch.Tasks.loadUpdatedTask(res);
    },

    updatePosition: async (payload: TasksUpdatePositionRequest) => {
      const res = await api.updatePosition(payload);
      dispatch.Tasks.loadUpdatedPositionTask(res);
    },

    remove: async (payload: TasksDeleteRequest) => {
      const res = await api.remove(payload);
      dispatch.Tasks.loadRemovedTask(res);
    },
  }),
});
