import React, { useEffect, useState } from 'react';

import { Link, useRouteMatch, useHistory } from 'react-router-dom';

import { useSelector } from 'react-redux';

import clsx from 'clsx';

import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Popper from '@material-ui/core/Popper';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import EditIcon from '@material-ui/icons/Edit';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import PersonIcon from '@material-ui/icons/Person';
import Typography from '@material-ui/core/Typography';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';

import { useStyles } from './styles';
import { routes } from '../../routes';

import { dispatch, RootState } from '../../config/store';

import { ReactComponent as PDSLogo } from '../../assets/pds-logo-v2.svg';

const AppDrawer: React.FC<{ main: React.ComponentType }> = ({ main: Main }) => {
  const [user, setUser] = useState('');

  const route = useRouteMatch();
  const history = useHistory();

  const classes = useStyles();

  const routeState = useSelector((state: RootState) => state.Drawer.path);
  const showDrawer = useSelector(
    (state: RootState) => state.Settings.drawer ?? false,
  );
  const { toggleDrawer } = dispatch.Settings;

  const isSelected = ({
    path,
    exact = false,
  }: {
    path: string;
    exact?: boolean;
  }): boolean => route.path === path && (!exact || route.isExact);

  const handleLogout = () => {
    dispatch.Auth.logout();
  };

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  useEffect(() => {
    async function getPath() {
      if (routeState === undefined) {
        const locations = history.location.pathname;
        await dispatch.Drawer.loadRoutes({ path: locations });
      }
    }
    getPath();
  }, [history.location.pathname, routeState]);

  const loggedUserName = useSelector((state: RootState) => state.Auth.name);

  useEffect(() => {
    if (loggedUserName) {
      setUser(loggedUserName);
    }
  }, [loggedUserName]);

  const drawer = (
    <>
      <Box className={classes.toolbar} />
      <Divider />
      <List>
        <Box
          onClick={toggleDrawer}
          display="flex"
          className={clsx(classes.drawerButton, {
            [classes.closeDrawerButton]: showDrawer,
            [classes.openDrawerButton]: !showDrawer,
          })}
        >
          <i
            className={clsx({
              'fas fa-caret-square-left': showDrawer,
              'fas fa-caret-square-right': !showDrawer,
            })}
          />
        </Box>
        {routes
          .filter(({ title, restricted, icon }) => restricted && title && icon)
          .map(({ title, path, icon, exact }) => (
            <ListItem
              button
              selected={isSelected({ path, exact })}
              key={path}
              component={Link}
              to={path}
              onClick={() => {
                const data = {
                  path,
                };
                dispatch.Drawer.loadRoutes(data);
              }}
            >
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText
                style={{
                  color: isSelected({ path, exact }) ? '#263579' : '#6c6c6c',
                }}
                primary={title}
              />
            </ListItem>
          ))}
      </List>
    </>
  );

  return (
    <Box className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Box display="flex" flex="1" justifyContent="center">
            <Box mr="auto">
              <PDSLogo className={classes.logo} />
            </Box>
          </Box>

          <Box display="flex" flex="1" justifyContent="center">
            <Typography variant="h6" noWrap className={classes.title}>
              PD System
            </Typography>
          </Box>

          <Box display="flex" flex="1" justifyContent="center">
            <Box ml="auto">
              <div>
                <Button
                  ref={anchorRef}
                  aria-controls={open ? 'menu-list-grow' : undefined}
                  aria-haspopup="true"
                  onClick={handleToggle}
                  style={{ color: 'white', textTransform: 'none' }}
                >
                  <PersonIcon style={{ color: 'white' }} />
                  <Typography
                    style={{
                      fontSize: 14,
                      marginLeft: 4,
                    }}
                  >
                    Olá,
                    <strong> {user}</strong>
                  </Typography>
                </Button>
                <Popper
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  disablePortal
                >
                  {({ TransitionProps }) => (
                    <Grow {...TransitionProps}>
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList
                            autoFocusItem={open}
                            id="menu-list-grow"
                            onKeyDown={handleListKeyDown}
                          >
                            <MenuItem
                              onClick={handleClose}
                              style={{ fontSize: 14 }}
                            >
                              <AccountCircleIcon
                                style={{ color: 'gray', marginRight: 10 }}
                              />
                              Profile
                            </MenuItem>
                            <MenuItem
                              onClick={handleClose}
                              style={{ fontSize: 14 }}
                            >
                              <EditIcon
                                style={{ color: 'gray', marginRight: 10 }}
                              />
                              Edit profile
                            </MenuItem>
                            <Divider />
                            <MenuItem
                              onClick={handleLogout}
                              style={{ fontSize: 14 }}
                            >
                              <ExitToAppOutlinedIcon
                                style={{
                                  color: 'gray',
                                  marginRight: 10,
                                }}
                              />
                              Logout
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Hidden mdUp>
        <Drawer
          variant="temporary"
          anchor="left"
          open={showDrawer}
          onClose={toggleDrawer}
          classes={{
            paper: classes.drawer,
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: showDrawer,
            [classes.drawerClose]: !showDrawer,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: showDrawer,
              [classes.drawerClose]: !showDrawer,
            }),
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <main style={{ width: '100%' }}>
        <Box className={classes.toolbar} />
        <Box className={classes.content}>
          <Main />
        </Box>
      </main>
    </Box>
  );
};

export default AppDrawer;
