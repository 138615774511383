import { createModel } from '@rematch/core';
import { RootModel } from '.';
import * as api from '../api/sprints';
import {
  SprintReadRes,
  SprintDashboardSprintRes,
  SprintFinishSprintRequest,
  SprintDashboardSprintRequest,
  SprintReadRequest,
} from '../interfaces/Sprints';

interface SprintsState {
  sprint: SprintReadRes;
  dashboardSprint: SprintDashboardSprintRes;
}

export const Sprints = createModel<RootModel>()({
  state: {} as SprintsState,
  reducers: {
    loadSprint: (state, payload: SprintReadRes) => ({
      ...state,
      sprint: payload,
    }),
    loadDashboardSprint: (state, payload: SprintDashboardSprintRes) => ({
      ...state,
      dashboardSprint: payload,
    }),
  },
  effects: dispatch => ({
    readSprintAsync: async (options: SprintReadRequest) => {
      const res = await api.read(options);

      dispatch.Sprints.loadSprint(res);
    },
    finishSprintAsync: async (options: SprintFinishSprintRequest) => {
      await api.finishSprint(options);
    },
    dashboardSprintAsync: async (options: SprintDashboardSprintRequest) => {
      const res = await api.dashboard(options);
      dispatch.Sprints.loadDashboardSprint(res);
    },
  }),
});
