import { useEffect, useState } from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import BugType from '../../assets/SelectType/BugType.svg';
import TaskType from '../../assets/SelectType/TaskType.svg';
import StoryType from '../../assets/SelectType/StoryType.svg';

import { useStyles } from './styles';

interface SelectTypeProps {
  setValue: (value: 'task' | 'bug' | 'story') => void;
  initialValue: string | undefined;
}

const SelectType = ({ setValue, initialValue }: SelectTypeProps) => {
  const classes = useStyles();

  const [selectType, setSelectType] = useState(initialValue || 'story');
  const [openSelect, setOpenSelect] = useState(false);

  useEffect(() => {
    if (initialValue) {
      setSelectType(initialValue);
    }
  }, [initialValue]);

  const handleSelectType = (value: 'task' | 'bug' | 'story') => {
    setSelectType(value);
    setValue(value);
    handleOpenAndCloseSelect();
  };

  const handleOpenAndCloseSelect = () => {
    setOpenSelect(!openSelect);
  };

  interface Types {
    id: number;
    type: 'task' | 'bug' | 'story';
    img: string;
    alt: string;
  }

  const types: Types[] = [
    { id: 1, type: 'bug', img: BugType, alt: 'BugType' },
    { id: 2, type: 'task', img: TaskType, alt: 'TaskType' },
    { id: 3, type: 'story', img: StoryType, alt: 'StoryType' },
  ];

  const createButton = (type: string) => {
    const res = types.find((item: any) => item.type === type);

    return (
      <button
        type="button"
        className={classes.button}
        onClick={handleOpenAndCloseSelect}
      >
        <img src={res?.img} alt={res?.alt} />
      </button>
    );
  };

  const uppercaseFirstLetter = (string: string) =>
    string.charAt(0).toUpperCase() + string.slice(1);

  return (
    <div>
      {createButton(selectType)}

      <Card
        className={
          openSelect ? classes.openSelectType : classes.closeSelectType
        }
      >
        <CardContent className={classes.cardContent}>
          <Typography className={classes.title}>TIPOS</Typography>
          {types.map(item => (
            <button
              key={item.id}
              type="button"
              className={classes.button}
              onClick={() => handleSelectType(item.type)}
            >
              <img src={item.img} alt={item.type} />
              <Typography className={classes.text}>
                {uppercaseFirstLetter(item.type)}
              </Typography>
            </button>
          ))}
        </CardContent>
      </Card>
    </div>
  );
};

export default SelectType;
