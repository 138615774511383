import styled from 'styled-components';
// import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  createStyles,
  withStyles,
  makeStyles,
  Theme,
} from '@material-ui/core/styles';

import LinearProgress from '@material-ui/core/LinearProgress';

export const useStyles = makeStyles(theme => ({
  breadCrumbContainer: {
    width: 'calc(100% - 57px)',
    borderBottom: '1px solid #e0e0e0',
    padding: '0rem 3rem 0.5rem 3rem',
  },

  container: {
    marginTop: '1rem',
    padding: '0.5rem 4rem',
  },

  buttonCreate: {
    margin: theme.spacing(3, 0),
  },

  formsNewSquad: {
    width: 500,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  formButtonsContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },

  squadName: {
    fontFamily: 'Quicksand, sans-serif',
    fontWeight: 600,
    fontSize: 18,
  },

  sprintName: {
    fontFamily: 'Quicksand, sans-serif',
    fontWeight: 600,
    fontSize: 14,
  },

  cardTextSmall: {
    fontFamily: 'Quicksand, sans-serif',
    fontWeight: 500,
    fontSize: 12,
  },

  cardTextMedium: {
    fontFamily: 'Quicksand, sans-serif',
    fontWeight: 600,
    fontSize: 12,
  },

  squadCard: {
    height: '100%',
    padding: '0 1rem 0 1rem',
    border: '1px solid #F4F0F0',
    borderRadius: 15,
    '&:hover': {
      boxShadow: '0px 0px 3px 1px rgba(0,0,0,0.39)',
      cursor: 'pointer',
    },
  },

  addSquadCard: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#F6F6F6',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0px 0px 0px 0px rgba(0,0,0,0)',
    },
  },

  addIcon: {
    color: '#C4C4C4',
    fontSize: '58px',
  },

  iconTypography: {
    display: 'grid',
    alignItems: 'center',
    whiteSpace: 'pre',
  },

  Typography: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'pre',
  },

  gutterBottom: {
    marginBottom: theme.spacing(4),
  },

  icons: {
    marginRight: '0.25rem',
  },

  clockIcon: {
    width: '0.75rem',
    height: '0.75rem',
  },

  squadGridItem: {
    minWidth: '18rem',
    maxWidth: '18rem',
  },

  maxWidthModalItems: {
    width: '415px',
  },

  modalContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '1rem',
    marginBottom: '1rem',
  },

  modalFieldContainer: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },

  modalFieldNamePlusIcon: {
    width: '7rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  field: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export const Field = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TextArea = styled.textarea`
  min-height: 10rem;
  max-height: 40rem;
  resize: none;

  margin-bottom: 1rem;
`;

export const SprintProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 20,
      width: '100%',
      borderRadius: 10,
      marginBottom: '0.5rem',
    },
    colorPrimary: {
      backgroundColor:
        theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
      borderRadius: 10,
      backgroundColor: 'primary',
    },
  }),
)(LinearProgress);
