import { createModel } from '@rematch/core';
import { RootModel } from '.';

export interface SettingsState {
  drawer?: boolean;
}

export const Settings = createModel<RootModel>()({
  state: {} as SettingsState,
  reducers: {
    toggleDrawer: state => ({
      ...state,
      drawer: !state.drawer,
    }),
  },
  effects: () => ({}),
});
