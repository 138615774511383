import React, { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import CloseIcon from '@material-ui/icons/Close';
import SnackAlert from '../../../../components/SnackAlert';

import { dispatch } from '../../../../config/store';
import { useSnackAlert } from '../../../../hooks/useSnackAlert';

import { useStyles } from './styles';

interface RequirementContentProps {
  updateRequirement?: (id: number) => Promise<void>;
  toggleModal: () => void;
  requirementId?: number;
  description?: string;
  projectId: number;
  title?: string;
  edit?: boolean;
}

const RequirementContent: React.FC<RequirementContentProps> = ({
  title,
  projectId,
  description,
  toggleModal,
  edit = false,
  requirementId = 0,
  updateRequirement,
}) => {
  const [snackActive, setSnackActive] = useState(false);

  const [requirementTitle, setRequirementTitle] = useState('');
  const [requirementDescription, setRequirementDescription] = useState('');

  const [errorTitle, setErrorTitle] = useState(false);
  const [errorDescription, setErrorDescription] = useState(false);

  const classes = useStyles();

  useEffect(
    () =>
      edit
        ? setRequirementTitle(title!)
        : setRequirementTitle('Titulo do Requisito'),
    [edit, title],
  );

  useEffect(() => {
    setRequirementDescription(description ?? '');
  }, [description]);

  const handleResetStates = () => {
    setErrorTitle(false);
    setErrorDescription(false);
    setRequirementDescription('');
    setRequirementTitle('');
  };

  const toggleSnack = () => {
    setSnackActive(!snackActive);
  };

  const { message, severity } = useSnackAlert({
    message: 'Preencha os campos corretamente!',
    severity: 'error',
  });

  const handleError = () => {
    if (!requirementTitle) {
      setErrorTitle(true);
    }
    if (!requirementDescription) {
      setErrorDescription(true);
    }
  };

  const handleSetRequirement = async () => {
    try {
      const data = {
        name: requirementTitle,
        description: requirementDescription,
      };

      if (!edit) {
        await dispatch.Requirements.create({ ...data, project_id: projectId });
      } else if (edit) {
        await dispatch.Requirements.update({ ...data, id: requirementId });
        if (updateRequirement) {
          updateRequirement(requirementId);
        }
      }

      toggleModal();

      await dispatch.Requirements.readAllWithPagination({
        project_id: projectId,
        page: 1,
        limit: 100,
      });

      handleResetStates();
    } catch {
      handleError();

      toggleSnack();
    }
  };

  return (
    <Container>
      <SnackAlert
        open={snackActive}
        handleClose={toggleSnack}
        message={message}
        severity={severity}
      />

      <Box className={classes.header}>
        <TextField
          size="small"
          className={classes.title}
          error={errorTitle}
          value={requirementTitle}
          onChange={event => setRequirementTitle(event.target.value as string)}
          helperText={errorTitle && 'O titulo é obrigatório.'}
          InputProps={{
            disableUnderline: true,
            classes: {
              input: classes.inputTitle,
            },
          }}
        />
        <Box className={classes.BoxButtonClose}>
          <IconButton
            aria-label="CloseIcon"
            onClick={toggleModal}
            style={{ margin: '0 0 0 1rem' }}
            classes={{
              root: classes.iconButton,
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <Typography className={classes.titleDescription}>Descrição</Typography>
      <TextField
        label={!edit && 'Adicionar descrição do requisito'}
        size="small"
        multiline
        defaultValue={edit ? requirementDescription : ''}
        error={errorDescription}
        rows={6}
        className={classes.description}
        onChange={event =>
          setRequirementDescription(event.target.value as string)
        }
        helperText={errorDescription && 'A descrição é obrigatória.'}
        InputProps={{
          disableUnderline: true,
          classes: {
            input: classes.inputDescription,
          },
        }}
      />
      <Box
        className={classes.BoxButton}
        style={{ padding: '1rem 0rem 1rem 1rem' }}
      >
        <Button
          variant="contained"
          className={classes.cancelButton}
          style={{ marginRight: '1rem' }}
          color="inherit"
          onClick={toggleModal}
          classes={{
            root: classes.removeUppercase,
          }}
        >
          Cancelar
        </Button>
        <Button
          className={classes.saveButton}
          variant="contained"
          onClick={handleSetRequirement}
          color="primary"
          classes={{
            root: classes.removeUppercase,
          }}
        >
          Salvar
        </Button>
      </Box>
    </Container>
  );
};

export default RequirementContent;
