import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  paper: {
    height: '1rem',
  },
  buttonCreate: {
    backgroundColor: '#373737',
  },
  boardContainer: {
    display: 'flex',
    maxWidth: '100%',
    minHeight: '550px',
    paddingBottom: theme.spacing(2),
    overflowX: 'auto',
  },
  columnOrder: {
    width: '1360px',
    height: '690px',
  },
  boardFilters: {
    display: 'flex',
  },
  modalTitle: {
    padding: '0',
  },
  modalContent: {
    padding: '0 4rem 0 1rem',
  },
  loading: {
    margin: 0,
    position: 'absolute',
    top: ' 50%',
    transform: 'translateY(-50%)',
  },
  newColumn: {
    fontSize: '14px',
    margin: '8px 0',
  },
  droggable: {
    margin: '0 1rem 0 0',
  },
}));
