import * as requests from '../requests';
import {
  ColumnsCreateRequest,
  ColumnsCreateRes,
  ColumnsDeleteRequest,
  ColumnsDeleteRes,
  ColumnsReadRequest,
  ColumnsReadRes,
  ColumnsSortPositionsRequest,
  ColumnsSortPositionsRes,
  ColumnsUpdateRequest,
  ColumnsUpdateRes,
} from '../../interfaces/Columns';

export const create = requests.create<ColumnsCreateRequest, ColumnsCreateRes>(
  'columns',
);

export const remove = requests.remove<ColumnsDeleteRequest, ColumnsDeleteRes>(
  'columns',
);

export const read = requests.read<ColumnsReadRequest, ColumnsReadRes>(
  'columns',
);

export const update = requests.update<ColumnsUpdateRequest, ColumnsUpdateRes>(
  'columns',
);

export const sortPositions = requests.generic<
  ColumnsSortPositionsRes,
  ColumnsSortPositionsRequest
>(requests.Method.POST, 'columns', 'sortPositions');
