import React, { useCallback, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';

import { Create } from '@material-ui/icons';
import SearchIcon from '@material-ui/icons/Search';

import Dialog from '../../components/Dialog';
import RequirementContent from './Components/RequirementContent';
import { dispatch, RootState, store } from '../../config/store';

import TaskCard from './Components/TaskCard';
import NewTaskMenu from './Components/NewTaskMenu';
import BreadCrumbBar from '../../components/BreadCrumbBar';
import RequirementItem from './Components/RequirementItem';

import { useStyles } from './styles';
import { RequirementsReadAllRes } from '../../config/interfaces/Requirements';

const Requirements: React.FC = () => {
  const [modal, setModal] = useState(false);

  const [active, setActive] = useState<number>();

  const [title, setTitle] = useState<string | undefined>('');
  const [requirementInput, setRequirementInput] = useState('');
  const [requirementId, setRequirementId] = useState<number>(0);
  const [description, setDescription] = useState<string | undefined>('');

  const taskData = useSelector(
    (state: RootState) => state.Requirements.requirement,
  );
  const [columnsRequirement, setColumnsRequirement] = useState<
    RequirementsReadAllRes[]
  >([]);

  const classes = useStyles();

  const { projectId: strProject } = useParams<{ projectId: string }>();
  const projectId = +strProject;

  const { clientId } = useParams<{ clientId: string }>();

  const requirements = useSelector(
    (state: RootState) => state.Requirements.requirementsWithPagination,
  );
  const project = useSelector((state: RootState) =>
    state.Projects.find(p => p.id === projectId),
  );

  useEffect(() => {
    setTitle(taskData?.name);
    setDescription(taskData?.description);
    setRequirementId(taskData?.id ?? 0);
  }, [taskData]);

  useEffect(() => {
    if (requirements !== undefined) {
      setColumnsRequirement(
        Object.values(requirements?.data ?? []).map((req, index) => ({
          Id: Number(req.id),
          key: index,
          ...req,
        })),
      );

      setActive(requirements.data[0]?.id);
      dispatch.Requirements.read({ id: requirements.data[0]?.id });
    }
  }, [requirements]);

  useEffect(() => {
    if (requirementInput === '') {
      setColumnsRequirement(
        Object.values(requirements?.data ?? []).map((req, index) => ({
          Id: Number(req.id),
          key: index,
          ...req,
        })),
      );
    }
  }, [requirementInput, requirements]);

  const handleData = useCallback(async () => {
    await dispatch.Squads.readAll();
    await dispatch.Projects.readAll();
    await dispatch.Requirements.readAllWithPagination({
      project_id: projectId,
      page: 1,
      limit: 100,
    });
  }, [projectId]);

  const handleSetModal = () => {
    setModal(!modal);
  };

  const HandleNewTask = async (id: number) => {
    handleRequerimentSelect(id);
    setActive(id);
    handleData();
    await dispatch.Requirements.readAllWithPagination({
      project_id: projectId,
      page: 1,
      limit: 100,
    });

    if (active) {
      dispatch.Requirements.read({ id: active });
    }
  };

  useEffect(() => {
    handleData();
  }, [handleData]);

  const handleRequerimentSelect = async (id: number) => {
    if (id) {
      dispatch.Requirements.read({ id });
    }
  };

  const handleEditTitle = async () => {
    if (title && description && requirementId !== 0) {
      await dispatch.Requirements.update({
        id: requirementId,
        name: title,
        description,
      });
    }

    await dispatch.Requirements.readAllWithPagination({
      project_id: projectId,
      page: 1,
      limit: 100,
    });
  };

  const handleFieldChange = async (e: any) => {
    setRequirementInput(e.target.value);
  };

  const handleSearchKeyDown = (event: any) => {
    if (
      ((event.key && event.key === 'Enter') || !event.key) &&
      requirementInput
    ) {
      setActive(0);
      setRequirementId(0);

      dispatch.Requirements.readAll({
        project_id: projectId,
        requirement_name: requirementInput,
      }).then(() => {
        setColumnsRequirement(
          store.getState().Requirements?.requirements ??
            ([] as RequirementsReadAllRes[]),
        );
      });
    }
  };

  const breadCrumbData = [
    { label: 'Cliente', path: '/clients' },
    { label: 'Projetos', path: `/clients/${clientId}/projects` },
    { label: project?.name ?? 'Projeto', path: '' },
  ];

  return (
    <>
      <Dialog
        title=""
        content={
          <RequirementContent
            toggleModal={handleSetModal}
            projectId={projectId}
            title={title}
            description={description}
            requirementId={requirementId}
            edit
            updateRequirement={handleRequerimentSelect}
          />
        }
        open={modal}
        fullWidth
        maxWidth="sm"
        handleClose={handleSetModal}
        classDialogContent={classes.modalContent}
        classDialogTitle={classes.modalTitle}
        showActions={false}
      />
      <div className={classes.breadCrumbContainer}>
        <BreadCrumbBar data={breadCrumbData} />
      </div>
      <Box className={classes.container}>
        <div className={classes.containerList}>
          <Paper className={classes.paperInput}>
            <InputBase
              onChange={handleFieldChange}
              id="outlined-basic"
              placeholder="Search Requirements"
              onKeyDown={handleSearchKeyDown}
            />
            <IconButton
              onClick={handleSearchKeyDown}
              onKeyDown={handleSearchKeyDown}
              aria-label="search"
              className={classes.buttonSearch}
            >
              <SearchIcon className={classes.searchIcon} />
            </IconButton>
          </Paper>
          <Box>
            <h2 className={classes.title}>Requisitos</h2>
            <List className={classes.root} style={{ maxHeight: '60vh' }}>
              {columnsRequirement &&
                columnsRequirement.map((requirement, index) => (
                  <ListItem
                    key={requirement.id}
                    alignItems="flex-start"
                    className={classes.listItem}
                    classes={{
                      gutters: classes.list,
                    }}
                    style={{ width: '100%' }}
                    onClick={() => {
                      setActive(requirement.id);
                    }}
                  >
                    <RequirementItem
                      active={active!}
                      index={index}
                      requirement={requirement}
                      handleRequirementSelect={handleRequerimentSelect}
                    />
                  </ListItem>
                ))}
            </List>
          </Box>
        </div>
        <div className={classes.requirementData}>
          {active ? (
            <Box>
              <div className={classes.requirementSummary}>
                <div className={classes.requirementSummaryTitle}>
                  <Typography
                    id="requirementEdit"
                    className={classes.requirementTitle}
                  >
                    {title}
                  </Typography>
                  <IconButton
                    className={classes.edit}
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={() => {
                      handleSetModal();
                      handleEditTitle();
                    }}
                  >
                    <Create fontSize="small" color="primary" />
                  </IconButton>
                </div>
                <TextField
                  size="small"
                  multiline
                  value={description}
                  disabled
                  rows={3}
                  className={classes.description}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: classes.inputDescription,
                    },
                  }}
                />
                <p className={classes.summary} />
              </div>
              <div className={classes.requirementTasks}>
                <h4 className={classes.titleTask}>Tarefas</h4>
                <List className={classes.root} style={{ maxHeight: '45vh' }}>
                  {taskData &&
                    taskData.tasks.map(task => (
                      <ListItem
                        className={classes.tasksList}
                        style={{ width: '100%' }}
                        classes={{
                          root: classes.cardList,
                        }}
                        key={task.id}
                      >
                        <TaskCard
                          id={task.id ?? 1}
                          squadId={task.squad_id}
                          name={task.name}
                          type={task.type}
                          time={task.time_estimated ?? 0}
                          userId={task.user_assignee_id!}
                          sprint={task.sprint_id}
                          projectId={projectId}
                          requirementId={active ?? 0}
                        />
                      </ListItem>
                    ))}
                </List>
              </div>
            </Box>
          ) : undefined}
        </div>
        <div className={classes.fab}>
          <NewTaskMenu
            projectId={projectId}
            handleData={HandleNewTask}
            requirementId={active ?? 0}
          />
        </div>
      </Box>
    </>
  );
};

export default Requirements;
