import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  title: {
    flexGrow: 1,
  },
  actions: {
    '&:not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
  pointer: {
    cursor: 'pointer',
  },
  paginationActions: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(2),
  },
  paginationActionLabel: {
    fontSize: '0.875rem',
  },
}));
