import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import LinearProgress from '@material-ui/core/LinearProgress';
import { createStyles, withStyles } from '@material-ui/core/styles';

interface Props {
  name: string;
  allocatedHours: number;
  totalHours: number;
}

export function MemberCard({ name, allocatedHours, totalHours }: Props) {
  const percentage = (allocatedHours / totalHours) * 100;
  const progressHours =
    percentage > 100 ? 100 : totalHours === 0 ? 0 : percentage;

  const barColor = () => {
    if (progressHours <= 50) {
      return '#07AE5E';
    }

    if (progressHours < 100) {
      return '#FFCE50';
    }

    return '#C81D1D';
  };

  const CustomLinearProgress = withStyles(() =>
    createStyles({
      root: {
        height: 10,
        borderRadius: 5,
      },
      colorPrimary: {
        backgroundColor: '#C1C1C1',
      },
      bar: {
        borderRadius: 5,
        backgroundColor: barColor(),
      },
    }),
  )(LinearProgress);

  return (
    <div style={{ display: 'flex', flexGrow: 1 }}>
      <Avatar alt={name} src={name} />
      <div style={{ flexGrow: 1, marginLeft: '1rem' }}>
        <span>{name}</span>
        <div style={{ width: '100%' }}>
          <CustomLinearProgress variant="determinate" value={progressHours} />
        </div>
        <span style={{ color: '#C1C1C1' }}>
          {allocatedHours}h de {totalHours}h
        </span>
      </div>
    </div>
  );
}
