import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  atentionOption: {
    color: '#DA4545',
    fontSize: '0.9rem',
    fontWeight: 'normal',
  },
  actionTitle: {
    color: '#a7a7a7',
    fontWeight: 'bold',
    fontSize: '0.6rem',
    marginLeft: '15px',
    textTransform: 'uppercase',
  },
  menu: {
    width: '120px',
    marginTop: '40px',
    marginLeft: '-60px',
  },
}));
