import { LoginParameters, AuthState } from '../../models/auth';
import { api } from '../axios';

export const login = async ({
  email,
  password,
}: LoginParameters): Promise<AuthState> => {
  const res = await api.post('/auth/login', { uid: email, password });
  return {
    ...res.data.user,
    token: res.data.token,
    name: res.data.name,
  };
};
