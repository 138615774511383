import React, { useEffect, useState } from 'react';

import { AccessTime } from '@material-ui/icons';

import { Avatar, Box } from '@material-ui/core';

import IconButton from '@material-ui/core/IconButton';

import { useSelector } from 'react-redux';

import BugType from '../../../../assets/SelectType/BugType.svg';
import TaskType from '../../../../assets/SelectType/TaskType.svg';
import StoryType from '../../../../assets/SelectType/StoryType.svg';

import ActionMenu from './ActionMenu';

import Dialog from '../../../../components/Dialog';
import TaskContent from '../../../../components/TaskContent';
import { UserData } from '../../../../config/interfaces/Users';
import { dispatch, RootState } from '../../../../config/store';

import { useStyles } from './styles';

interface TaskCardProps {
  id: number;
  name: string;
  type: string;
  time: number;
  userId: number;
  sprint: number;
  squadId: number;
  projectId: number;
  requirementId: number;
}

const TaskCard: React.FC<TaskCardProps> = ({
  id,
  name,
  time,
  type,
  userId,
  sprint,
  squadId,
  projectId,
  requirementId,
}: TaskCardProps) => {
  const classes = useStyles();

  const squadName = useSelector((state: RootState) => state.Squads.squad?.name);
  const [modalActive, setModalActive] = useState(false);
  const [user, setUser] = useState<UserData>();
  const [initials, setInitials] = useState('');

  useEffect(() => {
    async function selectUser() {
      if (userId && !user) {
        setUser(await dispatch.Users.read(userId));
      }
    }
    selectUser();
  }, [user, userId]);

  useEffect(() => {
    if (user) {
      const userName = user?.name;
      if (userName) {
        setInitials(userName.substring(0, 2).toUpperCase());
      }
    }
  }, [user]);

  const handleModal = () => {
    setModalActive(!modalActive);
  };

  const icons = [
    { icon: StoryType, alt: 'StoryType' },
    { icon: BugType, alt: 'BugType' },
    { icon: TaskType, alt: 'TaskType' },
  ];

  useEffect(() => {
    dispatch.Squads.read({ id: squadId });
  }, [squadId]);

  return (
    <>
      <Dialog
        title=""
        content={
          <TaskContent
            closeModal={setModalActive}
            projectId={projectId}
            requirementId={requirementId}
          />
        }
        fullWidth
        maxWidth="md"
        open={modalActive}
        handleClose={handleModal}
        classDialogContent={classes.modalContent}
        classDialogTitle={classes.modalTitle}
        showActions={false}
      />

      <Box className={classes.taskCard} onClick={handleModal}>
        <div>
          <div className={classes.taskType}>
            <IconButton
              aria-label="personAdd"
              disabled
              style={{ paddingLeft: '0' }}
            >
              <img
                src={
                  type === 'story'
                    ? icons[0].icon
                    : type === 'bug'
                    ? icons[1].icon
                    : icons[2].icon
                }
                alt={
                  type === 'story'
                    ? icons[0].alt
                    : type === 'bug'
                    ? icons[1].alt
                    : icons[2].alt
                }
              />
            </IconButton>
            <p className={classes.taskID}>IS-{sprint}</p>
          </div>
          <h5 className={classes.taskName}>{name}</h5>
        </div>

        <div className={classes.cardBody}>
          <p className={classes.tag}>{squadName}</p>
          <p className={classes.tag}>Status</p>
          <div className={classes.time}>
            <AccessTime className={classes.timeIcon} />
            <p className={classes.timeQuantity}>{time}</p>
          </div>
          <Avatar className={classes.avatar}>{initials}</Avatar>
          <div className={classes.actionMenu}>
            <ActionMenu id={id} requirementId={requirementId} />
          </div>
        </div>
      </Box>
    </>
  );
};

export default TaskCard;
