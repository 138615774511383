import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    flexDirection: 'column',
    height: '100vh',
  },

  paper: {
    marginTop: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 15,
    '@media (max-width:1366px)': {
      marginTop: theme.spacing(20),
    },
  },

  logo: {
    width: '70%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 0,
  },

  img: {
    width: '90%',
    height: '50%',
  },

  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },

  inputEmail: {
    marginBottom: 0,
  },

  inputPassword: {
    marginTop: 10,
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
    color: theme.palette.common.white,
    fontWeight: 600,
    '&:hover': {
      background: theme.palette.primary.light,
    },
  },

  remember: {
    display: 'flex',
    justifyContent: 'center',
  },

  forgot: {
    textAlign: 'center',
    fontSize: '12px',
    marginBottom: 10,
  },

  link: {
    color: theme.palette.primary.main,
  },

  error: {
    color: 'red',
    margin: '5px 0 5px 10px',
    fontSize: '12px',
  },

  errorCredential: {
    height: '30px',
    marginTop: '8px',
    display: 'flex',
    justifyContent: 'center',
  },

  errorCredentialText: {
    color: 'red',
    fontSize: '12px',
  },
}));
