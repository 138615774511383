import { createTheme } from '@material-ui/core/styles';

export default createTheme({
  palette: {
    common: {
      black: '#000',
      white: '#fff',
    },
    primary: {
      main: '#263579',
      light: '#263599',
    },
    secondary: {
      main: '#B9B9B7',
    },
    warning: {
      main: '#ff9800',
      light: '#ffb74d',
      dark: '#f57c00',
    },
    success: {
      main: '#4caf50',
      light: '#81c784',
      dark: '#388e3c',
    },
    info: {
      main: '#2196f3',
      light: '#64b5f6',
      dark: '#1976d2',
    },
    error: {
      main: '#f44336',
      light: '#e57373',
      dark: '#d32f2f',
    },
    text: {
      primary: '#000',
      secondary: '#B9B9B7',
    },
    grey: {
      500: '#f8f8f8',
      600: '#A7A7A7',
    },
  },
  typography: {
    fontFamily: 'Quicksand, sans-serif',
    h2: {
      fontSize: '1.75rem',
      fontWeight: 500,
    },
    h5: {
      fontSize: '1rem',
      fontWeight: 700,
    },
    caption: {
      fontSize: '0.75rem',
      fontWeight: 400,
      color: '#A7A7A7',
    },
  },
});
