import * as requests from '../requests';
import { UserData } from '../../interfaces/Users';

export interface CreateOptions {
  name: string;
  email: string;
  password: string;
  daily_average_hours: number;
  password_confirmation: string;
}
export interface EditPasswordOptions {
  old_password: string;
  password: string;
  password_confirmation: string;
}

export interface EditNameOptions {
  id: number;
  name: string;
  daily_average_hours: number;
}

export interface SearchOptions {
  user_name: string;
  active: boolean;
}

export interface UsersActive {
  active: boolean;
}

interface ReadOptions {
  id: number;
}

interface DeleteOptions {
  id: number;
}

export const create = requests.create<CreateOptions, UserData>('users');
export const read = requests.read<ReadOptions, UserData>('users');
export const readAll = requests.readAll<UserData>('users');
export const update = requests.update<EditNameOptions, UserData>('users');
export const remove = requests.remove<DeleteOptions, UserData>('users');
export const changepassword = requests.changepassword<
  EditPasswordOptions,
  UserData
>('users');
export const searchUser = requests.readAll<UserData, SearchOptions>('users');
export const activeUser = requests.readAll<UserData, UsersActive>('users');
