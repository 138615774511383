import { createModel } from '@rematch/core';
import { RootModel } from '.';

import * as api from '../api/users';
import { UserData } from '../interfaces/Users';

type UsersState = {
  users: UserData[];
  user: UserData[];
};

export const Users = createModel<RootModel>()({
  state: {} as UsersState,

  reducers: {
    loadUsers: (state, payload: UserData[]) => ({
      ...state,
      users: payload,
    }),

    loadUser: (state, payload: UserData[]) => ({
      ...state,
      user: payload,
    }),
  },
  effects: dispatch => ({
    readAll: async () => {
      const users = await api.readAll();
      dispatch.Users.loadUsers(users);
    },
    search: async (userName: string) => {
      const user = await api.searchUser({ user_name: userName, active: true });
      dispatch.Users.loadUsers(user);
    },
    active: async () => {
      const users = await api.activeUser({ active: true });
      dispatch.Users.loadUsers(users);
    },
    read: async (id: number) => {
      const users = await api.read({ id });
      return users;
    },
    create: async (options: api.CreateOptions) => {
      await api.create(options);
    },
    remove: async (id: number) => {
      await api.remove({ id });
    },
    changepassword: async (options: api.EditPasswordOptions) => {
      await api.changepassword(options);
    },
    update: async (options: api.EditNameOptions) => {
      await api.update(options);
    },
  }),
});
