import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { RootState } from '../../config/store';

export interface PropsUsersIcon {}

const UsersIcon = () => {
  const route = useSelector((state: RootState) => state.Drawer.path);

  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (route && route === '/users') {
      setIsActive(true);
    }
  }, [route]);

  return (
    <svg
      width="25"
      height="15"
      viewBox="0 0 25 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.08867 7.72539C7.13389 7.72539 6.21822 7.34611 5.54309 6.67098C4.86796 5.99584 4.48867 5.08017 4.48867 4.12539C4.48867 3.17061 4.86796 2.25494 5.54309 1.57981C6.21822 0.904675 7.13389 0.525391 8.08867 0.525391C9.04345 0.525391 9.95913 0.904675 10.6343 1.57981C11.3094 2.25494 11.6887 3.17061 11.6887 4.12539C11.6887 5.08017 11.3094 5.99584 10.6343 6.67098C9.95913 7.34611 9.04345 7.72539 8.08867 7.72539ZM17.6887 7.72539C16.7339 7.72539 15.8182 7.34611 15.1431 6.67098C14.468 5.99584 14.0887 5.08017 14.0887 4.12539C14.0887 3.17061 14.468 2.25494 15.1431 1.57981C15.8182 0.904675 16.7339 0.525391 17.6887 0.525391C18.6435 0.525391 19.5591 0.904675 20.2343 1.57981C20.9094 2.25494 21.2887 3.17061 21.2887 4.12539C21.2887 5.08017 20.9094 5.99584 20.2343 6.67098C19.5591 7.34611 18.6435 7.72539 17.6887 7.72539ZM8.08867 10.1254C9.45088 10.1238 10.7978 10.4128 12.0394 10.9732C13.281 11.5336 14.3887 12.3524 15.2887 13.375V14.9254H0.888672V13.375C1.78862 12.3524 2.89638 11.5336 4.13798 10.9732C5.37959 10.4128 6.72647 10.1238 8.08867 10.1254ZM17.6887 14.9254V12.4678L17.0887 11.7874C16.6307 11.268 16.1288 10.7891 15.5887 10.3558C16.2781 10.2022 16.9823 10.125 17.6887 10.1254C19.0509 10.1238 20.3978 10.4128 21.6394 10.9732C22.881 11.5336 23.9887 12.3524 24.8887 13.375V14.9254H17.6887Z"
        fill={isActive === false ? '#6c6c6c' : '#263579'}
      />
    </svg>
  );
};
export default UsersIcon;
