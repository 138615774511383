import React from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ScheduleIcon from '@material-ui/icons/Schedule';
import WarningIcon from '@material-ui/icons/Warning';

import { SprintReadRes } from '../../config/interfaces/Sprints';

import { useStyles } from './styles';

interface PDSDeleteTaskDialogProps {
  open: boolean;
  handleClose: () => void;
  confirm: (data?: any) => void;
  showActions?: boolean;
  fullWidth?: boolean;
  task?: SprintReadRes['sprint']['columns'][0]['tasks'][0];
}

const PDSDeleteTaskDialog: React.FC<PDSDeleteTaskDialogProps> = ({
  open = false,
  handleClose,
  confirm,
  fullWidth = false,
  task,
}) => {
  const handleClick = () => {
    confirm();
    handleClose();
  };

  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={fullWidth}
      maxWidth="lg"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <div className={classes.dialogTitleWrapper}>
          <WarningIcon style={{ color: '#C81D1D' }} />
          <span className={classes.dialogTitle}>Excluir issue?</span>
        </div>
      </DialogTitle>
      <DialogContent>
        <span className={classes.dialogMessage}>
          Você está prestes a excluir permanentemente esta issue. Tem certeza
          que deseja continuar?
        </span>
        <div className={classes.task}>
          <div className={classes.taskLeft}>
            <div className={classes.issue}>
              <CheckBoxIcon color="primary" fontSize="small" />
              <span className={classes.taskID}>IS-{task?.id}</span>
            </div>
            <div>
              <span className={classes.taskDescription}>{task?.name}</span>
            </div>
          </div>
          <div className={classes.taskRight}>
            <span className={classes.taskStatus}>
              {task?.name ?? 'Em progresso'}
            </span>
            <span className={classes.taskDifficulty}>
              <div className={classes.estimate}>
                <ScheduleIcon fontSize="small" />
                <span className={classes.time}>40</span>
              </div>
            </span>
            <Avatar alt={task?.name} src={task?.name} />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div className={classes.formButtonsContainer}>
          <Button
            variant="text"
            className={classes.buttonCreate}
            style={{ marginRight: 8 }}
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button
            style={{ backgroundColor: '#C81D1D', color: '#fff' }}
            variant="contained"
            type="submit"
            onClick={handleClick}
            className={classes.buttonCreate}
          >
            Excluir
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default PDSDeleteTaskDialog;
