import { Models } from '@rematch/core';

import { Auth } from './auth';
import { Users } from './users';
import { Tasks } from './tasks';
import { Squads } from './squads';
import { Drawer } from './drawer';
import { Columns } from './columns';
import { Clients } from './clients';
import { Sprints } from './sprints';
import { Settings } from './settings';
import { Projects } from './projects';
import { Requirements } from './requirements';
import { BoardFilter } from './boardFilter';

export interface RootModel extends Models<RootModel> {
  Auth: typeof Auth;
  Users: typeof Users;
  Tasks: typeof Tasks;
  Squads: typeof Squads;
  Columns: typeof Columns;
  Clients: typeof Clients;
  Sprints: typeof Sprints;
  Settings: typeof Settings;
  Projects: typeof Projects;
  BoardFilter: typeof BoardFilter;
  Requirements: typeof Requirements;
}
export const models: RootModel = {
  Auth,
  Users,
  Tasks,
  Squads,
  Drawer,
  Columns,
  Clients,
  Sprints,
  Settings,
  Projects,
  BoardFilter,
  Requirements,
};
