import { createModel } from '@rematch/core';
import { RootModel } from '.';

import * as api from '../api/squads';
import {
  SquadCreateRes,
  SquadCreateRequest,
  SquadReadHistoricRes,
  SquadReadHistoricRequest,
  SquadReadRes,
  SquadReadRequest,
  SquadReadAllRes,
  SquadReadAllWithPaginationRequest,
  SquadReadAllWithPaginationRes,
  SquadUpdateRes,
  SquadUpdateRequest,
  SquadGetUsersHoursRes,
  SquadGetUsersHoursRequest,
} from '../interfaces/Squads';

type SquadState = {
  createdSquad: SquadCreateRes | undefined;
  squadHistoric: SquadReadHistoricRes | undefined;
  squad: SquadReadRes | undefined;
  squads: SquadReadAllRes[];
  squadsWithPagination: SquadReadAllWithPaginationRes | undefined;
  updatedSquad: SquadUpdateRes | undefined;
  usersHours: SquadGetUsersHoursRes | undefined;
};

export const Squads = createModel<RootModel>()({
  state: {} as SquadState,

  reducers: {
    loadCreatedSquad: (state, payload: SquadCreateRes) => ({
      ...state,
      createdSquad: payload,
    }),

    loadHistoric: (state, payload: SquadReadHistoricRes) => ({
      ...state,
      squadHistoric: payload,
    }),

    loadSquad: (state, payload: SquadReadRes) => ({
      ...state,
      squad: payload,
    }),

    loadSquads: (state, payload: SquadReadAllRes[]) => ({
      ...state,
      squads: payload,
    }),

    loadSquadsWithPaginaton: (
      state,
      payload: SquadReadAllWithPaginationRes,
    ) => ({
      ...state,
      squadsWithPagination: payload,
    }),

    loadUpdatedSquad: (state, payload: SquadUpdateRes) => ({
      ...state,
      updatedSquad: payload,
    }),

    loadUsersHours: (state, payload: SquadGetUsersHoursRes) => ({
      ...state,
      usersHours: payload,
    }),
  },

  effects: dispatch => ({
    create: async (payload: SquadCreateRequest) => {
      const res = await api.create(payload);
      dispatch.Squads.loadCreatedSquad(res);
    },

    readHistoric: async (payload: SquadReadHistoricRequest) => {
      const res = await api.readHistoric(payload);
      dispatch.Squads.loadHistoric(res);
    },

    read: async (payload: SquadReadRequest) => {
      const res = await api.read(payload);
      dispatch.Squads.loadSquad(res);
    },

    readAll: async () => {
      const res = await api.readAll();
      dispatch.Squads.loadSquads(res);
    },

    readAllWithPagination: async (
      payload: SquadReadAllWithPaginationRequest,
    ) => {
      const res = await api.readAllWithPagination(payload);
      dispatch.Squads.loadSquadsWithPaginaton(res);
    },

    update: async (payload: SquadUpdateRequest) => {
      const res = await api.update(payload);
      dispatch.Squads.loadUpdatedSquad(res);
    },

    getUsersHours: async (payload: SquadGetUsersHoursRequest) => {
      const res = await api.getUsersHours(payload);
      dispatch.Squads.loadUsersHours(res);
    },
  }),
});
