import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import ButtonBase from '@material-ui/core/ButtonBase';
import Button from '@material-ui/core/Button';

import { dispatch } from '../../../../../config/store';

interface CreateColumnModalProps {
  toggleModal: () => void;
  squadId: number;
  updateColumns: () => Promise<void>;
}

const CreateColumnModal: React.FC<CreateColumnModalProps> = ({
  toggleModal,
  squadId,
  updateColumns,
}: CreateColumnModalProps) => {
  const [newColumnName, setNewColumnName] = useState('');

  async function handleCreateColumn() {
    await dispatch.Columns.create({
      name: newColumnName,
      position: 1,
      squad_id: squadId,
    }).then(() => {
      setNewColumnName('');
      toggleModal();
      updateColumns();
    });
  }

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Nome da coluna
      </Typography>
      <Box width="14rem">
        <TextField
          multiline
          fullWidth
          id="outlined-multiline-flexible"
          value={newColumnName}
          placeholder="Adcionar nome da coluna"
          onChange={event => setNewColumnName(event.target.value as string)}
          rows={4}
          variant="standard"
          InputProps={{
            disableUnderline: true,
          }}
        />
      </Box>
      <Box textAlign="end">
        <ButtonBase onClick={toggleModal} style={{ marginRight: '1rem' }}>
          <Typography variant="button">Cancelar</Typography>
        </ButtonBase>
        <Button
          variant="contained"
          onClick={handleCreateColumn}
          color="primary"
        >
          Salvar
        </Button>
      </Box>
    </Box>
  );
};

export default CreateColumnModal;
