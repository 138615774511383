import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  router: {
    fontSize: '16px',
    color: '#373737',
    gridGap: '0.5rem',
    alignItems: 'center',
  },

  root: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
      scrollbarWidth: 'none',
    },
    backgroundColor: theme.palette.background.paper,
  },

  listItem: {
    heigth: '100%',
  },

  noBorder: {
    border: 'none',
  },

  cardList: {
    display: 'block',
  },

  routerContent: {
    margin: 0,
    display: 'flex',
    gridGap: '0.5rem',
    alignItems: 'flex-end',
  },

  breadCrumbContainer: {
    borderBottom: '1px solid #e0e0e0',
    padding: '0rem 3rem 0.5rem 3rem',
  },

  container: {
    height: '76vh',
    display: 'flex',
    margin: '1rem 3rem',
    fontFamily: 'Quicksand, sans-serif',
  },

  containerList: {
    flex: 4,
    height: '100%',
    borderRight: '1px solid #e5e5e5',
  },

  paperInput: {
    width: '230px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 3px 0px 15px',
    margin: '15px 0 30px 0',
    background: '#F8F8F8',
    border: '1px solid #E0E0E0',
    boxShadow: 'none',
    '&:hover': {
      cursor: 'pointer',
      border: '1px solid #263579',
    },
    '@media(max-width: 768px)': {
      width: '85%',
    },
  },

  buttonSearch: {
    width: '35px',
    height: '35px',
    padding: '0',
    margin: '0',
  },

  searchIcon: {
    margin: '0',
  },

  input: {
    color: '#000000',
    fontWeight: 'bold',
    fontSize: '14px',
  },

  title: {
    fontSize: '1rem',
    color: '#373737',
    fontWeight: 'bold',
    marginBottom: '1rem',
  },

  list: {
    listStyleType: 'none',
    paddingLeft: '2px',
  },

  requirementData: {
    flex: 5,
    marginLeft: '2rem',
  },

  requirementSummary: {
    width: '100%',
    backgroundColor: '#fff',
  },

  requirementSummaryTitle: {
    display: 'flex',
  },

  inputTitle: {
    display: 'flex',
    fontSize: '18px',
    color: '#373737',
    fontWeight: 'bold',
    alignItems: 'center',
  },

  titleSummary: {
    fontSize: '1rem',
    color: '#373737',
    marginTop: '20px',
    fontWeight: 'bold',
    marginRight: '5px',
    marginBottom: '1rem',
  },

  edit: {
    color: '#c4c4c4',
    padding: '1rem',
  },

  summary: {
    fontSize: '16px',
    marginTop: '0px',
    marginBottom: '0',
    fontWeight: 'normal',
  },

  requirementTasks: {
    width: '100%',
    backgroundColor: '#fff',
  },

  titleTask: {
    color: '#373737',
    fontSize: '1rem',
    marginTop: '0',
    marginRight: '5px',
    fontWeight: 'bold',
    marginBottom: '0',
  },

  tasksList: {
    paddingLeft: '0',
  },

  fab: {
    top: 'auto',
    left: 'auto',
    margin: '0px',
    right: '20px',
    bottom: '20px',
    position: 'fixed',
  },

  formControl: {
    flexDirection: 'unset',
  },

  inputDescription: {
    fontSize: '16px',
    color: '#373737',
    lineHeight: '35px',
    fontFamily: 'Quicksand',
    '&::-webkit-scrollbar': {
      display: 'none',
      scrollbarWidth: 'none',
    },
  },

  requirementTitle: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '18px',
    color: '#373737',
    fontWeight: 'bold',
  },

  description: {
    width: '100%',
    fontFamily: 'Quicksand',
    fontSize: '16px',
    color: '#000000',
    padding: '0.2rem 0',
  },

  breadCrumbBar: {
    fontSize: 16,
  },

  modalTitle: {
    padding: '0',
  },

  modalContent: {
    padding: '8px 18px 8px 18px',
  },
}));
