import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { RootState } from '../config/store';

const RestrictedRoute: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const isLoggedIn = useSelector(
    (state: RootState) => state.Auth.token !== undefined,
  );
  const history = useHistory();

  useEffect(() => {
    if (!isLoggedIn) {
      const currentLocation = history.location.pathname;
      history.push('/', { afterLogin: currentLocation });
    }
  }, [isLoggedIn, history]);

  return children;
};

export default RestrictedRoute;
