/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';

import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import AutoComplete from '@material-ui/lab/Autocomplete';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';

import TimerIcon from '@material-ui/icons/Timer';

import { useSelector } from 'react-redux';

import moment from 'moment';

import { UserData } from '../../config/interfaces/Users';
import { SquadReadAllRes } from '../../config/interfaces/Squads';
import { ProjectData } from '../../config/models/projects';
import { RequirementsReadAllRes } from '../../config/interfaces/Requirements';

import { dispatch, RootState } from '../../config/store';

import SelectType from '../SelectType';

type TaskContentProps = {
  issueId?: number;
  closeModal: Function;
  columnId?: number;
  sprintId?: boolean;
} & (
  | {
      projectId: number;
    }
  | {
      squadId: number;
    }
  | {
      requirementId: number;
    }
);

const TaskContentDefaultProps = {
  issueId: undefined,
  columnId: undefined,
  sprintId: false,
};

require('moment/locale/pt-br');

moment.locale('pt-br');

const TaskContent: React.FC<TaskContentProps> = (props: TaskContentProps) => {
  const hasProjectId = (
    taskContentProps: TaskContentProps,
  ): taskContentProps is TaskContentProps & {
    projectId: number;
  } => (taskContentProps as any).projectId !== undefined;

  const hasRequirementId = (
    taskContentProps: TaskContentProps,
  ): taskContentProps is TaskContentProps & {
    requirementId: number;
  } => (taskContentProps as any).requirementId !== undefined;

  const hasSquadId = (
    taskContentProps: TaskContentProps,
  ): taskContentProps is TaskContentProps & { squadId: number } =>
    (taskContentProps as any).squadId !== undefined;

  const [issueType, setIssueType] = useState<'task' | 'bug' | 'story'>('task');
  const [issueTitle, setIssueTitle] = useState<string>('');
  const [selectedUser, setSelectedUser] = useState<UserData>();
  const [selectedSquad, setSelectedSquad] = useState<SquadReadAllRes>();
  const [selectedProject, setSelectedProject] = useState<ProjectData>();
  const [selectedRequirement, setSelectedRequirement] =
    useState<RequirementsReadAllRes>();
  const [estimatedTime, setEstimatedTime] = useState<number>(0);
  const [remainingTime, setRemainingTime] = useState<number>(0);
  const [realTime, setRealTime] = useState<number>(0);
  const [progress, setProgress] = useState<string>('0%');
  const [description, setDescription] = useState<string>('');
  const [selectedReporter, setSelectedReporter] = useState<UserData>();

  const [saveClicked, setSaveClicked] = useState<boolean>(false);

  const users = useSelector((state: RootState) => state.Users.users);
  const squads = useSelector((state: RootState) => state.Squads.squads);
  const projects = useSelector((state: RootState) => state.Projects);
  const requirements = useSelector(
    (state: RootState) => state.Requirements.requirements,
  );
  const issue = useSelector((state: RootState) => state.Tasks.task);

  const [filteredUsers, setFilteredUsers] = useState<UserData[]>([]);

  const handleSave = () => {
    setSaveClicked(true);

    if (!issueTitle || !selectedProject || !selectedSquad) {
      return;
    }

    if (props.issueId && issue) {
      dispatch.Tasks.update({
        id: props.issueId,
        name: issueTitle,
        description,
        type: issueType,
        project_id: selectedProject.id,
        column_id: issue.column_id,
        position: issue.position,
        time_spent: realTime,
        time_estimated: estimatedTime,
        time_remaining: remainingTime,
        ...(selectedUser
          ? { user_assignee_id: selectedUser.id }
          : { user_assignee_id: null }),
        ...(selectedRequirement !== undefined
          ? { requirement_id: selectedRequirement.id }
          : { requirement_id: null }),
      }).then(() => {
        props.closeModal();
      });
    } else {
      dispatch.Tasks.create({
        name: issueTitle,
        description,
        type: issueType,
        ...(props.columnId && { column_id: props.columnId }),
        ...(props.sprintId && { sprint_id: selectedSquad.sprint.id }),
        squad_id: selectedSquad.id,
        project_id: selectedProject.id,
        time_spent: realTime,
        time_estimated: estimatedTime,
        time_remaining: remainingTime,
        ...(selectedUser && { user_assignee_id: selectedUser.id }),
        ...(selectedRequirement && { requirement_id: selectedRequirement.id }),
      }).then(() => {
        props.closeModal();
      });
    }
  };

  useEffect(() => {
    if (!selectedProject) {
      return;
    }

    dispatch.Requirements.readAll({ project_id: selectedProject.id });
  }, [selectedProject]);

  useEffect(() => {
    if (issue?.project_id) {
      dispatch.Requirements.readAll({
        project_id: issue.project_id,
      });
    }
  }, [issue]);

  useEffect(() => {
    if (!requirements) {
      return;
    }

    if (issue?.requirement_id && props.issueId) {
      setSelectedRequirement(
        requirements.find(r => r.id === issue.requirement_id),
      );
    } else {
      setSelectedRequirement(undefined);
    }
  }, [requirements, issue]);

  useEffect(() => {
    if (!props.issueId) {
      return;
    }

    dispatch.Tasks.read({ id: props.issueId });
  }, []);

  useEffect(() => {
    if (!filteredUsers || !squads || !issue || !props.issueId) {
      return;
    }

    setIssueType(issue.type as 'task' | 'bug' | 'story');
    setIssueTitle(issue.name);
    setSelectedUser(
      filteredUsers.find(user => user.id === issue.user_assignee_id),
    );
    setSelectedSquad(squads.find(squad => squad.id === issue.squad_id));
    setSelectedProject(issue.project as ProjectData);
    setEstimatedTime(issue.time_estimated);
    setRemainingTime(issue.time_remaining);
    setRealTime(issue.time_spent);
    setDescription(issue.description);
    setSelectedReporter(
      filteredUsers.find(user => user.id === issue.user_reporter_id),
    );
  }, [issue, filteredUsers, squads]);

  useEffect(() => {
    if (hasSquadId(props) && squads) {
      setSelectedSquad(squads.find(squad => squad.id === props.squadId));
    }

    if (hasProjectId(props) && projects) {
      setSelectedProject(
        projects.find(project => project.id === props.projectId),
      );
    }

    if (hasRequirementId(props) && requirements) {
      setSelectedRequirement(
        requirements.find(
          requirement => requirement.id === props.requirementId,
        ),
      );
    }
  }, [squads, projects, requirements]);

  useEffect(() => {
    if (!users) {
      return;
    }

    if (hasSquadId(props)) {
      setFilteredUsers(
        users.filter(
          user =>
            user.squads.filter(squad => squad.id === props.squadId).length > 0,
        ),
      );
    } else if (selectedSquad) {
      setFilteredUsers(
        users.filter(
          user =>
            user.squads.filter(squad => squad.id === selectedSquad.id).length >
            0,
        ),
      );
    }
  }, [users, selectedSquad]);

  const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEstimatedTime(+event.target.value);
  };

  useEffect(() => {
    dispatch.Users.readAll();
    dispatch.Squads.readAll();
    dispatch.Projects.readAll();
  }, []);

  return (
    <Box py={3.5} px={2.3}>
      <Box display="flex" alignItems="center" mb="1.1875rem">
        <SelectType initialValue={issueType} setValue={setIssueType} />
        <span
          style={{
            fontSize: '0.6875rem',
            color: '#898989',
            fontFamily: 'Quicksand',
          }}
        >
          IS-0 &#8226; Sprint 1
        </span>
      </Box>

      <TextField
        style={{ width: '100%', marginBottom: '1.1825rem' }}
        placeholder="Título da Task"
        value={issueTitle}
        onChange={e => setIssueTitle(e.target.value)}
        InputProps={{ style: { fontSize: '1.75rem' } }}
        error={saveClicked && issueTitle.length === 0}
        helperText={
          saveClicked && issueTitle.length === 0 ? 'Campo obrigatório' : ''
        }
      />

      <Box
        display="flex"
        alignItems="center"
        mb="0.875rem"
        style={{ gap: '1rem' }}
      >
        <AutoComplete
          id="combo-box-users"
          options={filteredUsers ?? []}
          value={selectedUser ?? null}
          noOptionsText="Sem opções"
          getOptionLabel={user => user.name}
          size="small"
          style={{ flexGrow: 1 }}
          renderInput={params => (
            <TextField {...params} label="Responsável" variant="outlined" />
          )}
          onChange={(_event, value) => {
            setSelectedUser(value ?? undefined);
          }}
          disabled={!hasSquadId(props) && !selectedSquad}
        />

        <AutoComplete
          id="combo-box-squads"
          options={squads ?? []}
          value={selectedSquad ?? null}
          noOptionsText="Sem opções"
          getOptionLabel={squad => squad.name}
          size="small"
          style={{ flexGrow: 1 }}
          renderInput={params => (
            <TextField
              {...params}
              label="Squad"
              variant="outlined"
              error={saveClicked && !selectedSquad}
              helperText={
                saveClicked && !selectedSquad ? 'Campo obrigatório' : ''
              }
            />
          )}
          onChange={(_event, value) => {
            setSelectedSquad(value ?? undefined);
          }}
          disabled={hasSquadId(props)}
        />

        <AutoComplete
          id="combo-box-projects"
          options={projects ?? []}
          value={selectedProject ?? null}
          noOptionsText="Sem opções"
          getOptionLabel={project => project.name}
          size="small"
          style={{ flexGrow: 1 }}
          renderInput={params => (
            <TextField
              {...params}
              label="Projeto"
              variant="outlined"
              error={saveClicked && !selectedProject}
              helperText={
                saveClicked && !selectedProject ? 'Campo obrigatório' : ''
              }
            />
          )}
          onChange={(_event, value) => {
            setSelectedProject(value ?? undefined);
            if (!value) {
              setSelectedRequirement(undefined);
            }
          }}
          disabled={hasProjectId(props)}
        />

        <AutoComplete
          id="combo-box-requirements"
          options={requirements ?? []}
          value={selectedRequirement ?? null}
          noOptionsText="Sem opções"
          getOptionLabel={requirement => requirement.name}
          size="small"
          style={{ flexGrow: 1 }}
          renderInput={params => (
            <TextField {...params} label="Requisitos" variant="outlined" />
          )}
          onChange={(_event, value) => {
            setSelectedRequirement(value ?? undefined);
          }}
          disabled={hasRequirementId(props) || !selectedProject}
        />
      </Box>

      <Box
        display="flex"
        alignItems="center"
        mb="1.625rem"
        style={{ gap: '1rem', width: '60%' }}
      >
        <Box>
          <span style={{ fontSize: '1rem', fontFamily: 'Quicksand' }}>
            Horas <br /> Estimadas
          </span>
          <Box display="flex" alignItems="center" style={{ gap: '0.4375rem' }}>
            <TimerIcon style={{ fontSize: '1.2rem' }} />
            <FormControl>
              <Input
                style={{ flexGrow: 1, fontSize: '1.2rem' }}
                placeholder="0"
                value={estimatedTime}
                onChange={handleTimeChange}
                disableUnderline
                type="number"
                inputProps={{
                  min: 0,
                }}
              />
            </FormControl>
          </Box>
        </Box>

        <Box>
          <span style={{ fontSize: '1rem', fontFamily: 'Quicksand' }}>
            Horas <br /> Restantes
          </span>
          <Box display="flex" alignItems="center" style={{ gap: '0.4375rem' }}>
            <TimerIcon style={{ fontSize: '1.2rem' }} />
            <TextField
              style={{ flexGrow: 1 }}
              placeholder="0"
              value={remainingTime}
              InputProps={{
                style: { fontSize: '1.2rem' },
                disableUnderline: true,
              }}
              type="number"
              disabled
            />
          </Box>
        </Box>

        <Box>
          <span style={{ fontSize: '1rem', fontFamily: 'Quicksand' }}>
            Horas <br /> Reais
          </span>
          <Box display="flex" alignItems="center" style={{ gap: '0.4375rem' }}>
            <TimerIcon style={{ fontSize: '1.2rem' }} />
            <TextField
              style={{ flexGrow: 1 }}
              placeholder="0"
              value={realTime}
              InputProps={{
                style: { fontSize: '1.2rem' },
                disableUnderline: true,
              }}
              type="number"
              disabled
            />
          </Box>
        </Box>

        <Box>
          <span style={{ fontSize: '1rem', fontFamily: 'Quicksand' }}>
            Progresso
          </span>
          <Box display="flex" alignItems="center" style={{ gap: '0.4375rem' }}>
            <TextField
              style={{ flexGrow: 1 }}
              placeholder="0%"
              value={progress}
              onChange={event => setProgress(event.target.value)}
              InputProps={{
                style: { fontSize: '1.2rem' },
                disableUnderline: true,
              }}
              disabled
            />
          </Box>
        </Box>
      </Box>

      <Box>
        <span
          style={{
            fontWeight: 'bold',
            fontFamily: 'QuickSand',
            display: 'block',
          }}
        >
          Descrição
        </span>
        <TextField
          placeholder="Adcione uma descrição para a tarefa"
          value={description}
          onChange={event => setDescription(event.target.value)}
          style={{ width: '100%', height: '4.4375rem' }}
          InputProps={{
            style: { fontSize: '0.875rem' },
            disableUnderline: true,
          }}
        />
      </Box>

      <Box
        display="flex"
        padding={1}
        alignItems="center"
        justifyContent="space-between"
        style={{
          border: '1px solid #f9f1f1',
          borderRadius: '0.5rem',
        }}
        mb={1}
      >
        <Box display="flex" style={{ width: '50%', gap: '1rem' }}>
          <span style={{ fontFamily: 'Quicksand', fontWeight: 'bold' }}>
            Relator
          </span>
          <AutoComplete
            id="combo-box-reporter"
            options={filteredUsers ?? []}
            value={selectedReporter ?? null}
            noOptionsText="Sem opções"
            getOptionLabel={user => user.name}
            size="small"
            style={{ flexGrow: 1 }}
            renderInput={params => (
              <TextField {...params} label="Relator" variant="outlined" />
            )}
            onChange={(_event, value) => {
              setSelectedReporter(value ?? undefined);
            }}
          />
        </Box>

        {issue && (
          <Box>
            <span
              style={{
                fontFamily: 'Quicksand',
                color: '#A7A7A7',
                fontSize: '0.75rem',
              }}
            >
              Criado {moment(issue.created_at).fromNow()}
            </span>
            <br />
            <span
              style={{
                fontFamily: 'Quicksand',
                color: '#A7A7A7',
                fontSize: '0.75rem',
              }}
            >
              Atualizado {moment(issue.updated_at).fromNow()}
            </span>
          </Box>
        )}
      </Box>

      <Box textAlign="end">
        <ButtonBase
          onClick={() => props.closeModal()}
          style={{ marginRight: '1rem' }}
        >
          <Typography variant="button">Cancelar</Typography>
        </ButtonBase>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Salvar
        </Button>
      </Box>
    </Box>
  );
};

TaskContent.defaultProps = TaskContentDefaultProps;

export default TaskContent;
