/* eslint-disable arrow-body-style */
import { api } from './axios';

export enum Method {
  GET = 'get',
  POST = 'post',
  PATCH = 'patch',
  DELETE = 'delete',
}

export const create = <T, R>(entity: string) => {
  return async (options: T): Promise<R> => {
    const res = await api.post(`/${entity}/create`, options);
    return res.data as R;
  };
};

export const read = <T, R>(entity: string) => {
  return async (id: T): Promise<R> => {
    const res = await api.get(`/${entity}/read`, { params: { ...id } });
    return res.data as R;
  };
};

export const readAll = <R, T = {}>(entity: string) => {
  return async (params?: T): Promise<R[]> => {
    const res = await api.get(`/${entity}/readAll`, { params });
    return res.data as R[];
  };
};

export const readAllWithPagination = <R, T = {}>(entity: string) => {
  return async (params?: T): Promise<R> => {
    const res = await api.get(`/${entity}/readAll`, { params });
    return res.data as R;
  };
};

export const update = <T, R>(entity: string) => {
  return async (data: T): Promise<R> => {
    const res = await api.patch(`${entity}/update`, data);
    return res.data as R;
  };
};

export const changepassword = <T, R>(entity: string) => {
  return async (data: T): Promise<R> => {
    const res = await api.patch(`/${entity}/changePassword`, data);
    return res.data as R;
  };
};

export const remove = <T, R>(entity: string) => {
  return async (params: T): Promise<R> => {
    const res = await api.delete(`/${entity}/delete`, { params });
    return res.data as R;
  };
};

export const generic = <R, T>(method: Method, entity: string, path: string) => {
  return async (data?: T): Promise<R> => {
    const res = await api[method](`/${entity}/${path}`, {
      ...data,
    });
    return res.data as R;
  };
};

export const genericWithParams = <R, T>(
  method: Method,
  entity: string,
  path: string,
) => {
  return async (data?: T): Promise<R> => {
    const res = await api[method](`/${entity}/${path}`, {
      params: { ...data },
    });
    return res.data as R;
  };
};
