import useTheme from '@material-ui/core/styles/useTheme';

import ReactLoading from 'react-loading';

const Loading = () => {
  const theme = useTheme();
  return <ReactLoading type="bars" color={theme.palette.primary.main} />;
};

export default Loading;
