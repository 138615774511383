import { AppRoute } from './appRoute';
import Router from './router';

import Home from '../pages/Home';
import SignIn from '../pages/SignIn';
import Users from '../pages/Users';
import Clients from '../pages/Clients';
import Squads from '../pages/Squads';
import SquadDetails from '../pages/SquadDetails';
import ClientProjects from '../pages/ClientProjects';
import Requirements from '../pages/Requirements';

import HomeIcon from '../components/Icones/HomeIcon';
import UsersIcon from '../components/Icones/UsersIcon';
import SquadsIcon from '../components/Icones/SquadsIcon';
import ClientsIcon from '../components/Icones/ClientsIcon';

const routes: AppRoute[] = [
  {
    path: '/',
    exact: true,
    main: SignIn,
  },
  {
    path: '/home',
    exact: true,
    restricted: true,
    main: Home,
    title: 'Home',
    icon: <HomeIcon />,
  },
  {
    path: '/users',
    exact: true,
    restricted: true,
    main: Users,
    title: 'Users',
    icon: <UsersIcon />,
  },
  {
    path: '/clients',
    exact: true,
    restricted: true,
    main: Clients,
    title: 'Clients',
    icon: <ClientsIcon />,
  },
  {
    path: '/clients/:clientId/projects',
    exact: true,
    restricted: true,
    main: ClientProjects,
    title: 'Client Projects',
  },

  {
    path: '/clients/:clientId/project/:projectId/details',
    exact: true,
    restricted: true,
    main: Requirements,
    title: 'Requirements',
  },
  {
    path: '/squads',
    exact: true,
    restricted: true,
    main: Squads,
    title: 'Squads',
    icon: <SquadsIcon />,
  },
  {
    path: '/squad/:squad',
    exact: true,
    restricted: true,
    main: SquadDetails,
    title: 'Squad Backlog',
  },
];

const AppRouter = () => <Router routes={routes} />;

export { routes };
export default AppRouter;
